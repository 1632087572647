@function color-wh($opacity) {
  @return rgba(255, 255, 255, $opacity);
}
@function color-bk($opacity) {
  @return rgba(0, 0, 0, $opacity);
}

@function color-black01($opacity) {
  @return rgba(66, 72, 81, $opacity);  //#424851、黒1、基本テキスト色
}
@function color-black02($opacity) {
  @return rgba(45, 45, 45, $opacity);  //#2d2d2d、黒2、ビジネステキスト色
}

@function color-gray01($opacity) {
  @return rgba(154, 154, 154, $opacity);  //#9a9a9a、灰色1、BRAND背景
}
@function color-gray02($opacity) {
  @return rgba(216, 216, 223, $opacity);  //#d8d8df、灰色2、INFORMATION下線
}
@function color-gray03($opacity) {
  @return rgba(182, 182, 182, $opacity);  //#b6b6b6、灰色3、1つ目のUpdate Quality of Lifeテキスト色(mix-blend-mode)
}
@function color-gray04($opacity) {
  @return rgba(104, 104, 104, $opacity);  //#686868、灰色4、ビジネスの英語見出しテキスト色
}
@function color-gray05($opacity) {
  @return rgba(242, 242, 245, $opacity);  //#f2f2f5、灰色5、ビジネスの数字テキスト色
}
@function color-gray06($opacity) {
  @return rgba(209, 213, 217, $opacity);  //#d1d5d9、灰色6、沿革の縦棒色
}

@function color-blue01($opacity) {
  @return rgba(6, 46, 103, $opacity);  //#062e67、紺色1、紺色背景
}
@function color-blue02($opacity) {
  @return rgba(13, 52, 107, $opacity);  //#0d346b、紺色2、TOPICS日付、TOPICS MORE
}
@function color-blue03($opacity) {
  @return rgba(9, 83, 183, $opacity);  //#0953b7、青色1
}
@function color-blue04($opacity) {
  @return rgba(112, 130, 155, $opacity);  //#70829b、薄紫色、TOPICSカテゴリ、INFORMATION項目見出し
}

@function color-green01($opacity) {
  @return rgba(6, 211, 204, $opacity);  //#06d3cc、青緑色、TOPICS右上、2つ目のUpdate Quality of Lifeテキスト色(mix-blend-mode)
}

@function color-yellow01($opacity) {
  @return rgba(249, 209, 152, $opacity);  //#f9d198、明黄色、mix-blend-modeの「除外」指定で使用する色
}

@function color-red01($opacity) {
  @return rgba(255, 0, 0, $opacity);  //#ff0000、赤色、フォームの必須・エラーメッセージ色
}


