.p3-text {
  position: relative;

  ///// テキストシャドウ /////
  &.-shadow {
    &.-bk {
      -moz-text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.58), 0px 0px 16px rgba(0, 0, 0, 0.36);
      -webkit-text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.58), 0px 0px 16px rgba(0, 0, 0, 0.36);
      -ms-text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.58), 0px 0px 16px rgba(0, 0, 0, 0.36);
      text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.58), 0px 0px 16px rgba(0, 0, 0, 0.36);
    }

    &.-wh {
      -moz-text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.58), 0px 0px 3px rgba(255, 255, 255, 0.58), 0px 0px 16px rgba(255, 255, 255, 1);
      -webkit-text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.58), 0px 0px 3px rgba(255, 255, 255, 0.58), 0px 0px 16px rgba(255, 255, 255, 1);
      -ms-text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.58), 0px 0px 3px rgba(255, 255, 255, 0.58), 0px 0px 16px rgba(255, 255, 255, 1);
      text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.58), 0px 0px 3px rgba(255, 255, 255, 0.58), 0px 0px 16px rgba(255, 255, 255, 1);
    }
  }
  
  ///// 色付きテキスト /////
  &.-wh {
    color: color-wh(1) !important;
  }
  &.-black01 {
    color: color-black01(1) !important;
  }
  &.-black02 {
    color: color-black02(1) !important;
  }
  &.-gray04 {
    color: color-gray04(1) !important;
  }
  &.-gray05 {
    color: color-gray05(1) !important;
  }
  &.-blue01 {
    color: color-blue01(1) !important;
  }
  &.-blue02 {
    color: color-blue02(1) !important;
  }
  &.-blue03 {
    color: color-blue03(1) !important;
  }
  &.-blue04 {
    color: color-blue04(1) !important;
  }
  &.-green01 {
    color: color-green01(1) !important;
  }


  ///// 縦書き /////
  &.-mixed {
    @include tategaki('mixed');
  }
  &.-upright {
    @include tategaki('upright');
  }
  &.-sideways {
    @include tategaki('sideways');
  }

  ///// リード文 /////
/*
  &.-lead01 {
    position: relative;
    @include font-montserrat("l");
    @include fontSize(3.2, 2.4);
    color: color-black01(1);
    text-align: justify;
    line-height: 1.65;

    @include breakpoint-pc {
    }
  }
*/

  ///// マーキーテキスト /////
  &.-marquee01 {
    position: absolute;
    top: calc( 50% - 8px);
    left: 0;
    transform: translateY(-50%);
    @include font-montserrat("t");
    font-size: 7rem;
    line-height: 1;
    letter-spacing: 0.6rem;
    color: color-gray03(1);
    mix-blend-mode: luminosity;

    @include breakpoint-pc {
      top: 50%;
//      top: calc( 50% - 25px);
      font-size: 14rem;
    }
    @include breakpoint-mainbox {
      top: 50%;
//      top: calc( 50% - 40px);
      font-size: 20rem;
    }
  }

  &.-marquee02 {
    position: absolute;
    top: -50px;
    left: 0;
    @include font-montserrat("t");
    font-size: 7rem;
    line-height: 1;
    letter-spacing: 0.8rem;
    color: color-green01(1);
    mix-blend-mode: multiply;
    opacity: 0.55;

    @include breakpoint-pc {
      top: -85px;
      font-size: 12rem;
    }
    @include breakpoint-mainbox {
      top: -94px;
      font-size: 13rem;
    }
  }


  ///// テキスト /////
  &.-t01 {
    @include lineHeight(2.5, 2);
    text-align: justify;
    letter-spacing: 1px;

    @include breakpoint-pc {
    }
    
    &.-center {
      text-align: center;
    }
    
    &.-lh16 {
      @include lineHeight(1.6, 1.5);
    }
  }

  &.-t02 {
    @include font-ntsan("m");
    @include fontSize(1.4, 1.4);
    @include lineHeight(1, 1);
    text-align: justify;
    letter-spacing: 1px;

    @include breakpoint-pc {
    }
    
    &.-center {
      text-align: center;
    }

    &.-lh17 {
      @include lineHeight(1.7, 1.7);
    }
  }

  &.-t03 {
    @include font-montserrat("sb");
    font-size: 1.2rem;
    line-height: 1;
    letter-spacing: 1px;

    @include breakpoint-pc {
      font-size: 1.3rem;
    }
    @include breakpoint-mainbox {
      font-size: 1.5rem;
    }
  }

  &.-t04 {
    @include font-dnpshumin-pr6("m");
    font-size: 1.4rem;
    @include lineHeight(1.5, 1.4);
    letter-spacing: 1px;

    @include breakpoint-pc {
      font-size: 1.7rem;
    }
    @include breakpoint-mainbox {
      font-size: 1.8rem;
    }
    
    &.-justify {
      text-align: justify;
    }
  }

  &.-t05 {
    @include font-ntsan("b");
    @include fontSize(1.2, 1);
    @include lineHeight(1.5, 1.4);
  }

  &.-t06 {
    @include font-ntsan("m");
    font-size: 1.2rem;
    line-height: 1;

    @include breakpoint-mainbox {
      font-size: 1.3rem;
    }
  }

  &.-t07 {
    @include font-ntsan("r");
    font-size: 1.4rem;
    @include lineHeight(1.6, 1.6);

    @include breakpoint-mainbox {
      font-size: 1.5rem;
    }
  }

  &.-t08 {
    @include font-montserrat("m");
    @include fontSize(1.8, 1.6);
    @include lineHeight(1, 1);
  }

  &.-t09 {
    @include font-sprmin("r");
    @include fontSize(1.8, 1.6);
    @include lineHeight(2.2, 2);
  }

  &.-t10 {
    @include font-ntsan("r");
    @include fontSize(2, 1.8);
    @include lineHeight(2.3, 2.1);
    letter-spacing: 1px;
  }

  &.-t11 {
    @include font-din1451();
    @include fontSize(4, 3.6);
    @include lineHeight(1, 1);
  }

  &.-t12 {
    @include font-din1451();
    @include fontSize(4.2, 3.6);
    @include lineHeight(1, 1);
  }

  &.-t13 {
    @include font-dnpshumin-pr6("m");
    @include fontSize(1.5, 1.3);
    @include lineHeight(1, 1);
  }

  &.-t14 {
    @include font-din1451();
    @include fontSize(2, 1.8);
    @include lineHeight(1, 1);
  }

  &.-t15 {
    @include font-din1451();
    @include fontSize(1.4, 1.2);
    @include lineHeight(1, 1);
  }

  &.-t16 {
    @include font-ntsan("r");
    @include fontSize(1.8, 1.6);
    @include lineHeight(1.8, 1.8);
  }

  &.-t17 {
    @include font-ntsan("b");
    @include fontSize(1.3, 1.1);
    @include lineHeight(1.5, 1.4);
  }

  &.-t18 {
    @include font-lldr();
    font-size: 2.8rem;
    line-height: 0.83;

    @include breakpoint-sp {
      font-size: 3.2rem;
    }
    @include breakpoint-tb {
      font-size: 9.5vw;
    }
    @include breakpoint-business {
      font-size: 5.6rem;
    }
    @include breakpoint-pc {
      font-size: 7.4vw;
      line-height: 0.88;
    }
    @include breakpoint-mainbox {
      font-size: 9.2rem;
      line-height: 0.9;
    }
  }

  &.-t19 {
    @include font-din1451();
    font-size: 8rem;
    @include lineHeight(1, 1);
    letter-spacing: 1px;

    @include breakpoint-tb {
      font-size: 9rem;
    }
    @include breakpoint-pc {
      font-size: 13vw;
    }
    @include breakpoint-mainbox {
      font-size: 16rem;
    }
  }

  &.-t20 {
    @include font-dnpshumin-pr6("m");
    font-size: 1.2rem;
    @include lineHeight(1, 1);
    letter-spacing: 1px;

    @include breakpoint-tb {
      font-size: 1.4rem;
    }
    @include breakpoint-pc {
      font-size: 1.6rem;
    }
  }

  &.-t21 {
    @include font-dnpshumin-pr6("m");
    font-size: 2.4rem;
    @include lineHeight(1.375, 1.375);
    letter-spacing: 0.03em;

    @include breakpoint-sp {
      font-size: 2.6rem;
    }
    @include breakpoint-tb {
      font-size: 6.7vw;
    }
    @include breakpoint-business {
      font-size: 3.9rem;
    }
    @include breakpoint-pc {
      font-size: 5vw;
    }
    @include breakpoint-mainbox {
      font-size: 5.6rem;
    }
  }

  &.-t22 {
    @include font-dnpshumin-pr6("m");
    font-size: 2.3rem;
    @include lineHeight(1.26, 1.26);
    letter-spacing: 0.03em;

    @include breakpoint-sp {
      font-size: 2.5rem;
    }
    @include breakpoint-tb {
      font-size: 6vw;
    }
    @include breakpoint-business {
      font-size: 3.8rem;
    }
    @include breakpoint-pc {
      font-size: 4.6vw;
    }
    @include breakpoint-mainbox {
      font-size: 5rem;
    }
  }

  &.-t23 {
    @include font-ntsan("b");
    @include fontSize(1.1, 1);
    line-height: 1;
  }

  &.-t24 {
    @include font-lldr();
    font-size: 1.2rem;
    line-height: 0.83;

    @include breakpoint-tb {
      font-size: 1.4rem;
    }
    @include breakpoint-mainbox {
      font-size: 1.6rem;
      line-height: 0.9;
    }
  }

  &.-t25 {
    @include font-dnpshumin-pr6("m");
    font-size: 2.6rem;
    @include lineHeight(1.5, 1.5);
    letter-spacing: 1px;

    @include breakpoint-pc {
      font-size: 2.6rem;
    }
    @include breakpoint-mainbox {
      font-size: 2.8rem;
    }
    
    &.-justify {
      text-align: justify;
    }
  }

  &.-t26 {
    @include font-montserrat("sb");
    @include fontSize(2.4, 2.2);
    @include lineHeight(1, 1);
    letter-spacing: 0.04em;
  }

  &.-t27 {
    @include font-montserrat("sb");
    @include fontSize(1.2, 1);
    @include lineHeight(1, 1);
    letter-spacing: 0.04em;
  }

  &.-t28 {
    @include font-ntsan("b");
    @include fontSize(1.8, 1.6);
    @include lineHeight(1.6, 1.6);
    letter-spacing: 0.04em;
  }

  &.-t29 {
    @include font-montserrat("sb");
    @include fontSize(1.3, 1.2);
    @include lineHeight(1, 1);
    letter-spacing: 0.04em;
  }

  ///// リスト /////
  &.-list01 {
    margin-left: 20px;
    list-style-type: disc;
    box-sizing: border-box;
    @include lineHeight(2.5, 2);
    text-align: justify;
    letter-spacing: 1px;

    @include breakpoint-pc {
    }

    & .-item {
      list-style-type: inherit;
    }
  }

  ///// リンク /////
  &.-link01 {
    color: color-black01(1);
    text-decoration: underline;
  }


/*
  &.-t01 {
    text-align: justify;
    line-height: 2;

    @include breakpoint-pc {
      line-height: 2.4;
    }
    
    &.-center {
      text-align: center;
    }
  }

  &.-t02 {
    @include font-montserrat("r");
    @include fontSize(1.3, 1.3);
    text-align: justify;
    line-height: 1.7;
    
    &.-center {
      text-align: center;
    }
  }

  &.-t03 {
    @include font-montserrat("m");
    @include fontSize(2.4, 1.8);
    color: color-black01(1);
    line-height: 1.7;
    @include breakpoint-pc {

    }
    
    &.-center {
      text-align: center;
    }
  }

  &.-t04 {
    @include font-montserrat("m");
    @include fontSize(1.5, 1.3);
    color: color-black01(1);
    text-align: justify;
    line-height: 2;

    @include breakpoint-pc {
      line-height: 2.4;
    }
    
    &.-center {
      text-align: center;
    }
    
    &.-slim {
      line-height: 1.5;

      @include breakpoint-pc {
        line-height: 1.8;
      }
    }
  }

  &.-t05 {
    @include fontSize(1.9, 1.7);
  }

  &.-t06 {
    @include font-montserrat("m");
    font-size: 1rem;
    line-height: 1;
  }

  &.-t07 {
    text-align: justify;
    line-height: 1.7;

    @include breakpoint-pc {
      line-height: 1.8;
    }
    
    &.-center {
      text-align: center;
    }
  }
*/


  ///// リスト /////
/*
  &.-list01 {
    padding-left: 5px;
    box-sizing: border-box;
    text-align: justify;

    & .-item {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include align-items-stretch;
      line-height: 1.7;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
      
      &:before {
        display: block;
        content: "◎";
        padding-right: 5px;
        box-sizing: border-box;
      }
    }
  }

  &.-list02 {
    margin-left: 20px;
    list-style-type: disc;
    box-sizing: border-box;
    @include font-montserrat("m");
    @include fontSize(1.6, 1.5);
    color: color-black01(1);
    text-align: justify;
    line-height: 2.3;

    @include breakpoint-pc {
    }

    & .-item {
      list-style-type: inherit;
    }
  }

  &.-list03 {
    box-sizing: border-box;
    text-align: justify;

    & .-item {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include align-items-stretch;
      line-height: 1.7;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
      
      &:before {
        display: block;
        content: "※";
        padding-right: 5px;
        box-sizing: border-box;
      }
    }
  }

  &.-list04 {
    margin-left: 20px;
    list-style-type: disc;
    box-sizing: border-box;
    @include font-montserrat("r");
    @include fontSize(1.5, 1.4);
    color: color-black01(1);
    text-align: justify;
    line-height: 2.3;

    @include breakpoint-pc {
    }

    & .-item {
      list-style-type: inherit;
    }
  }
*/



/*
  &.-t04 {
    font-size: 2rem;
    color: color-black01(1);
    line-height: 1.92;
    @include breakpoint-pc {
      font-size: 2.8rem;
    }
  }
  &.-t05 {
    font-size: 1.4rem;
    @include breakpoint-pc {
      font-size: 1.6rem;
    }
  }
  &.-t06 {
    font-size: 1.5rem;
    font-weight: 700;
    color: color-black01(1);
    line-height: 1.8;
    @include breakpoint-mainboxhalf {
      font-size: 1.8rem;
      line-height: 2;
    }
    @include breakpoint-pc {
      font-size: 2.2rem;
    }
  }
  &.-t07 {
    line-height: 1.5;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &.-t08 {
    font-size: 80%;
    line-height: 1.7;
  }
  &.-t09 {
    padding-bottom: 20px;
    border-bottom: 2px solid color-effect02(1);
  }
  &.-t10 {
    padding-bottom: 20px;
  }

  &.-ttl01 {
    @include font-montserrat("m");
    font-size: 4.4vw;
    color: color-black01(1);
    text-align: left;
    line-height: 1;
    @media (min-width:400px) {
      font-size: 1.85rem;
    }
    @include breakpoint-pc {
      font-size: 2.6rem;
    }
    & .-ttlInner01 {
      display: inline-block;
      position: relative;
      z-index: 5;
      width: 100%;
      max-width: 645px;
      padding: 15px 20px 25px 30px;
      background-image: url("../images/recruit/merit_border.png");
      background-position: left bottom;
      background-repeat: no-repeat;
      background-size: auto 100%;
      line-height: 1.4;
      @include breakpoint-mainboxhalf {
        padding: 20px 20px 30px 35px;
      }
      @include breakpoint-mainboxhalf {
        padding: 25px 160px 35px 40px;
      }
      
      & .-yg {
        color: color-black01(1);
      }
    }
  }
  &.-ttl02 {
    font-size: 2rem;
    color: color-effect03(1);
    line-height: 1.35;
    @include font-montserrat(b);
    @media (min-width:520px) {
      text-align: center;
    }
    @include breakpoint-mainboxhalf {
      font-size: 2.4rem;
    }
    @media (min-width:820px) {
      line-height: 1.6;
      font-size: 2.8rem;
    }
    & .br {
      @media (max-width:980px) {
        display: none;
      }
    }
  }

  &.-ttl03 {
    font-size: 90%;
    line-height: 1.3;
    color: color-black01(1);
    @include font-montserrat(b);
  }
*/

}