.p1-gnav {
  @include flexbox;
  @include flex-wrap-nowrap;
  @include flex-direction-row;
  @include flex-justify-space-between;
  @include align-items-center;
  overflow: hidden;
  width: 100%;
  height: 100%;

  @include breakpoint-pc {
  }
  @include breakpoint-max {
  }

  &_content {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-column;
    @include flex-justify-flex-start;
    @include align-items-stretch;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 5%;
    box-sizing: border-box;

    @include breakpoint-pc {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-end;
      @include align-items-stretch;
      position: relative;
      transform: translateY(0);
      width: 100%;
      height: 100%;
      padding: 0;
      background-image: none;
      background-color: transparent;
    }
  }

  &_list {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-center;
    width: 100%;
    max-width: 340px;
    gap: 0 $gnav-item-gap-sp;
    margin-left: auto;
    margin-right: auto;
    padding-top: 15px;
    box-sizing: border-box;

    @include breakpoint-pc {
      @include flex-wrap-nowrap;
      @include flex-justify-flex-end;
      @include align-items-center;
      @include flex-align-content-center;
      max-width: none;
      height: 100%;
      gap: 0 $gnav-item-gap-pc;
      padding: 0;
    }
    @media all and (min-width: 900px) {
//      width: 32vw;
    }
    @include breakpoint-content {
//      width: 320px;
    }
  }

  &_item {
    display: block;
    position: relative;
    width: calc( 50% - #{$gnav-item-gap-sp} );
    padding: 0;
    border-bottom: 1px solid color-wh(1);
    box-sizing: border-box;
    @include font-montserrat("r");
    font-size: 2rem;
    line-height: 1;
    letter-spacing: .12rem; 

    @include breakpoint-pc {
      width: auto;
      height: 100%;
      border-bottom-style: none;
      font-size: 1.8rem;
    }
    
    &.-pconly {
      display: none;

      @include breakpoint-pc {
        display: block;
      }
    }
    
    &.-sponly {
      display: block;

      @include breakpoint-pc {
        display: none;
      }
    }

    &.-spnoborder {
      border-bottom-style: none;
    }
    
    &.-contact {
      width: 100%;
      margin-top: 20px;
      @include font-montserrat("r");
      font-size: 3.4rem;
      
      @include breakpoint-pc {
        margin-top: 0;
        @include font-montserrat("l");
        font-size: 1.8rem;
      }
      
      a {
        color: color-wh(1);
      }
    }
  }

  &_itemInner {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-row;
    @include flex-justify-center;
    @include align-items-center;
    height: 100%;
    padding: 10px 0;
    box-sizing: border-box;
    color: color-wh(1);

    @include breakpoint-sp {
      padding: 15px 0;
    }
    @include breakpoint-pc {
      @include flex-justify-center;
      padding: 0;
      color: color-yellow01(1);     
    }

    &:hover, &:focus {
    }
  
    &.is-current {
    }
  }
}
