$i: 5;
@for $value from 0 through 36 {
  .u-m#{ $value * $i } {
    margin: $i * $value + px!important;
  }
  .u-mtb#{ $value * $i } {
    margin-top: $i * $value + px!important;
    margin-bottom: $i * $value + px!important;
  }
  .u-mlr#{ $value * $i } {
    margin-left: $i * $value + px!important;
    margin-right: $i * $value + px!important;
  }
  .u-mt#{ $value * $i } {
    margin-top: $i * $value  + px!important;
  }
  .u-mb#{ $value * $i } {
    margin-bottom: $i * $value + px!important;
  }
  .u-ml#{ $value * $i } {
    margin-left: $i * $value + px!important;
  }
  .u-mr#{ $value * $i } {
    margin-right: $i * $value + px!important;
  }
  .u-p#{ $value * $i } {
    padding: $i * $value + px!important;
  }
  .u-pt#{ $value * $i } {
    padding-top: $i * $value + px!important;
  }
  .u-pb#{ $value * $i } {
    padding-bottom: $i * $value + px!important;
  }
  .u-pl#{ $value * $i } {
    padding-left: $i * $value + px!important;
  }
  .u-pr#{ $value * $i } {
    padding-right: $i * $value + px!important;
  }
}

.u-m-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.u-m-pcAuto-spLeft {
  margin-left: 0 !important;
  margin-right: auto !important;

  @include breakpoint-pc {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.u-m-pcAuto-spRight {
  margin-left: auto !important;
  margin-right: 0 !important;

  @include breakpoint-pc {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

$j: 10;
@for $value from 1 through 18 {
  .u-mb {
    &#{ $value * $j }_half {
      margin-bottom: $value * $j / 2 + px!important;

      @include breakpoint-pc {
        margin-bottom: $value * $j + px!important;
      }
    }
  }
}

$k: 15;
@for $value from 1 through 18 {
  .u-mb {
    &#{ $value * $k }_23rd {
      margin-bottom: $value * $k * 2 / 3 + px!important;

      @include breakpoint-pc {
        margin-bottom: $value * $k + px!important;
      }
    }
  }
}

$l: 10;
@for $value from 1 through 18 {
  .u-mb {
    &#{ $value * $l }_level {
      margin-bottom: $value * $l / 3 + px!important;
      @include breakpoint-contenthalf {
        margin-bottom: $value * $l / 2 + px!important;
      }
      @include breakpoint-pc {
        margin-bottom: $value * $l + px!important;
      }
    }
  }
}

$j: 10;
@for $value from 1 through 18 {
  .u-mb-minus {
    &#{ $value * $j }_half {
      margin-bottom: - $value * $j / 2 + px!important;
      @include breakpoint-pc {
        margin-bottom: - $value * $j + px!important;
      }
    }
  }
}

///// 個別指定 /////
.u-mb15_half {
  margin-bottom: calc(15px / 2) !important;

  @include breakpoint-pc {
    margin-bottom: 15px !important;
  }
}

.u-mb25_half {
  margin-bottom: calc(25px / 2) !important;

  @include breakpoint-pc {
    margin-bottom: 25px !important;
  }
}

.u-mb36_15 {
  margin-bottom: 15px !important;

  @include breakpoint-pc {
    margin-bottom: 36px !important;
  }
}

.u-mb50_30 {
  margin-bottom: 30px !important;

  @include breakpoint-pc {
    margin-bottom: 50px !important;
  }
}

.u-mb70_20 {
  margin-bottom: 20px !important;

  @include breakpoint-pc {
    margin-bottom: 70px !important;
  }
}

.u-mb80_20 {
  margin-bottom: 20px !important;

  @include breakpoint-pc {
    margin-bottom: 80px !important;
  }
}

.u-pb20_0 {
  padding-bottom: 0 !important;

  @include breakpoint-pc {
    padding-bottom: 20px !important;
  }
}


// 3段階変化
.u-mb20_0_0 {
  margin-bottom: 0 !important;

  @include breakpoint-pc {
    margin-bottom: 0 !important;
  }
  @include breakpoint-mainbox {
    margin-bottom: 20px !important;
  }
}

.u-mb60_30_10 {
  margin-bottom: 10px !important;

  @include breakpoint-pc {
    margin-bottom: 30px !important;
  }
  @include breakpoint-mainbox {
    margin-bottom: 60px !important;
  }
}

.u-mb70_50_25 {
  margin-bottom: 25px !important;

  @include breakpoint-pc {
    margin-bottom: 50px !important;
  }
  @include breakpoint-mainbox {
    margin-bottom: 70px !important;
  }
}

.u-mb60_40_15 {
  margin-bottom: 15px !important;

  @include breakpoint-pc {
    margin-bottom: 40px !important;
  }
  @include breakpoint-mainbox {
    margin-bottom: 60px !important;
  }
}

.u-mb90_50_40 {
  margin-bottom: 40px !important;

  @include breakpoint-pc {
    margin-bottom: 50px !important;
  }
  @include breakpoint-mainbox {
    margin-bottom: 90px !important;
  }
}

.u-mb90_50_50 {
  margin-bottom: 50px !important;

  @include breakpoint-pc {
    margin-bottom: 50px !important;
  }
  @include breakpoint-mainbox {
    margin-bottom: 90px !important;
  }
}

.u-mb100_80_20 {
  margin-bottom: 20px !important;

  @include breakpoint-pc {
    margin-bottom: 80px !important;
  }
  @include breakpoint-mainbox {
    margin-bottom: 100px !important;
  }
}

.u-mb110_70_30 {
  margin-bottom: 30px !important;

  @include breakpoint-pc {
    margin-bottom: 70px !important;
  }
  @include breakpoint-mainbox {
    margin-bottom: 110px !important;
  }
}

.u-mb130_120_60 {
  margin-bottom: 60px !important;

  @include breakpoint-pc {
    margin-bottom: 120px !important;
  }
  @include breakpoint-mainbox {
    margin-bottom: 130px !important;
  }
}


///// 専用多段階変化 /////
// トップページ、BUSINESS見出し下余白
.u-mb_business_ttl {
  margin-bottom: 20px !important;

  @include breakpoint-sp {
    margin-bottom: 30px !important;
  }
  @include breakpoint-contenthalf {
    margin-bottom: 40px !important;
  }
  @include breakpoint-pc {
    margin-bottom: 60px !important;
  }
}

// トップページ、BUSINESSリード文下余白
.u-mb_business_lead {
  margin-bottom: 50px !important;

  @include breakpoint-tb {
    margin-bottom: 70px !important;
  }
  @include breakpoint-pc {
    margin-bottom: 60px !important;
  }
  @include breakpoint-mainbox {
    margin-bottom: 120px !important;
  }
}

// トップページ、COMPANY見出し下余白
.u-mb_company_ttl {
  margin-bottom: 15px !important;

  @include breakpoint-tb {
    margin-bottom: 30px !important;
  }
  @include breakpoint-mainbox {
    margin-bottom: 70px !important;
  }
}

// COMPANYページ、KV下のセクションを上にずらしてKV下に重ねる
.u-mb_company_kvbtmsec {
  margin-bottom: -65px !important;

  @include breakpoint-tb {
    margin-bottom: -80px !important;
  }
  @media all and (min-width: 700px) {
    margin-bottom: -80px !important;
  }
  @include breakpoint-pc {
    margin-bottom: -160px !important;
  }
  @include breakpoint-mainbox {
    margin-bottom: -215px !important;
  }
}


// 全ページ共通、最下部CONTACTボタン下余白
.u-mb_tocontact_ttl {
  margin-bottom: 30px !important;

  @include breakpoint-pc {
    margin-bottom: 40px !important;
  }
  @include breakpoint-mainbox {
    margin-bottom: 50px !important;
  }
  @media all and (min-width: 1300px) {
    margin-bottom: 62px !important;
  }
}


// ボタン右余白(KV周囲余白と同じ幅をあける)
.u-pr_btnpad {
  padding-right: $section-content-pad-sp;
  box-sizing: border-box;
  
  @include breakpoint-pc {
    padding-right: $section-content-pad-pc;
  }
}