.p1-logo {
  display: block;
  position: absolute;
  top: 20px;
  left: $front-mv-border-width-sp;
  z-index: 1200;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  mix-blend-mode: exclusion;
  opacity: 0;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  pointer-events: none;

  @include breakpoint-mainboxhalf {
    left: $front-mv-border-width-sp;
  }
  @include breakpoint-pc {
    top: 20px;
    left: auto;
  }
  @include breakpoint-mainbox {
    top: 135px;
  }

  &.js-isscroll {
    position: fixed;
    opacity: 1;

    @include breakpoint-pc {
    }
    @include breakpoint-content {
    }
  }
  &.js-noscroll {
    position: fixed;
    opacity: 1;

    @include breakpoint-pc {
      position: absolute;
      opacity: 1;
    }
    
    &.js-isopen {
      opacity: 1;
    }
  }

  &_item {
    display: block;
    width: 85px;
    height: auto;
    pointer-events: auto;

    @include breakpoint-pc {
      width: 118px;
    }
    @include breakpoint-mainbox {
      width: 17.948717948717949vw;  //280px / 1560 * 100
    }
    @include breakpoint-maxinner {
      width: 280px;
    }
  }

  &_img {
    display: block;
    width: 100%;
    height: auto;
  }
}