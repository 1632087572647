.l-wrap {
  overflow: hidden;
}


.l-section {
  width: 100%;
  padding: 30px 0;
  position: relative;
  overflow: hidden;

  @include breakpoint-pc {
    padding: 60px 0;
  }

  &.-visible {
    overflow: visible;
  }

  &.-pad0 {
    padding: 0;
  }

  &.-pb0 {
    padding: 30px 0 0;

    @include breakpoint-pc {
      padding: 60px 0 0;
    }
  }
  
  //トップページの最初のセクション専用
  &.-frontfirst {
    padding-top: 100px;

    @include breakpoint-pc {
      padding-top: 100px;
    }
    @include breakpoint-mainbox {
      padding-top: 13.6vw;
    }
    @include breakpoint-max {
      padding-top: 215px;
    }
  }
  
  //下層ページの最初のセクション用
  &.-firstsec {
    padding-top: 100px;

    @include breakpoint-pc {
      padding-top: 70px;
    }
    @include breakpoint-mainbox {
      padding-top: 11vw;
    }
    @include breakpoint-maxinner {
      padding-top: 180px;
    }
  }

  &.-largeSpace {
    padding: 40px 0;

    @include breakpoint-pc {
      padding: 80px 0;
    }

    &.-pb0 {
      padding: 40px 0 0;

      @include breakpoint-pc {
        padding: 80px 0 0;
      }
    }
  }

  &.-hugeSpace {
    padding: 55px 0 60px;

    @include breakpoint-pc {
      padding: 70px 0 60px;
    }
    @include breakpoint-mainbox {
      padding: 150px 0 160px;
    }

    &.-pt40_70_150 {
      padding-top: 40px;

      @include breakpoint-pc {
        padding-top: 70px;
      }
      @include breakpoint-mainbox {
        padding-top: 150px;
      }
    }

    &.-pb0 {
      padding: 80px 0 0;

      @include breakpoint-pc {
        padding: 160px 0 0;
      }
    }
  }

  &.-tocontact {
    padding: 10px 0 0;

    @include breakpoint-pc {
      padding: 0 0 0;
    }
    @include breakpoint-content {
      padding: 0 0 0;
    }
    @include breakpoint-mainbox {
      padding: 10px 0 0;
    }
    @media all and (min-width: 1300px) {
      padding: 40px 0 0;
    }
    @media all and (min-width: 1400px) {
      padding: 60px 0 0;
    }
  }
  
  &.-contactform {
    padding-bottom: 10px;
    
    @include breakpoint-pc {
      padding-bottom: 20px;
    }
    @include breakpoint-mainbox {
      padding-bottom: 0;
    }
  }
  
  
  ///// セクションの重なり順を指定 /////
  $i: 1;
  @for $value from 1 through 10 {
    &.-zi {
      &#{ $value * $i } {
        z-index: $value * $i;
      }
    }
  }


  ///// セクション全体の背景色を変える /////
  &.-bg01 {
    background-color: color-gray01(1);
  }
  &.-bg02 {
    background-color: color-blue01(1);
  }


  ///// セクション内の一部のみの背景色を変える /////
  &_bg {
    &.-type01 {
      background-color: color-gray01(1);
    }

    &.-type02 {
      background-color: color-blue01(1);
    }

    &.-type03 {
      background-color: color-blue02(1);
      
      &.-company {
        @include breakpoint-company {
          background-color: transparent;
        }
      }
    }
  }

  &_content {
    width: calc( 100% - #{$front-mv-border-width-sp} - #{$front-mv-border-width-sp} );
    position: relative;
    overflow: hidden;
    margin: 0 auto;

    @include breakpoint-pc {
      width: calc( 100% - #{$front-mv-border-width-pc} - #{$front-mv-border-width-pc} );
    }
    @include breakpoint-mainbox {
      width: calc( 100% - #{$section-content-pad-pc} - #{$section-content-pad-pc} );
    }
    @include breakpoint-max {
      width: $maxinner;
    }

    &.-visible {
      overflow: visible;
    }
     
    // 最大1000px幅
    &.-content {
      width: 100%;
      max-width: $content;

      @include breakpoint-mainbox {
        width: $content;
      }
    }
   
    // 最大1620px幅
    &.-wide {
      width: 100vw;
      max-width: $max;
    }
    
    // 最大100%幅
    &.-full {
      width: 100%;
    }
  }
}
