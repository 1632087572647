body {
  color: color-black01(1);
  background: color-wh(1);
  width: 100%;
  font-size: 1.4rem;
  line-height: 1;
  @include font-ntsan("r");

  @include breakpoint-pc {
    font-size: 1.6rem;
  }
  @include breakpoint-mainbox {
    font-size: 1.8rem;
  }
}

a {
  color: color-black01(0.8);
  text-decoration: none;
}

::selection {
  background: color-gray02(1);
}
::-moz-selection {
  background: color-gray02(1);
}

//電話番号リンク
.js-tellink {
  a {
    color: inherit;
  }
}
