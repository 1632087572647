@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700&family=Noto+Sans+JP:wght@400;500;700&family=Shippori+Mincho:wght@400;500&display=swap");
html {
  font-size: 62.5%; }

#defs {
  display: none; }

img {
  width: 100%; }

ul li {
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

svg {
  display: block; }

.disp-block-pc {
  display: none; }
  @media all and (min-width: 800px) {
    .disp-block-pc {
      display: block; } }

.disp-block-sptb {
  display: block; }
  @media all and (min-width: 800px) {
    .disp-block-sptb {
      display: none; } }

.disp-block-pctb {
  display: none; }
  @media all and (min-width: 480px) {
    .disp-block-pctb {
      display: block; } }

.disp-block-sp {
  display: block; }
  @media all and (min-width: 480px) {
    .disp-block-sp {
      display: none; } }

.disp-block-pcsp {
  display: none; }
  @media all and (min-width: 375px) {
    .disp-block-pcsp {
      display: block; } }

.disp-block-sponly {
  display: block; }
  @media all and (min-width: 375px) {
    .disp-block-sponly {
      display: none; } }

.disp-inline-pc {
  display: none; }
  @media all and (min-width: 800px) {
    .disp-inline-pc {
      display: inline; } }

.disp-inline-sptb {
  display: inline; }
  @media all and (min-width: 800px) {
    .disp-inline-sptb {
      display: none; } }

.disp-inline-pctb {
  display: none; }
  @media all and (min-width: 480px) {
    .disp-inline-pctb {
      display: inline; } }

.disp-inline-sp {
  display: inline; }
  @media all and (min-width: 480px) {
    .disp-inline-sp {
      display: none; } }

.disp-inline-pcsp {
  display: none; }
  @media all and (min-width: 375px) {
    .disp-inline-pcsp {
      display: inline; } }

.disp-inline-sponly {
  display: inline; }
  @media all and (min-width: 375px) {
    .disp-inline-sponly {
      display: none; } }

@font-face {
  font-family: "linlibrtine_dr";
  src: url("/assets/font/LinLibertine_DR.eot?") format("eot"), url("/assets/font/LinLibertine_DR.woff") format("woff"), url("/assets/font/LinLibertine_DR.ttf") format("truetype"), url("/assets/font/LinLibertine_DR.svg#LinLibertineDisplay") format("svg");
  font-weight: normal;
  font-style: normal; }

.icon-first {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .icon-first:before {
    display: inline-block;
    width: 20px;
    height: 26px;
    content: "";
    margin-right: 6px;
    background-image: url("../images/front/icon_first.png");
    background-size: contain;
    background-position: left center;
    background-repeat: no-repeat; }

.icon-tri3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .icon-tri3:before {
    display: inline-block;
    width: 36px;
    height: 11px;
    content: "";
    margin-right: 15px;
    background-image: url("../images/common/icon_tri3_gr.png");
    background-size: contain;
    background-position: left center;
    background-repeat: no-repeat; }

/*
@mixin icon-spot($status) {
  @if $status == 'maplink' {
    fill: color-wh(1);
    @include svgSize (12,16);
  } @else if $status == '' {
    
  }
}
*/
/*
// 外部リンクicon svg
@mixin icon-external() {
  fill: color-effect01(1);
  @include svgSize (14,14);
}
.externalIcon, %externalIcon {
  padding: 0 5px;
  @include icon-external();
}
*/
@media all and (min-width: 375px) {
  .break.-minsp:after {
    content: "\a";
    white-space: pre; } }

@media all and (min-width: 480px) {
  .break.-mintb:after {
    content: "\a";
    white-space: pre; } }

@media all and (min-width: 500px) {
  .break.-minch:after {
    content: "\a";
    white-space: pre; } }

@media all and (min-width: 800px) {
  .break.-minpc:after {
    content: "\a";
    white-space: pre; } }

@media all and (max-width: calc(800px - 1px)) {
  .break.-maxpc:after {
    content: "\a";
    white-space: pre; } }

@media all and (max-width: calc(480px - 1px)) {
  .break.-maxtb:after {
    content: "\a";
    white-space: pre; } }

@media all and (max-width: calc(375px - 1px)) {
  .break.-maxsp:after {
    content: "\a";
    white-space: pre; } }

.align-l {
  text-align: left !important; }
  @media all and (min-width: 800px) {
    .align-l {
      text-align: left !important; } }
  @media all and (min-width: 800px) {
    .align-l {
      text-align: left !important; } }

.align-r {
  text-align: left !important; }
  @media all and (min-width: 800px) {
    .align-r {
      text-align: right !important; } }
  .align-r.-spr {
    text-align: left !important;
    text-align: right !important; }
    @media all and (min-width: 800px) {
      .align-r.-spr {
        text-align: right !important; } }
    @media all and (min-width: 800px) {
      .align-r.-spr {
        text-align: right !important; } }

.align-c {
  text-align: left !important; }
  @media all and (min-width: 800px) {
    .align-c {
      text-align: center !important; } }
  .align-c.-spc {
    text-align: left !important;
    text-align: center !important; }
    @media all and (min-width: 800px) {
      .align-c.-spc {
        text-align: center !important; } }
    @media all and (min-width: 800px) {
      .align-c.-spc {
        text-align: center !important; } }

.br {
  display: none; }
  @media all and (min-width: 800px) {
    .br {
      display: inline-block; } }

/*
｡ : &#xFF61;
､ : &#xFF64;
･(和文) : &#xFF65;
·(欧文) : &#x00B7;
*/
.hover, .p0-btn1_inner, .p0-btn1 a, .p0-btn1_inner:before, .p0-btn1 a:before, .p0-btn1_inner:after, .p0-btn1 a:after, .p0-btn2_inner, .p0-btn2 a, .p0-btn2_inner:after, .p0-btn2 a:after, .p0-btn3_inner, .p0-btn3 a, .p0-btn3:after, .p0-btn4_inner, .p0-btn4 a, .p0-btn4:after, .p0-business_more:after, .p0-bnrs_more:after, .p4-pager .page-numbers, .p4-pager .page-numbers.prev, .p4-pager .page-numbers.next {
  filter: alpha(opacity=100);
  opacity: 1;
  transition: all 0.3s ease-in-out; }
  .hover:hover, .hover:focus, .p0-btn1_inner:hover, .p0-btn1 a:hover, .p0-btn1_inner:hover:before, .p0-btn1 a:hover:before, .p0-btn1_inner:hover:after, .p0-btn1 a:hover:after, .p0-btn2_inner:hover, .p0-btn2 a:hover, .p0-btn2_inner:hover:after, .p0-btn2 a:hover:after, .p0-btn3_inner:hover, .p0-btn3 a:hover, .p0-btn3:hover:after, .p0-btn4_inner:hover, .p0-btn4 a:hover, .p0-btn4:hover:after, .p0-business_more:hover:after, .p0-bnrs_more:hover:after, .p4-pager .page-numbers:hover, .p0-btn1_inner:focus, .p0-btn1 a:focus, .p0-btn1_inner:focus:before, .p0-btn1 a:focus:before, .p0-btn1_inner:focus:after, .p0-btn1 a:focus:after, .p0-btn2_inner:focus, .p0-btn2 a:focus, .p0-btn2_inner:focus:after, .p0-btn2 a:focus:after, .p0-btn3_inner:focus, .p0-btn3 a:focus, .p0-btn3:focus:after, .p0-btn4_inner:focus, .p0-btn4 a:focus, .p0-btn4:focus:after, .p0-business_more:focus:after, .p0-bnrs_more:focus:after, .p4-pager .page-numbers:focus {
    filter: alpha(opacity=70);
    opacity: 0.7; }

.nohover, .p0-btn2_inner, .p0-btn2 a, .p0-btn2_inner.-movearwright:after, .p0-btn2 a.-movearwright:after, .p0-btn2_inner.-movearwright2:after, .p0-btn2 a.-movearwright2:after, .p0-btn3_inner, .p0-btn3 a, .p0-btn3.-movearwright:after, .p0-btn4_inner, .p0-btn4 a, .p0-btn4.-movearwleft:after, .p0-business_item.-movearwright:hover .p0-business_more:after, .p0-bnrs_item.-movearwright:hover .p0-bnrs_more:after, .p4-pager .page-numbers.current {
  filter: alpha(opacity=100);
  opacity: 1; }
  .nohover:hover, .nohover:focus, .p0-btn2_inner:hover, .p0-btn2 a:hover, .p0-btn2_inner.-movearwright:hover:after, .p0-btn2 a.-movearwright:hover:after, .p0-btn2_inner.-movearwright2:hover:after, .p0-btn2 a.-movearwright2:hover:after, .p0-btn3_inner:hover, .p0-btn3 a:hover, .p0-btn3.-movearwright:hover:after, .p0-btn4_inner:hover, .p0-btn4 a:hover, .p0-btn4.-movearwleft:hover:after, .p0-business_item.-movearwright:hover .p0-business_more:hover:after, .p0-bnrs_item.-movearwright:hover .p0-bnrs_more:hover:after, .p4-pager .page-numbers.current:hover, .p0-btn2_inner:focus, .p0-btn2 a:focus, .p0-btn2_inner.-movearwright:focus:after, .p0-btn2 a.-movearwright:focus:after, .p0-btn2_inner.-movearwright2:focus:after, .p0-btn2 a.-movearwright2:focus:after, .p0-btn3_inner:focus, .p0-btn3 a:focus, .p0-btn3.-movearwright:focus:after, .p0-btn4_inner:focus, .p0-btn4 a:focus, .p0-btn4.-movearwleft:focus:after, .p0-business_item.-movearwright:hover .p0-business_more:focus:after, .p0-bnrs_item.-movearwright:hover .p0-bnrs_more:focus:after, .p4-pager .page-numbers.current:focus {
    filter: alpha(opacity=100);
    opacity: 1; }

.nohover.-moveright {
  animation: 0.35s ease-out 0s 1 both hoverMoveRight; }
  .nohover.-moveright:hover, .nohover.-moveright:focus {
    filter: alpha(opacity=100);
    opacity: 1; }
    .nohover.-moveright:hover:after, .nohover.-moveright:focus:after {
      filter: alpha(opacity=100);
      opacity: 1; }

.anchor {
  clear: both;
  position: relative;
  top: -60px;
  display: block; }
  @media all and (min-width: 800px) {
    .anchor {
      top: -72px; } }
  @media all and (min-width: 1000px) {
    .anchor {
      top: -80px; } }

body {
  color: #424851;
  background: white;
  width: 100%;
  font-size: 1.4rem;
  line-height: 1;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 400; }
  @media all and (min-width: 800px) {
    body {
      font-size: 1.6rem; } }
  @media all and (min-width: 1120px) {
    body {
      font-size: 1.8rem; } }

a {
  color: rgba(66, 72, 81, 0.8);
  text-decoration: none; }

::selection {
  background: #d8d8df; }

::-moz-selection {
  background: #d8d8df; }

.js-tellink a {
  color: inherit; }

.l-header {
  position: relative;
  top: 0;
  left: auto;
  z-index: 1000;
  width: 100%;
  height: 100px;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box; }
  @media all and (min-width: 800px) {
    .l-header {
      position: absolute;
      width: 100%;
      height: 80px;
      background-image: none;
      mix-blend-mode: exclusion; } }
  @media all and (min-width: 1620px) {
    .l-header {
      width: 1560px; } }
  .l-header.js-isscroll {
    position: fixed;
    top: 0; }
    @media all and (min-width: 800px) {
      .l-header.js-isscroll {
        width: calc(100% - 40px - 40px); } }
    @media all and (min-width: 1120px) {
      .l-header.js-isscroll {
        width: calc(100% - 30px - 30px); } }
    @media all and (min-width: 1620px) {
      .l-header.js-isscroll {
        width: 1560px; } }
  .l-header.js-noscroll {
    position: relative;
    top: 0; }
  .l-header.js-isopen {
    background: rgba(6, 46, 103, 0.95); }
    @media all and (min-width: 800px) {
      .l-header.js-isopen {
        background: none; } }
  .l-header.js-isclose {
    animation: 0.3s ease-in 0s 1 both drawerOutHeaderBgc; }
    @media all and (min-width: 800px) {
      .l-header.js-isclose {
        animation: none; } }
  .l-header_wrap {
    position: relative;
    overflow: visible;
    width: 100%;
    height: 0;
    margin: 0 auto; }
    @media all and (min-width: 800px) {
      .l-header_wrap {
        width: calc(100% - 40px - 40px);
        height: 80px; } }
    @media all and (min-width: 1120px) {
      .l-header_wrap {
        width: calc(100% - 30px - 30px); } }
    @media all and (min-width: 1620px) {
      .l-header_wrap {
        width: 1560px; } }
  .l-header_inner {
    width: 100%;
    height: 100%;
    max-width: 1620px;
    margin: 0 auto; }

.l-wrap {
  overflow: hidden; }

.l-section {
  width: 100%;
  padding: 30px 0;
  position: relative;
  overflow: hidden; }
  @media all and (min-width: 800px) {
    .l-section {
      padding: 60px 0; } }
  .l-section.-visible {
    overflow: visible; }
  .l-section.-pad0 {
    padding: 0; }
  .l-section.-pb0 {
    padding: 30px 0 0; }
    @media all and (min-width: 800px) {
      .l-section.-pb0 {
        padding: 60px 0 0; } }
  .l-section.-frontfirst {
    padding-top: 100px; }
    @media all and (min-width: 800px) {
      .l-section.-frontfirst {
        padding-top: 100px; } }
    @media all and (min-width: 1120px) {
      .l-section.-frontfirst {
        padding-top: 13.6vw; } }
    @media all and (min-width: 1620px) {
      .l-section.-frontfirst {
        padding-top: 215px; } }
  .l-section.-firstsec {
    padding-top: 100px; }
    @media all and (min-width: 800px) {
      .l-section.-firstsec {
        padding-top: 70px; } }
    @media all and (min-width: 1120px) {
      .l-section.-firstsec {
        padding-top: 11vw; } }
    @media all and (min-width: 1560px) {
      .l-section.-firstsec {
        padding-top: 180px; } }
  .l-section.-largeSpace {
    padding: 40px 0; }
    @media all and (min-width: 800px) {
      .l-section.-largeSpace {
        padding: 80px 0; } }
    .l-section.-largeSpace.-pb0 {
      padding: 40px 0 0; }
      @media all and (min-width: 800px) {
        .l-section.-largeSpace.-pb0 {
          padding: 80px 0 0; } }
  .l-section.-hugeSpace {
    padding: 55px 0 60px; }
    @media all and (min-width: 800px) {
      .l-section.-hugeSpace {
        padding: 70px 0 60px; } }
    @media all and (min-width: 1120px) {
      .l-section.-hugeSpace {
        padding: 150px 0 160px; } }
    .l-section.-hugeSpace.-pt40_70_150 {
      padding-top: 40px; }
      @media all and (min-width: 800px) {
        .l-section.-hugeSpace.-pt40_70_150 {
          padding-top: 70px; } }
      @media all and (min-width: 1120px) {
        .l-section.-hugeSpace.-pt40_70_150 {
          padding-top: 150px; } }
    .l-section.-hugeSpace.-pb0 {
      padding: 80px 0 0; }
      @media all and (min-width: 800px) {
        .l-section.-hugeSpace.-pb0 {
          padding: 160px 0 0; } }
  .l-section.-tocontact {
    padding: 10px 0 0; }
    @media all and (min-width: 800px) {
      .l-section.-tocontact {
        padding: 0 0 0; } }
    @media all and (min-width: 1000px) {
      .l-section.-tocontact {
        padding: 0 0 0; } }
    @media all and (min-width: 1120px) {
      .l-section.-tocontact {
        padding: 10px 0 0; } }
    @media all and (min-width: 1300px) {
      .l-section.-tocontact {
        padding: 40px 0 0; } }
    @media all and (min-width: 1400px) {
      .l-section.-tocontact {
        padding: 60px 0 0; } }
  .l-section.-contactform {
    padding-bottom: 10px; }
    @media all and (min-width: 800px) {
      .l-section.-contactform {
        padding-bottom: 20px; } }
    @media all and (min-width: 1120px) {
      .l-section.-contactform {
        padding-bottom: 0; } }
  .l-section.-zi1 {
    z-index: 1; }
  .l-section.-zi2 {
    z-index: 2; }
  .l-section.-zi3 {
    z-index: 3; }
  .l-section.-zi4 {
    z-index: 4; }
  .l-section.-zi5 {
    z-index: 5; }
  .l-section.-zi6 {
    z-index: 6; }
  .l-section.-zi7 {
    z-index: 7; }
  .l-section.-zi8 {
    z-index: 8; }
  .l-section.-zi9 {
    z-index: 9; }
  .l-section.-zi10 {
    z-index: 10; }
  .l-section.-bg01 {
    background-color: #9a9a9a; }
  .l-section.-bg02 {
    background-color: #062e67; }
  .l-section_bg.-type01 {
    background-color: #9a9a9a; }
  .l-section_bg.-type02 {
    background-color: #062e67; }
  .l-section_bg.-type03 {
    background-color: #0d346b; }
    @media all and (min-width: 650px) {
      .l-section_bg.-type03.-company {
        background-color: transparent; } }
  .l-section_content {
    width: calc( 100% - 5vw - 5vw);
    position: relative;
    overflow: hidden;
    margin: 0 auto; }
    @media all and (min-width: 800px) {
      .l-section_content {
        width: calc( 100% - 40px - 40px); } }
    @media all and (min-width: 1120px) {
      .l-section_content {
        width: calc( 100% - 60px - 60px); } }
    @media all and (min-width: 1620px) {
      .l-section_content {
        width: 1560px; } }
    .l-section_content.-visible {
      overflow: visible; }
    .l-section_content.-content {
      width: 100%;
      max-width: 1000px; }
      @media all and (min-width: 1120px) {
        .l-section_content.-content {
          width: 1000px; } }
    .l-section_content.-wide {
      width: 100vw;
      max-width: 1620px; }
    .l-section_content.-full {
      width: 100%; }

.l-footer {
  padding: 10px 0;
  box-sizing: border-box;
  background: #062e67; }
  @media all and (min-width: 800px) {
    .l-footer {
      padding: 15px 0; } }

.c-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  /*
  &.-odd {
    @media all and (min-width:520px) and (max-width:739px) {
      @include flex-justify-center;
    }
    & .c-flex_item {
      &:nth-child(odd) {
        @media all and (min-width:520px) and (max-width:739px) {
          margin-right: 5%;
        }
      }
      &:last-child {
        @media all and (min-width:520px) and (max-width:739px) {
          margin: 0;
        }
      }
    }
  }
*/
  /*
  &.-post {
    & .c-flex_item {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 20px;
      }
      @media all and (min-width:740px) {
        margin-bottom: 40px;
      }
    }
  }
*/ }
  .c-flex_item {
    /*
    &.-box3 {
      width: 100%;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      @media all and (min-width:520px) {
        width: 47.5%;
        margin-bottom: 30px;
      }
      @media all and (min-width:740px) {
        width: 31%;
        margin-bottom: 0;
      }
      @media all and (min-width:920px) {
        width: 29.3%;
      }
    }
    
    &.-dummy {
      display: none;
      opacity: 0;
      height: 1px;
 
      @media all and (min-width:520px) {
        display: block;
        width: 1%;
      }
    }
*/ }
    .c-flex_item.-box2 {
      width: 100%; }
      @media all and (min-width: 800px) {
        .c-flex_item.-box2 {
          width: 47.5%; } }

/*
.c-flex02 {
  @include flexbox;
  @include flex-direction-column;
  @include flex-wrap-wrap;

  @include breakpoint-mainboxhalf {
    @include flex-direction-row;
    @include flex-wrap-nowrap;
    @include flex-justify-space-between;
  }
  &_item {
    &.-item01 {
      width: 60vw;
      max-width: 500px;
      margin: 0 auto;
      order: 2;
      display: none;
      @include breakpoint-mainboxhalf {
        width: 40%;
        padding: 0 5% 0 0;
        order: 1;
        display: block;
      }
      @include breakpoint-pc {
        width: 50%;
      }
      @media (min-width:900px) {
        width: 500px;
        padding: 0 60px 0 0;
      }
    }
    &.-item02 {
      width: 100%;
      order: 1;
      margin-bottom: 6vw;
      @include breakpoint-mainboxhalf {
        width: 60%;
        order: 2;
        margin-bottom: 0;
      }
      @include breakpoint-pc {
        width: 50%;
      }
      @media (min-width:900px) {
        width: 500px;
      }
    }
  }
}
*/
.p0-btn1 {
  position: relative;
  width: 100%;
  clear: both;
  text-align: center;
  box-sizing: border-box; }
  @media all and (min-width: 375px) {
    .p0-btn1 {
      max-width: 340px; } }
  @media all and (min-width: 800px) {
    .p0-btn1 {
      width: 340px; } }
  .p0-btn1_inner, .p0-btn1 a {
    display: block;
    width: 100%;
    padding: 11px 0 12px;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 500;
    color: white;
    line-height: 1; }
  .p0-btn1_inner, .p0-btn1 a {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 500;
    font-size: 3.4rem;
    color: #424851; }
    @media all and (min-width: 640px) {
      .p0-btn1_inner, .p0-btn1 a {
        font-size: 4rem; } }
  .p0-btn1_inner a {
    padding: 0; }
  .p0-btn1.-w100per {
    width: 100%; }
  .p0-btn1.-ls02 {
    letter-spacing: 0.2rem; }
  .p0-btn1.-blue .p0-btn1_inner:before, .p0-btn1.-blue a:before {
    background-image: url("../images/common/icon/contact_blue.png"); }
  .p0-btn1.-blue .p0-btn1_inner:after, .p0-btn1.-blue a:after {
    background-image: url("../images/common/arw_r_blue.png"); }
  .p0-btn1.-cyan .p0-btn1_inner:before, .p0-btn1.-cyan a:before {
    background-image: url("../images/common/icon/contact_cyan.png"); }
  .p0-btn1.-cyan .p0-btn1_inner:after, .p0-btn1.-cyan a:after {
    background-image: url("../images/common/arw_r_cyan.png"); }
  .p0-btn1.-movearwlr .p0-btn1_inner:after, .p0-btn1.-movearwlr a:after {
    animation: 1.8s ease-out 0s infinite normal forwards running moveArrowLR; }
  .p0-btn1_inner, .p0-btn1 a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .p0-btn1_inner:before, .p0-btn1 a:before {
      position: relative;
      width: 28px;
      height: 23px;
      content: "";
      margin-left: 10px;
      margin-right: 20px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain; }
      @media all and (min-width: 640px) {
        .p0-btn1_inner:before, .p0-btn1 a:before {
          width: 31px;
          height: 25px;
          margin-left: 20px;
          margin-right: 30px; } }
    .p0-btn1_inner:after, .p0-btn1 a:after {
      position: relative;
      width: 54px;
      height: 10px;
      content: "";
      margin-left: 14px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain; }
      @media all and (min-width: 640px) {
        .p0-btn1_inner:after, .p0-btn1 a:after {
          margin-left: 22px; } }

.p0-btn2 {
  display: inline-block;
  position: relative;
  width: auto;
  clear: both;
  text-align: center;
  box-sizing: border-box; }
  .p0-btn2_inner, .p0-btn2 a {
    display: block;
    width: 100%;
    padding: 11px 0 12px;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 500;
    color: white;
    line-height: 1; }
  .p0-btn2_inner, .p0-btn2 a {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 500;
    font-size: 1.6rem;
    color: #0d346b; }
    @media all and (min-width: 1120px) {
      .p0-btn2_inner, .p0-btn2 a {
        font-size: 1.6rem; } }
  .p0-btn2_inner a {
    padding: 0; }
  .p0-btn2.-w100per {
    width: 100%; }
  .p0-btn2.-blue .p0-btn2_inner:after, .p0-btn2.-blue a:after {
    background-image: url("../images/common/arw_r_blue.png"); }
  .p0-btn2.-cyan .p0-btn2_inner:after, .p0-btn2.-cyan a:after {
    background-image: url("../images/common/arw_r_cyan.png"); }
  .p0-btn2.-bgblue03 {
    background-color: #0953b7; }
    .p0-btn2.-bgblue03.-br05 {
      border-radius: 5px; }
    .p0-btn2.-bgblue03 .p0-btn2_inner, .p0-btn2.-bgblue03 a {
      padding: 8px 14px 9px 14px;
      box-sizing: border-box;
      color: white; }
      @media all and (min-width: 375px) {
        .p0-btn2.-bgblue03 .p0-btn2_inner, .p0-btn2.-bgblue03 a {
          padding-left: 30px;
          padding-right: 21px; } }
      @media all and (min-width: 480px) {
        .p0-btn2.-bgblue03 .p0-btn2_inner, .p0-btn2.-bgblue03 a {
          padding-left: 50px;
          padding-right: 35px; } }
      .p0-btn2.-bgblue03 .p0-btn2_inner:after, .p0-btn2.-bgblue03 a:after {
        width: 27px;
        background-image: url("../images/common/arw_r_white.png"); }
        @media all and (min-width: 375px) {
          .p0-btn2.-bgblue03 .p0-btn2_inner:after, .p0-btn2.-bgblue03 a:after {
            width: 54px; } }
  .p0-btn2_inner, .p0-btn2 a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .p0-btn2_inner:after, .p0-btn2 a:after {
      position: relative;
      width: 54px;
      height: 10px;
      content: "";
      margin-left: 12px;
      background-position: right center;
      background-repeat: no-repeat;
      background-size: auto 100%; }
      @media all and (min-width: 1120px) {
        .p0-btn2_inner:after, .p0-btn2 a:after {
          margin-left: 16px; } }
    .p0-btn2_inner.-movearwright:hover, .p0-btn2 a.-movearwright:hover {
      transform: translateX(0); }
      @media all and (min-width: 480px) {
        .p0-btn2_inner.-movearwright:hover:after, .p0-btn2 a.-movearwright:hover:after {
          -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
          -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
          -webkit-transition-property: transform;
          transition-property: transform;
          transform: translateX(20px); } }
    .p0-btn2_inner.-movearwright2:hover, .p0-btn2 a.-movearwright2:hover {
      transform: translateX(0); }
      @media all and (min-width: 480px) {
        .p0-btn2_inner.-movearwright2:hover:after, .p0-btn2 a.-movearwright2:hover:after {
          -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
          -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
          -webkit-transition-property: transform;
          transition-property: transform;
          transform: translateX(4px); } }

.p0-btn3 {
  display: inline-block;
  position: relative;
  width: auto;
  clear: both;
  text-align: center;
  box-sizing: border-box; }
  .p0-btn3_inner, .p0-btn3 a {
    display: block;
    width: 100%;
    padding: 11px 0 12px;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 500;
    color: white;
    line-height: 1; }
  .p0-btn3_inner, .p0-btn3 a {
    position: relative;
    width: auto;
    padding-left: 20px;
    padding-right: 120px;
    box-sizing: border-box;
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 2.2rem;
    color: #062e67; }
    @media all and (min-width: 800px) {
      .p0-btn3_inner, .p0-btn3 a {
        font-size: 2.6rem; } }
  .p0-btn3_inner a {
    padding: 0; }
  .p0-btn3.-w100per {
    width: 100%; }
  .p0-btn3.-blue:after {
    background-image: url("../images/common/arw_r_blue.png"); }
  .p0-btn3.-cyan:after {
    background-image: url("../images/common/arw_r_cyan.png"); }
  .p0-btn3:after {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 40px;
    transform: translate(0, -50%);
    width: 54px;
    height: 10px;
    content: "";
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    pointer-events: none; }
  @media all and (min-width: 480px) {
    .p0-btn3.-movearwright:hover:after {
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
      -webkit-transition-duration: 0.2s;
      transition-duration: 0.2s;
      -webkit-transition-property: transform;
      transition-property: transform;
      transform: translate(20px, -50%); } }

.p0-btn4 {
  display: inline-block;
  position: relative;
  width: auto;
  clear: both;
  text-align: center;
  box-sizing: border-box; }
  .p0-btn4_inner, .p0-btn4 a {
    display: block;
    width: 100%;
    padding: 11px 0 12px;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 500;
    color: white;
    line-height: 1; }
  .p0-btn4_inner, .p0-btn4 a {
    position: relative;
    width: auto;
    padding-left: 100px;
    padding-right: 20px;
    box-sizing: border-box;
    font-family: dnp-shuei-mincho-pr6, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-weight: 500;
    font-size: 1.4rem;
    color: #062e67; }
    @media all and (min-width: 800px) {
      .p0-btn4_inner, .p0-btn4 a {
        padding-left: 110px;
        font-size: 1.6rem; } }
  .p0-btn4_inner a {
    padding: 0; }
  .p0-btn4.-w100per {
    width: 100%; }
  .p0-btn4.-blue:after {
    background-image: url("../images/common/arw_r_blue.png"); }
  .p0-btn4.-cyan:after {
    background-image: url("../images/common/arw_r_cyan.png"); }
  .p0-btn4:after {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translate(0, -50%);
    width: 54px;
    height: 10px;
    content: "";
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    pointer-events: none; }
  .p0-btn4.-movearwleft:after {
    transform: translate(0, -50%) scale(-1, 1); }
  @media all and (min-width: 480px) {
    .p0-btn4.-movearwleft:hover:after {
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
      -webkit-transition-duration: 0.2s;
      transition-duration: 0.2s;
      -webkit-transition-property: transform;
      transition-property: transform;
      transform: translate(-20px, -50%) scale(-1, 1); } }

.p0-flexbtns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch; }
  @media all and (min-width: 800px) {
    .p0-flexbtns {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row; } }
  .p0-flexbtns.-column {
    -ms-flex-direction: column;
    flex-direction: column; }
    @media all and (min-width: 800px) {
      .p0-flexbtns.-column {
        -ms-flex-direction: column;
        flex-direction: column; } }
  .p0-flexbtns.-left {
    -webkit-justify-content: flex-start;
    justify-content: flex-start; }
  .p0-flexbtns.-center {
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    @media all and (min-width: 800px) {
      .p0-flexbtns.-center {
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch; } }
  .p0-flexbtns.-right {
    -webkit-justify-content: flex-end;
    justify-content: flex-end; }
  .p0-flexbtns_item {
    margin-bottom: 20px; }
    @media all and (min-width: 800px) {
      .p0-flexbtns_item {
        margin-bottom: 0;
        margin-right: 2vw; } }
    @media all and (min-width: 1000px) {
      .p0-flexbtns_item {
        margin-right: 20px; } }
    .p0-flexbtns_item:last-of-type {
      margin-bottom: 0; }
      @media all and (min-width: 800px) {
        .p0-flexbtns_item:last-of-type {
          margin-right: 0; } }
      @media all and (min-width: 1000px) {
        .p0-flexbtns_item:last-of-type {
          margin-right: 0; } }

.p0-hero {
  box-sizing: border-box;
  /* トップページ */
  /* 下層ページ */ }
  .p0-hero_content {
    width: 100%;
    height: 100%; }
  .p0-hero_ttl {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 600;
    font-size: 5.2rem;
    line-height: 1;
    text-align: center;
    color: white;
    mix-blend-mode: exclusion; }
    @media all and (min-width: 800px) {
      .p0-hero_ttl {
        line-height: 1; } }
    @media all and (min-width: 580px) {
      .p0-hero_ttl {
        font-size: 9vw; } }
    @media all and (min-width: 800px) {
      .p0-hero_ttl {
        font-size: 8vw;
        letter-spacing: 1px; } }
    @media all and (min-width: 1050px) {
      .p0-hero_ttl {
        font-size: 7.6rem; } }
  .p0-hero.-front {
    position: relative;
    height: 80vh;
    box-sizing: border-box;
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-property: height, border;
    transition-property: height, border;
    border: 0 solid white; }
    @media all and (min-width: 800px) {
      .p0-hero.-front {
        height: 840px; } }
    @media all and (min-width: 1620px) {
      .p0-hero.-front {
        height: 43.2vw; } }
    .p0-hero.-front.js-isscroll {
      border-width: 130px 5vw calc(5vw / 3 * 2) 5vw; }
      @media all and (min-width: 800px) {
        .p0-hero.-front.js-isscroll {
          border-width: 40px 40px 20px 40px; } }
      @media all and (min-width: 1120px) {
        .p0-hero.-front.js-isscroll {
          border-width: 40px 30px 20px 30px; } }
      @media all and (min-width: 1620px) {
        .p0-hero.-front.js-isscroll .p0-hero_bg video {
          width: 100%;
          height: auto; } }
    .p0-hero.-front.js-init {
      height: 100vh;
      border-width: 0 0 0 0; }
      @media all and (min-width: 1120px) {
        .p0-hero.-front.js-init {
          border-width: 0 0 0 0; } }
    .p0-hero.-front .p0-hero_bg {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%; }
      .p0-hero.-front .p0-hero_bg video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        height: 100%;
        min-width: 100%;
        min-height: 100%; }
    .p0-hero.-front .p0-hero_content {
      width: 100%;
      height: 100%; }
    .p0-hero.-front .p0-hero_logo {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      transform: translate(-50%, -50%);
      width: 150px;
      height: auto;
      -webkit-transition-timing-function: ease-in;
      transition-timing-function: ease-in;
      -webkit-transition-duration: 0.5s;
      transition-duration: 0.5s;
      -webkit-transition-property: top;
      transition-property: top; }
      @media all and (min-width: 800px) {
        .p0-hero.-front .p0-hero_logo {
          width: 254px; } }
      .p0-hero.-front .p0-hero_logo_img img {
        width: 100%;
        height: auto; }
    .p0-hero.-front .p0-hero_scroll {
      display: inline-block;
      position: absolute;
      bottom: calc( 30px + ( 5vw / 3 * 2 ));
      left: 50%;
      z-index: 1;
      transform: translate(-50%, 0);
      width: 12px;
      height: auto;
      opacity: 0;
      -webkit-transition-timing-function: ease-in;
      transition-timing-function: ease-in;
      -webkit-transition-duration: 0.5s;
      transition-duration: 0.5s;
      -webkit-transition-property: opacity;
      transition-property: opacity; }
      @media all and (min-width: 800px) {
        .p0-hero.-front .p0-hero_scroll {
          bottom: calc( 50px + 40px);
          width: 18px; } }
      @media all and (min-width: 1120px) {
        .p0-hero.-front .p0-hero_scroll {
          bottom: calc( 48px + 30px); } }
      .p0-hero.-front .p0-hero_scroll_img img {
        width: 100%;
        height: auto; }
    .p0-hero.-front.js-init .p0-hero_scroll {
      opacity: 1; }
  .p0-hero.-lowerpage .p0-hero_logo {
    display: none;
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    mix-blend-mode: exclusion; }
    @media all and (min-width: 800px) {
      .p0-hero.-lowerpage .p0-hero_logo {
        display: block;
        top: 40px; } }
    .p0-hero.-lowerpage .p0-hero_logo img {
      width: 82px;
      height: auto; }
      @media all and (min-width: 800px) {
        .p0-hero.-lowerpage .p0-hero_logo img {
          width: 90px; } }
      @media all and (min-width: 1050px) {
        .p0-hero.-lowerpage .p0-hero_logo img {
          width: 118px; } }
  .p0-hero.-lowerpage .p0-hero_ttl {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .p0-hero.-company {
    width: 90%;
    height: 357px;
    margin: 130px 5vw calc(5vw / 3 * 2);
    box-sizing: border-box; }
    @media all and (min-width: 800px) {
      .p0-hero.-company {
        width: calc( 100% - 40px - 40px);
        height: 44.6808510638298vw;
        margin: 40px 40px 20px; } }
    @media all and (min-width: 1120px) {
      .p0-hero.-company {
        width: calc( 100% - 30px - 30px);
        margin: 30px 30px 20px; } }
    .p0-hero.-company .p0-hero_bg {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
      background-image: url("../images/company/kv_pc.jpg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover; }
  .p0-hero.-business {
    width: 90%;
    height: 357px;
    margin: 130px 5vw calc(5vw / 3 * 2);
    box-sizing: border-box; }
    @media all and (min-width: 800px) {
      .p0-hero.-business {
        width: calc( 100% - 40px - 40px);
        height: 44.6808510638298vw;
        margin: 40px 40px 20px; } }
    @media all and (min-width: 1120px) {
      .p0-hero.-business {
        width: calc( 100% - 30px - 30px);
        margin: 30px 30px 20px; } }
    .p0-hero.-business .p0-hero_bg {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
      /*
      background-image: url("../images/business/kv_pc.jpg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
*/ }
      .p0-hero.-business .p0-hero_bg video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        height: 100%;
        min-width: 100%;
        min-height: 100%; }
  .p0-hero_slider {
    display: block;
    position: relative;
    width: 100%;
    height: 100%; }
    .p0-hero_slider .swiper-container-mv {
      width: 100%;
      height: 100%;
      overflow: hidden; }
    .p0-hero_slider .swiper-wrapper .swiper-slide {
      width: 100%;
      height: 100%;
      overflow: hidden;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden; }
    .p0-hero_slider .slide-item {
      position: relative;
      width: 100%;
      height: 100%; }
      .p0-hero_slider .slide-item_inner {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        animation: 9s linear 0s 1 both mvSize; }
      .p0-hero_slider .slide-item.swiper-slide-active .slide-item_inner {
        animation: 9s linear 0s 1 both mvMove; }
    .p0-hero_slider_dots {
      position: absolute;
      left: 3%;
      bottom: 33px; }
      @media all and (min-width: 800px) {
        .p0-hero_slider_dots {
          left: 0; } }
      .p0-hero_slider_dots ul {
        width: 10px; }
      .p0-hero_slider_dots li {
        display: block;
        width: 10px;
        height: 10px;
        margin-bottom: 10px;
        border-radius: 100px;
        background: rgba(255, 255, 255, 0.8); }
        .p0-hero_slider_dots li:last-child {
          margin-bottom: 0; }
      .p0-hero_slider_dots button {
        width: 10px;
        height: 10px;
        background: color-effect01(0.4);
        text-indent: -9999px;
        border-radius: 100px; }
      .p0-hero_slider_dots .slick-active button {
        background: color-effect01(1); }

.p0-separatebox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  gap: 5vw;
  position: relative;
  width: 100%; }
  @media all and (min-width: 800px) {
    .p0-separatebox {
      gap: 40px; } }
  .p0-separatebox_item {
    width: calc(50% - (5vw / 2)); }
    @media all and (min-width: 800px) {
      .p0-separatebox_item {
        width: calc(50% - (40px / 2)); } }

.p0-topics_list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  gap: 5vw; }
  @media all and (min-width: 375px) {
    .p0-topics_list {
      gap: 5vw; } }
  @media all and (min-width: 800px) {
    .p0-topics_list {
      gap: 40px; } }
  @media all and (min-width: 1120px) {
    .p0-topics_list {
      gap: 60px; } }
  .p0-topics_list_item {
    position: relative;
    width: calc(50% - (5vw / 2)); }
    @media all and (min-width: 375px) {
      .p0-topics_list_item {
        width: calc(50% - (5vw / 2)); } }
    @media all and (min-width: 800px) {
      .p0-topics_list_item {
        width: calc(( 100% / 3 ) - ( 40px * 2 / 3)); } }
    @media all and (min-width: 1120px) {
      .p0-topics_list_item {
        width: calc(( 100% / 3 ) - ( 60px * 2 / 3)); } }
  .p0-topics_list_img {
    width: 100%;
    background-color: white; }
    .p0-topics_list_img_inner {
      position: relative;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover; }
      .p0-topics_list_img_inner.-deco:before {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 11px 11px 0;
        border-color: transparent #06d3cc transparent transparent; }
        @media all and (min-width: 800px) {
          .p0-topics_list_img_inner.-deco:before {
            border-width: 0 15px 15px 0; } }
        @media all and (min-width: 1120px) {
          .p0-topics_list_img_inner.-deco:before {
            border-width: 0 20px 20px 0; } }
      .p0-topics_list_img_inner img {
        width: 100%;
        height: auto; }
  .p0-topics_list_dt {
    margin-bottom: 5px; }
  .p0-topics_list_cat {
    color: #70829b; }
    .p0-topics_list_cat span {
      margin-right: 10px; }

.p0-topics_cats {
  padding-bottom: 30px;
  border-bottom: 1px solid #70829b;
  box-sizing: border-box; }
  .p0-topics_cats_ttl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative; }
    .p0-topics_cats_ttl:after {
      display: block;
      position: relative;
      width: 100%;
      height: 1px;
      content: "";
      margin-left: 10px;
      background-color: #70829b; }
    .p0-topics_cats_ttl_txt {
      -ms-flex-negative: 0;
      flex-shrink: 0; }
  .p0-topics_cats_item {
    padding-bottom: 5px;
    box-sizing: border-box; }
    .p0-topics_cats_item.-current {
      border-bottom: 1px solid #062e67; }

.p0-decoration {
  display: block; }
  .p0-decoration img {
    display: block; }

.p0-business {
  display: block;
  margin-bottom: 160px; }
  @media all and (min-width: 480px) {
    .p0-business {
      margin-bottom: 80px; } }
  @media all and (min-width: 800px) {
    .p0-business {
      margin-bottom: 130px; } }
  @media all and (min-width: 1120px) {
    .p0-business {
      margin-bottom: 170px; } }
  .p0-business_item {
    display: block;
    width: calc( 100% - 5vw);
    max-width: 1400px;
    height: 200px;
    margin: 10px 0 120px auto;
    padding: 0;
    box-sizing: border-box;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 100%; }
    @media all and (min-width: 480px) {
      .p0-business_item {
        height: 380px;
        margin-top: 30px;
        margin-bottom: 0; } }
    @media all and (min-width: 800px) {
      .p0-business_item {
        width: calc( 100% - 40px);
        height: 502px;
        margin-top: 80px; } }
    @media all and (min-width: 1120px) {
      .p0-business_item {
        width: calc( 100% - 60px - 60px);
        margin-right: auto;
        padding: 0 30px; } }
    @media all and (min-width: 1620px) {
      .p0-business_item {
        width: 1560px; } }
    .p0-business_item:last-of-type {
      margin-bottom: 0; }
    .p0-business_item.-b01 .p0-business_box {
      background-image: url("../images/front/business_bg1_pc.jpg"); }
    .p0-business_item.-b02 .p0-business_box {
      background-image: url("../images/front/business_bg2_pc.jpg");
      background-position: left -20px center; }
      @media all and (min-width: 480px) {
        .p0-business_item.-b02 .p0-business_box {
          background-position: left -30px center; } }
      @media all and (min-width: 800px) {
        .p0-business_item.-b02 .p0-business_box {
          background-position: left center; } }
    .p0-business_item.-b03 .p0-business_box {
      background-image: url("../images/front/business_bg3_pc.jpg"); }
    .p0-business_item.-movearwright:hover {
      transform: translateX(0); }
      @media all and (min-width: 480px) {
        .p0-business_item.-movearwright:hover .p0-business_more:after {
          -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
          -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
          -webkit-transition-property: transform;
          transition-property: transform;
          transform: translateX(20px); } }
  .p0-business_box {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 30px 0;
    box-sizing: border-box;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover; }
    @media all and (min-width: 800px) {
      .p0-business_box {
        padding: 41px 0; } }
  .p0-business_inner {
    position: absolute;
    bottom: 20px;
    left: 0;
    transform: translateY(100%);
    width: calc(100% - 5vw);
    padding: 15px 0 30px 0;
    box-sizing: border-box;
    background-color: white; }
    @media all and (min-width: 480px) {
      .p0-business_inner {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        position: relative;
        bottom: auto;
        left: auto;
        transform: translateY(0);
        width: 34vw;
        height: 100%;
        margin: 0 0 0 auto;
        padding-top: 40px;
        padding-right: 3.5vw;
        padding-bottom: 0; } }
    @media all and (min-width: 600px) {
      .p0-business_inner {
        width: 250px;
        padding-right: 5vw; } }
    @media all and (min-width: 800px) {
      .p0-business_inner {
        width: 29vw;
        height: 100%;
        padding-right: 4vw; } }
    @media all and (min-width: 1120px) {
      .p0-business_inner {
        width: 340px;
        padding-right: 60px; } }
  .p0-business_num {
    display: inline-block;
    position: absolute !important;
    bottom: 0;
    left: -3.2vw;
    z-index: 1;
    color: rgba(242, 242, 245, 0.8);
    letter-spacing: .15rem; }
    @media all and (min-width: 480px) {
      .p0-business_num {
        bottom: auto;
        left: auto;
        top: 0;
        right: 0;
        transform: translateY(-25%); } }
    @media all and (min-width: 800px) {
      .p0-business_num {
        right: 0;
        transform: translateY(-35%); } }
    @media all and (min-width: 1120px) {
      .p0-business_num {
        right: 10px;
        transform: translateY(-50%); } }
  .p0-business_en {
    position: absolute !important;
    top: 0;
    right: 0;
    transform: translate(0, -45%);
    letter-spacing: 0.03em; }
    @media all and (min-width: 480px) {
      .p0-business_en {
        right: auto;
        top: 20px;
        left: 0;
        transform: translate(-0.48em, 0);
        writing-mode: vertical-rl;
        text-align: left;
        text-orientation: upright;
        text-orientation: sideways; }
        .p0-business_en .combine {
          text-combine-upright: all; } }
    @media all and (min-width: 800px) {
      .p0-business_en {
        transform: translate(-0.5em, 0); } }
  .p0-business_ttl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    margin-bottom: 10px; }
    @media all and (min-width: 480px) {
      .p0-business_ttl {
        margin-bottom: 0;
        writing-mode: vertical-rl;
        text-align: left;
        text-orientation: upright;
        text-orientation: upright; }
        .p0-business_ttl .combine {
          text-combine-upright: all; } }
    .p0-business_ttl:before {
      position: relative;
      width: 7px;
      height: 13px;
      content: "";
      margin-right: 5px;
      background-image: url("../images/common/tri_r_cyan.png");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain; }
      @media all and (min-width: 480px) {
        .p0-business_ttl:before {
          transform: rotate(90deg);
          width: 9px;
          height: 18px;
          margin-bottom: 2px;
          margin-right: 0; } }
      @media all and (min-width: 800px) {
        .p0-business_ttl:before {
          margin-bottom: 12px; } }
  .p0-business_txt {
    margin-right: 0; }
    @media all and (min-width: 480px) {
      .p0-business_txt {
        margin-right: 16px;
        writing-mode: vertical-rl;
        text-align: left;
        text-orientation: upright;
        text-orientation: upright; }
        .p0-business_txt .combine {
          text-combine-upright: all; } }
    @media all and (min-width: 600px) {
      .p0-business_txt {
        margin-right: 25px; } }
    @media all and (min-width: 800px) {
      .p0-business_txt {
        margin-right: 28px; } }
    .p0-business_txt.-line2 {
      margin-right: 0; }
      @media all and (min-width: 480px) {
        .p0-business_txt.-line2 {
          margin-right: 16px; } }
      @media all and (min-width: 600px) {
        .p0-business_txt.-line2 {
          margin-right: 25px; } }
      @media all and (min-width: 800px) {
        .p0-business_txt.-line2 {
          margin-right: 28px; } }
    .p0-business_txt.-line3 {
      margin-right: 0; }
      @media all and (min-width: 480px) {
        .p0-business_txt.-line3 {
          margin-right: 10px; } }
      @media all and (min-width: 600px) {
        .p0-business_txt.-line3 {
          margin-right: 16px; } }
      @media all and (min-width: 800px) {
        .p0-business_txt.-line3 {
          margin-right: 20px; } }
    .p0-business_txt .-lsminus006 {
      letter-spacing: -0.06em; }
  .p0-business_more:after {
    display: inline-block;
    position: absolute;
    bottom: 10px;
    right: 15px;
    width: 54px;
    height: 10px;
    content: "";
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain; }
    @media all and (min-width: 480px) {
      .p0-business_more:after {
        bottom: 30px;
        right: 5vw; } }
    @media all and (min-width: 800px) {
      .p0-business_more:after {
        right: 40px; } }
    @media all and (min-width: 1120px) {
      .p0-business_more:after {
        right: 60px; } }
  .p0-business_more.-blue:after {
    background-image: url("../images/common/arw_r_blue.png"); }
  .p0-business_headline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    position: relative; }
    .p0-business_headline.-leftborder .p0-business_headline_num:before {
      display: block;
      position: absolute;
      top: 50%;
      left: -2vw;
      transform: translate(-100vw, -50%);
      content: "";
      width: 100vw;
      height: 1px;
      background-color: #062e67; }
      @media all and (min-width: 800px) {
        .p0-business_headline.-leftborder .p0-business_headline_num:before {
          left: -20px; } }
    .p0-business_headline_num {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      position: relative;
      letter-spacing: .15rem; }
      .p0-business_headline_num:after {
        position: relative;
        width: 7px;
        height: 13px;
        content: "";
        margin-left: 11px;
        margin-right: 21px;
        background-image: url("../images/common/tri_r_cyan.png");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain; }
        @media all and (min-width: 800px) {
          .p0-business_headline_num:after {
            width: 9px;
            height: 18px;
            margin-left: 15px;
            margin-right: 35px; } }
        @media all and (min-width: 1120px) {
          .p0-business_headline_num:after {
            left: 0;
            margin-left: 19px;
            margin-right: 37px; } }
    .p0-business_headline_txt {
      margin-top: -3px; }
    .p0-business_headline_en {
      margin-bottom: 1px;
      letter-spacing: 2px; }
      @media all and (min-width: 800px) {
        .p0-business_headline_en {
          margin-bottom: 3px; } }
    .p0-business_headline_jp {
      padding-bottom: 2px;
      box-sizing: border-box; }
      @media all and (min-width: 800px) {
        .p0-business_headline_jp {
          padding-bottom: 4px; } }
  .p0-business_slider {
    padding: 40px 0;
    box-sizing: border-box; }
    @media all and (min-width: 1120px) {
      .p0-business_slider {
        padding: 80px 0; } }
    .p0-business_slider_item {
      position: relative; }
    .p0-business_slider_img {
      position: relative;
      margin-bottom: 7px;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat; }
      @media all and (min-width: 800px) {
        .p0-business_slider_img {
          margin-bottom: 14px; } }
    .p0-business_slider_name {
      margin-bottom: 1px; }
    .p0-business_slider_location {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .p0-business_slider_location:before {
        display: inline-block;
        position: relative;
        width: 7px;
        height: 10px;
        content: "";
        margin-right: 4px;
        background-image: url("../images/common/icon/pin_cyan.png");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain; }
        @media all and (min-width: 800px) {
          .p0-business_slider_location:before {
            width: 9px;
            height: 13px; } }
    .p0-business_slider_sectional {
      display: inline-block;
      position: absolute !important;
      bottom: 0;
      left: 0;
      padding: 3px 7px 4px;
      box-sizing: border-box;
      background-color: white; }
  .p0-business_slider_pagination.swiper-pagination-bullets {
    bottom: 10px !important;
    height: 20px !important; }
    @media all and (min-width: 1120px) {
      .p0-business_slider_pagination.swiper-pagination-bullets {
        bottom: 20px !important; } }
    .p0-business_slider_pagination.swiper-pagination-bullets .swiper-pagination-bullet {
      width: 8px !important;
      height: 8px !important;
      opacity: 1;
      background-color: rgba(6, 211, 204, 0.28); }
      .p0-business_slider_pagination.swiper-pagination-bullets .swiper-pagination-bullet-active {
        background-color: #06d3cc; }

.p0-brand {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  gap: 35px 5vw;
  position: relative;
  width: 100%;
  max-width: 360px;
  margin: 0 auto; }
  @media all and (min-width: 375px) {
    .p0-brand {
      gap: 35px 20px; } }
  @media all and (min-width: 800px) {
    .p0-brand {
      gap: 0 30px;
      max-width: 910px;
      margin-left: 0; } }
  .p0-brand_item {
    width: calc( 50% - (5vw / 2)); }
    @media all and (min-width: 375px) {
      .p0-brand_item {
        width: calc( 50% - (20px / 2)); } }
    @media all and (min-width: 800px) {
      .p0-brand_item {
        width: calc( 25% - ((30px * 3) / 4)); } }
  .p0-brand_sitelink {
    display: block;
    /*
    .p0-brand_img img, .p0-brand_name {
      filter:alpha(opacity=100);
      opacity:1;
      transition: all 0.3s ease-in-out;
    }
*/ }
    .p0-brand_sitelink:hover, .p0-brand_sitelink:focus {
      /*
      .p0-brand_img img, .p0-brand_name {
        filter:alpha(opacity=70);
        opacity:0.7;
      }
*/ }
      .p0-brand_sitelink:hover .p0-brand_name_txt, .p0-brand_sitelink:focus .p0-brand_name_txt {
        border-bottom-color: white; }
    .p0-brand_sitelink .p0-brand_name_txt {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0); }
      .p0-brand_sitelink .p0-brand_name_txt:after {
        display: inline-block;
        position: relative;
        width: 14px;
        height: 14px;
        content: "";
        margin-left: 6px;
        background-image: url("../images/common/icon/exlink.png");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain; }
        @media all and (min-width: 800px) {
          .p0-brand_sitelink .p0-brand_name_txt:after {
            margin-left: 8px; } }
  .p0-brand_img {
    margin-bottom: 12px; }
    @media all and (min-width: 800px) {
      .p0-brand_img {
        margin-bottom: 15px; } }
  .p0-brand_name {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .p0-brand_name_txt {
      padding-bottom: 3px;
      box-sizing: border-box;
      -webkit-transition-timing-function: linear;
      transition-timing-function: linear;
      -webkit-transition-duration: 0.25s;
      transition-duration: 0.25s;
      -webkit-transition-property: border-bottom-color;
      transition-property: border-bottom-color; }
  .p0-brand_sns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0 14px; }
    .p0-brand_sns a {
      display: inline-block; }
    .p0-brand_sns img {
      width: auto;
      height: 29px; }

.p0-ceo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  width: 100%;
  padding-bottom: 30px;
  box-sizing: border-box; }
  @media all and (min-width: 650px) {
    .p0-ceo {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch; } }
  @media all and (min-width: 800px) {
    .p0-ceo {
      padding-bottom: 0; } }
  .p0-ceo_img {
    width: 300px;
    max-width: 100%; }
    @media all and (min-width: 375px) {
      .p0-ceo_img {
        max-width: none; } }
    @media all and (min-width: 650px) {
      .p0-ceo_img {
        width: 37.5vw; } }
    @media all and (min-width: 800px) {
      .p0-ceo_img {
        width: 300px; } }
  .p0-ceo_txt {
    width: 300px;
    max-width: 100%;
    padding-left: 0;
    box-sizing: border-box; }
    @media all and (min-width: 375px) {
      .p0-ceo_txt {
        max-width: none; } }
    @media all and (min-width: 650px) {
      .p0-ceo_txt {
        width: calc( 100% - 300px);
        padding-left: 20px; } }
    @media all and (min-width: 800px) {
      .p0-ceo_txt {
        padding-left: 25px; } }
    @media all and (min-width: 1120px) {
      .p0-ceo_txt {
        padding-left: 2.4691358024691vw; } }
    @media all and (min-width: 1620px) {
      .p0-ceo_txt {
        padding-left: 40px; } }
    .p0-ceo_txt_head {
      position: relative;
      transform: translateX(-11px);
      margin-top: -46px;
      margin-bottom: 20px; }
      @media all and (min-width: 650px) {
        .p0-ceo_txt_head {
          transform: translateX(0);
          width: auto;
          margin-top: 0;
          margin-bottom: 40px; } }
      @media all and (min-width: 700px) {
        .p0-ceo_txt_head {
          margin-bottom: 60px; } }
      @media all and (min-width: 800px) {
        .p0-ceo_txt_head {
          margin-bottom: 80px; } }
    .p0-ceo_txt_body {
      transform: translateX(0);
      padding-left: 0;
      box-sizing: border-box; }
      @media all and (min-width: 650px) {
        .p0-ceo_txt_body {
          transform: translateX(0);
          padding-left: 23px; } }
      @media all and (min-width: 800px) {
        .p0-ceo_txt_body {
          padding-left: 25px; } }
      @media all and (min-width: 1120px) {
        .p0-ceo_txt_body {
          padding-left: 25px; } }
      @media all and (min-width: 1620px) {
        .p0-ceo_txt_body {
          padding-left: 25px; } }
    .p0-ceo_txt_greeting {
      width: 100%;
      padding-top: 15px;
      box-sizing: border-box; }
      .p0-ceo_txt_greeting img {
        width: 193px;
        height: auto; }
        @media all and (min-width: 650px) {
          .p0-ceo_txt_greeting img {
            width: 193px; } }
        @media all and (min-width: 800px) {
          .p0-ceo_txt_greeting img {
            width: 219px; } }
    .p0-ceo_txt_ttl {
      position: absolute;
      bottom: 10px;
      left: 100px;
      padding: 4px 13px 4px 17px;
      box-sizing: border-box;
      font-family: dnp-shuei-mincho-pr6, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-weight: 600;
      font-size: 1.5rem;
      color: #062e67;
      line-height: 1;
      letter-spacing: 6px;
      background-color: #06d3cc; }
      @media all and (min-width: 800px) {
        .p0-ceo_txt_ttl {
          font-size: 1.8rem; } }
      @media all and (min-width: 800px) {
        .p0-ceo_txt_ttl {
          bottom: 3px;
          left: 95px;
          padding: 4px 16px 4px 22px; } }
      @media all and (min-width: 1120px) {
        .p0-ceo_txt_ttl {
          bottom: 0; } }
    .p0-ceo_txt_cnamejp {
      margin-bottom: 5px;
      font-size: 1.3rem;
      color: white; }
      @media all and (min-width: 800px) {
        .p0-ceo_txt_cnamejp {
          font-size: 1.5rem; } }
      @media all and (min-width: 650px) {
        .p0-ceo_txt_cnamejp {
          color: #062e67; } }
      @media all and (min-width: 800px) {
        .p0-ceo_txt_cnamejp {
          margin-bottom: 8px;
          color: #062e67; } }
    .p0-ceo_txt_cnameen {
      margin-bottom: 10px;
      background-image: url("../images/front/company_name_en_wh.png");
      background-position: left top;
      background-repeat: no-repeat;
      background-size: 165px auto; }
      @media all and (min-width: 650px) {
        .p0-ceo_txt_cnameen {
          background-image: url("../images/front/company_name_en_bl.png");
          background-size: 165px auto; } }
      @media all and (min-width: 800px) {
        .p0-ceo_txt_cnameen {
          background-size: 187px auto; } }
      .p0-ceo_txt_cnameen img {
        width: 165px;
        opacity: 0; }
        @media all and (min-width: 650px) {
          .p0-ceo_txt_cnameen img {
            width: 165px; } }
        @media all and (min-width: 800px) {
          .p0-ceo_txt_cnameen img {
            width: 187px; } }
    .p0-ceo_txt_name {
      font-family: dnp-shuei-mincho-pr6, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-weight: 600;
      font-size: 2.4rem;
      color: white;
      letter-spacing: 1px; }
      @media all and (min-width: 800px) {
        .p0-ceo_txt_name {
          font-size: 3rem; } }
      @media all and (min-width: 650px) {
        .p0-ceo_txt_name {
          color: #062e67; } }
      @media all and (min-width: 800px) {
        .p0-ceo_txt_name {
          color: #062e67; } }
      .p0-ceo_txt_name span {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-weight: 600;
        font-size: 1.3rem;
        line-height: 1;
        letter-spacing: 0.5px;
        color: #06d3cc; }
        @media all and (min-width: 800px) {
          .p0-ceo_txt_name span {
            font-size: 1.5rem; } }
        @media all and (min-width: 650px) {
          .p0-ceo_txt_name span:before {
            content: "\a";
            white-space: pre; } }
        @media all and (min-width: 800px) {
          .p0-ceo_txt_name span:before {
            content: "";
            white-space: normal; } }

.p0-info {
  padding: 22px 0 15px;
  border-bottom: 1px solid #d8d8df;
  box-sizing: border-box; }
  @media all and (min-width: 800px) {
    .p0-info {
      padding: 27px 0 20px; } }
  .p0-info:first-of-type {
    margin-top: 20px;
    border-top: 1px solid #d8d8df; }
    @media all and (min-width: 800px) {
      .p0-info:first-of-type {
        margin-top: 0;
        border-top-style: none; } }

.p0-bnrs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: calc( 100% - 5vw);
  max-width: 1400px;
  margin-left: auto;
  margin-right: 0;
  padding: 0; }
  @media all and (min-width: 800px) {
    .p0-bnrs {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
      width: calc( 100% - 40px - 40px);
      margin-right: auto; } }
  @media all and (min-width: 1120px) {
    .p0-bnrs {
      width: calc( 100% - 60px - 60px);
      padding: 0 30px; } }
  @media all and (min-width: 1620px) {
    .p0-bnrs {
      width: 1560px; } }
  .p0-bnrs_item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    position: relative;
    width: 100%;
    height: 200px;
    margin: 10px 0 120px auto;
    padding: 0;
    box-sizing: border-box;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover; }
    @media all and (min-width: 480px) {
      .p0-bnrs_item {
        display: block;
        height: 380px;
        margin-top: 30px;
        margin-bottom: 0; } }
    @media all and (min-width: 800px) {
      .p0-bnrs_item {
        width: calc(50% - 20px);
        height: auto;
        margin-top: 0;
        margin-left: 0; } }
    .p0-bnrs_item:first-of-type {
      margin-top: 0; }
    .p0-bnrs_item.-bnr01 {
      background-image: url("../images/front/company_info_bg.jpg"); }
    .p0-bnrs_item.-bnr02 {
      background-image: url("../images/front/company_history_bg.jpg"); }
    .p0-bnrs_item.-movearwright:hover {
      transform: translateX(0); }
      @media all and (min-width: 480px) {
        .p0-bnrs_item.-movearwright:hover .p0-bnrs_more:after {
          -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
          -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
          -webkit-transition-property: transform;
          transition-property: transform;
          transform: translateX(20px); } }
  .p0-bnrs_box {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 30px 0;
    box-sizing: border-box;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover; }
    @media all and (min-width: 800px) {
      .p0-bnrs_box {
        padding: 41px 0; } }
  .p0-bnrs_inner {
    position: absolute;
    bottom: 20px;
    left: 0;
    transform: translateY(100%);
    width: calc(100% - 5vw);
    padding: 15px 0 30px 0;
    box-sizing: border-box;
    background-color: white; }
    @media all and (min-width: 480px) {
      .p0-bnrs_inner {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        position: relative;
        bottom: auto;
        left: auto;
        transform: translateY(0);
        width: 34vw;
        height: 100%;
        margin: 0 0 0 auto;
        padding-top: 40px;
        padding-right: 3.5vw;
        padding-bottom: 0; } }
    @media all and (min-width: 600px) {
      .p0-bnrs_inner {
        width: 250px;
        padding-right: 5vw; } }
    @media all and (min-width: 800px) {
      .p0-bnrs_inner {
        width: 29vw;
        height: 100%;
        padding-right: 4vw; } }
    @media all and (min-width: 1120px) {
      .p0-bnrs_inner {
        width: 340px;
        padding-right: 60px; } }
  .p0-bnrs_bg {
    display: none; }
    @media all and (min-width: 800px) {
      .p0-bnrs_bg {
        display: block; } }
  .p0-bnrs_ttl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    transform: translateY(90px);
    width: calc(100% - 5vw);
    padding: 15px 0 10px;
    box-sizing: border-box;
    background-color: white; }
    @media all and (min-width: 480px) {
      .p0-bnrs_ttl {
        position: absolute;
        top: 0;
        right: 5vw;
        transform: translateY(0);
        width: auto;
        padding: 0 14px 30px 14px;
        writing-mode: vertical-rl;
        text-align: left;
        text-orientation: upright;
        text-orientation: sideways; }
        .p0-bnrs_ttl .combine {
          text-combine-upright: all; } }
    @media all and (min-width: 800px) {
      .p0-bnrs_ttl {
        right: 20px; } }
    .p0-bnrs_ttl:before {
      position: relative;
      width: 7px;
      height: 13px;
      content: "";
      margin-right: 5px;
      background-image: url("../images/common/tri_r_cyan.png");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain; }
      @media all and (min-width: 480px) {
        .p0-bnrs_ttl:before {
          transform: rotate(90deg);
          width: 9px;
          height: 18px;
          margin-bottom: 2px;
          margin-right: 0; } }
      @media all and (min-width: 800px) {
        .p0-bnrs_ttl:before {
          margin-bottom: 12px; } }
  .p0-bnrs_txt {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    transform: translateY(90px);
    width: calc(100% - 5vw);
    padding: 0 0 30px 0;
    box-sizing: border-box;
    background-color: white; }
    @media all and (min-width: 480px) {
      .p0-bnrs_txt {
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translateY(0);
        width: auto;
        padding: 10px 20px 10px 30px; } }
  .p0-bnrs_more:after {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    bottom: 10px;
    right: 15px;
    width: 54px;
    height: 10px;
    content: "";
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain; }
    @media all and (min-width: 480px) {
      .p0-bnrs_more:after {
        position: relative;
        bottom: auto;
        margin-left: 30px; } }
  .p0-bnrs_more.-blue:after {
    background-image: url("../images/common/arw_r_blue.png"); }

.p0-tocontact {
  position: relative; }
  .p0-tocontact_left, .p0-tocontact_right {
    display: inline-block;
    position: absolute;
    bottom: 0;
    height: 100px; }
    @media all and (min-width: 375px) {
      .p0-tocontact_left, .p0-tocontact_right {
        height: 111px; } }
    @media all and (min-width: 480px) {
      .p0-tocontact_left, .p0-tocontact_right {
        height: 136px; } }
    @media all and (min-width: 560px) {
      .p0-tocontact_left, .p0-tocontact_right {
        height: 136px; } }
    @media all and (min-width: 800px) {
      .p0-tocontact_left, .p0-tocontact_right {
        height: 161px; } }
    @media all and (min-width: 1120px) {
      .p0-tocontact_left, .p0-tocontact_right {
        height: 233px; } }
    @media all and (min-width: 1300px) {
      .p0-tocontact_left, .p0-tocontact_right {
        height: 305px; } }
    .p0-tocontact_left img, .p0-tocontact_right img {
      width: auto;
      height: 100%; }
  .p0-tocontact_left {
    left: 0; }
  .p0-tocontact_right {
    right: 0; }
  .p0-tocontact:before, .p0-tocontact:after {
    display: block;
    position: absolute;
    bottom: 0;
    content: "";
    width: 100vw;
    height: 100px;
    background-color: #0953b7; }
    @media all and (min-width: 375px) {
      .p0-tocontact:before, .p0-tocontact:after {
        height: 111px; } }
    @media all and (min-width: 480px) {
      .p0-tocontact:before, .p0-tocontact:after {
        height: 136px; } }
    @media all and (min-width: 560px) {
      .p0-tocontact:before, .p0-tocontact:after {
        height: 136px; } }
    @media all and (min-width: 800px) {
      .p0-tocontact:before, .p0-tocontact:after {
        height: 161px; } }
    @media all and (min-width: 1120px) {
      .p0-tocontact:before, .p0-tocontact:after {
        height: 233px; } }
    @media all and (min-width: 1300px) {
      .p0-tocontact:before, .p0-tocontact:after {
        height: 305px; } }
  .p0-tocontact:before {
    left: 0;
    transform: translateX(-100%); }
  .p0-tocontact:after {
    right: 0;
    transform: translateX(100%); }
  .p0-tocontact_box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    gap: 0 0;
    margin-top: -5px;
    padding-bottom: 40px;
    box-sizing: border-box; }
    @media all and (min-width: 560px) {
      .p0-tocontact_box {
        gap: 0 5px; } }
    @media all and (min-width: 680px) {
      .p0-tocontact_box {
        gap: 0 10px; } }
    @media all and (min-width: 800px) {
      .p0-tocontact_box {
        gap: 0 10px;
        padding-bottom: 30px; } }
    @media all and (min-width: 850px) {
      .p0-tocontact_box {
        gap: 0 20px; } }
    @media all and (min-width: 1120px) {
      .p0-tocontact_box {
        gap: 0 35px;
        padding-bottom: 50px; } }
    @media all and (min-width: 1300px) {
      .p0-tocontact_box {
        gap: 0 50px;
        padding-bottom: 70px; } }
  .p0-tocontact_item {
    width: 100px;
    box-sizing: border-box; }
    .p0-tocontact_item:nth-of-type(1), .p0-tocontact_item:nth-of-type(3) {
      padding-top: 0; }
      @media all and (min-width: 800px) {
        .p0-tocontact_item:nth-of-type(1), .p0-tocontact_item:nth-of-type(3) {
          padding-top: 60px; } }
    .p0-tocontact_item:nth-of-type(1) {
      transform: translateX(40px); }
      @media all and (min-width: 560px) {
        .p0-tocontact_item:nth-of-type(1) {
          transform: translateX(0); } }
    .p0-tocontact_item:nth-of-type(2) {
      padding-top: 105px; }
      @media all and (min-width: 560px) {
        .p0-tocontact_item:nth-of-type(2) {
          padding-top: 40px; } }
      @media all and (min-width: 800px) {
        .p0-tocontact_item:nth-of-type(2) {
          padding-top: 0; } }
    .p0-tocontact_item:nth-of-type(3) {
      transform: translateX(-40px); }
      @media all and (min-width: 560px) {
        .p0-tocontact_item:nth-of-type(3) {
          transform: translateX(0); } }
    @media all and (min-width: 340px) {
      .p0-tocontact_item {
        width: 110px; } }
    @media all and (min-width: 680px) {
      .p0-tocontact_item {
        width: 130px; } }
    @media all and (min-width: 800px) {
      .p0-tocontact_item {
        width: 150px; } }
    @media all and (min-width: 1120px) {
      .p0-tocontact_item {
        width: 165px; } }
    @media all and (min-width: 1300px) {
      .p0-tocontact_item {
        width: 180px; } }
  .p0-tocontact.-contactform .p0-tocontact_left, .p0-tocontact.-contactform .p0-tocontact_right {
    display: none; }
    @media all and (min-width: 480px) {
      .p0-tocontact.-contactform .p0-tocontact_left, .p0-tocontact.-contactform .p0-tocontact_right {
        display: inline-block; } }

.p0-marquee {
  display: flex;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100vw;
  height: auto; }
  .p0-marquee_txt {
    flex: 0 0 auto; }
    .p0-marquee_txt.-marquee01 {
      position: relative;
      animation: 24s marquee01 -12s linear infinite;
      white-space: nowrap; }
      @media all and (min-width: 800px) {
        .p0-marquee_txt.-marquee01:nth-child(odd) {
          animation-duration: 30s;
          animation-delay: -15s; } }
      @media all and (min-width: 1620px) {
        .p0-marquee_txt.-marquee01:nth-child(odd) {
          animation-duration: 36s;
          animation-delay: -18s; } }
      .p0-marquee_txt.-marquee01:nth-child(even) {
        animation: 24s marquee01Copy linear infinite; }
        @media all and (min-width: 800px) {
          .p0-marquee_txt.-marquee01:nth-child(even) {
            animation-duration: 30s; } }
        @media all and (min-width: 1620px) {
          .p0-marquee_txt.-marquee01:nth-child(even) {
            animation-duration: 36s; } }
    .p0-marquee_txt.-marquee02 {
      position: relative;
      animation: 24s marquee02 -12s linear infinite;
      white-space: nowrap; }
      @media all and (min-width: 800px) {
        .p0-marquee_txt.-marquee02:nth-child(odd) {
          animation-duration: 30s;
          animation-delay: -15s; } }
      @media all and (min-width: 1620px) {
        .p0-marquee_txt.-marquee02:nth-child(odd) {
          animation-duration: 36s;
          animation-delay: -18s; } }
      .p0-marquee_txt.-marquee02:nth-child(even) {
        animation: 24s marquee02Copy linear infinite; }
        @media all and (min-width: 800px) {
          .p0-marquee_txt.-marquee02:nth-child(even) {
            animation-duration: 30s; } }
        @media all and (min-width: 1620px) {
          .p0-marquee_txt.-marquee02:nth-child(even) {
            animation-duration: 36s; } }

.p0-history {
  position: relative;
  width: 100%;
  margin: -5px auto 65px 0; }
  @media all and (min-width: 800px) {
    .p0-history {
      margin-top: -10px;
      margin-bottom: 130px; } }
  .p0-history_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    position: relative;
    z-index: 1;
    padding: 15px 0;
    box-sizing: border-box; }
    @media all and (min-width: 800px) {
      .p0-history_row {
        padding: 30px 0; } }
    .p0-history_row:first-of-type .p0-history_y:before {
      transform: translate(50%, -10px);
      height: calc(100% + 15px + 15px + 10px); }
      @media all and (min-width: 800px) {
        .p0-history_row:first-of-type .p0-history_y:before {
          height: calc(100% + 30px + 30px + 10px); } }
    .p0-history_row:last-of-type .p0-history_y:before {
      height: calc(100% + 15px + 15px + 10px); }
      @media all and (min-width: 800px) {
        .p0-history_row:last-of-type .p0-history_y:before {
          height: calc(100% + 30px + 30px + 20px); } }
    .p0-history_row.-sameyear {
      padding-top: 0; }
      @media all and (min-width: 800px) {
        .p0-history_row.-sameyear {
          padding-top: 0; } }
      .p0-history_row.-sameyear .p0-history_y:after {
        display: none; }
    .p0-history_row_end {
      display: block;
      width: 100%;
      height: 15px;
      padding: 0; }
      @media all and (min-width: 800px) {
        .p0-history_row_end {
          height: 30px; } }
  .p0-history_y {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    position: relative;
    width: 85px;
    padding: 0 35px 0 0;
    box-sizing: border-box; }
    @media all and (min-width: 800px) {
      .p0-history_y {
        width: 130px;
        padding: 0 35px 0 0; } }
    .p0-history_y:before {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, 0);
      content: "";
      width: 3px;
      height: calc(100% + 15px + 15px);
      background-color: #d1d5d9; }
      @media all and (min-width: 800px) {
        .p0-history_y:before {
          height: calc(100% + 30px + 30px); } }
    .p0-history_y:after {
      display: block;
      position: absolute;
      top: 4px;
      right: 0;
      transform: translate(50%, 0);
      width: 15px;
      height: 15px;
      content: "";
      border-radius: 50%;
      background-color: #06d3cc; }
      @media all and (min-width: 800px) {
        .p0-history_y:after {
          top: 6px; } }
    .p0-history_y_above {
      margin-bottom: 5px;
      white-space: nowrap; }
      .p0-history_y_above.-empty {
        margin-bottom: 0; }
  .p0-history_tag {
    display: inline-block;
    margin-bottom: 10px;
    padding: 4px 5px;
    box-sizing: border-box;
    color: white; }
    @media all and (min-width: 800px) {
      .p0-history_tag {
        margin-bottom: 13px; } }
    .p0-history_tag + .p0-history_lead {
      margin-top: 0; }
      @media all and (min-width: 800px) {
        .p0-history_tag + .p0-history_lead {
          margin-top: 0; } }
  .p0-history_lead {
    margin-top: -4px;
    margin-bottom: 5px; }
    @media all and (min-width: 800px) {
      .p0-history_lead {
        margin-top: -2px;
        margin-bottom: 7px; } }
  .p0-history_txt {
    width: 100%;
    padding-top: 1px;
    padding-left: 20px;
    box-sizing: border-box;
    line-height: 1; }
    @media all and (min-width: 800px) {
      .p0-history_txt {
        padding-top: 0;
        padding-left: 30px; } }
    .p0-history_txt p {
      padding-top: 1px;
      box-sizing: border-box; }

/* トップページのみで使用するCSS */
.p0-front_maincontent {
  display: block; }
  .p0-front_maincontent.js-init {
    display: none; }

.p0-front_separationbox_img1 {
  position: relative;
  width: auto;
  height: 130px; }
  @media all and (min-width: 375px) {
    .p0-front_separationbox_img1 {
      height: 34.6vw; } }
  @media all and (min-width: 800px) {
    .p0-front_separationbox_img1 {
      height: 35.3vw; } }
  @media all and (min-width: 1120px) {
    .p0-front_separationbox_img1 {
      height: 480px; } }
  .p0-front_separationbox_img1 img {
    position: relative;
    top: -1px;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    height: calc( 100% + 2px); }

.p1-logo {
  display: block;
  position: absolute;
  top: 20px;
  left: 5vw;
  z-index: 1200;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  mix-blend-mode: exclusion;
  opacity: 0;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  pointer-events: none; }
  @media all and (min-width: 560px) {
    .p1-logo {
      left: 5vw; } }
  @media all and (min-width: 800px) {
    .p1-logo {
      top: 20px;
      left: auto; } }
  @media all and (min-width: 1120px) {
    .p1-logo {
      top: 135px; } }
  .p1-logo.js-isscroll {
    position: fixed;
    opacity: 1; }
  .p1-logo.js-noscroll {
    position: fixed;
    opacity: 1; }
    @media all and (min-width: 800px) {
      .p1-logo.js-noscroll {
        position: absolute;
        opacity: 1; } }
    .p1-logo.js-noscroll.js-isopen {
      opacity: 1; }
  .p1-logo_item {
    display: block;
    width: 85px;
    height: auto;
    pointer-events: auto; }
    @media all and (min-width: 800px) {
      .p1-logo_item {
        width: 118px; } }
    @media all and (min-width: 1120px) {
      .p1-logo_item {
        width: 17.948717948717949vw; } }
    @media all and (min-width: 1560px) {
      .p1-logo_item {
        width: 280px; } }
  .p1-logo_img {
    display: block;
    width: 100%;
    height: auto; }

.p1-drawer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  transform: translateY(-100vh); }
  @media all and (min-width: 800px) {
    .p1-drawer {
      position: relative;
      height: 80px;
      transform: translateY(0) !important; } }
  @media all and (min-width: 1000px) {
    .p1-drawer {
      height: 80px; } }
  .p1-drawer.js-isopen {
    animation: 0.6s ease-out 0s 1 both drawerIn; }
  .p1-drawer.js-isclose {
    background-color: red;
    animation: 0.4s ease-in 0s 1 both drawerOut; }
  .p1-drawer-wrap {
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    overflow: hidden; }
    @media all and (min-width: 800px) {
      .p1-drawer-wrap {
        position: relative;
        top: 0;
        height: 80px; } }
    .p1-drawer-wrap.js-isopen {
      height: calc(100vh - 100px); }
      @media all and (min-width: 800px) {
        .p1-drawer-wrap.js-isopen {
          height: calc(100vh - 80px); } }
    .p1-drawer-wrap.js-isclose {
      animation: 0.3s ease-in 0s 1 both drawerWrapOut; }
  .p1-drawer-open {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: fixed;
    top: 29px;
    right: 5vw;
    z-index: 1200;
    /* 重なり順を一番上に */
    width: 40px;
    height: 34px;
    box-sizing: border-box;
    cursor: pointer;
    mix-blend-mode: exclusion; }
    @media all and (min-width: 800px) {
      .p1-drawer-open {
        display: none; } }
    .p1-drawer-open.js-init {
      display: none; }
    .p1-drawer-open span, .p1-drawer-open span:before, .p1-drawer-open span:after {
      display: block;
      position: absolute;
      left: 0;
      height: 2px;
      width: 40px;
      content: '';
      background-color: #f9d198;
      transition: 0.5s; }
    .p1-drawer-open span {
      width: 25px; }
      .p1-drawer-open span::before {
        bottom: 14px;
        transform-origin: top left; }
      .p1-drawer-open span::after {
        top: 14px;
        transform-origin: bottom left; }
    .p1-drawer-open.js-isopen span {
      background-color: rgba(66, 72, 81, 0); }
    .p1-drawer-open.js-isopen span::before {
      transform: translate(6px, 1px) rotate(40.36deg); }
    .p1-drawer-open.js-isopen span::after {
      transform: translate(6px, -1px) rotate(-40.36deg); }

.p1-gnav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%; }
  .p1-gnav_content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 5%;
    box-sizing: border-box; }
    @media all and (min-width: 800px) {
      .p1-gnav_content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-justify-content: flex-end;
        justify-content: flex-end;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        position: relative;
        transform: translateY(0);
        width: 100%;
        height: 100%;
        padding: 0;
        background-image: none;
        background-color: transparent; } }
  .p1-gnav_list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: center;
    justify-content: center;
    width: 100%;
    max-width: 340px;
    gap: 0 40px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 15px;
    box-sizing: border-box; }
    @media all and (min-width: 800px) {
      .p1-gnav_list {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: flex-end;
        justify-content: flex-end;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-line-pack: center;
        align-content: center;
        max-width: none;
        height: 100%;
        gap: 0 26px;
        padding: 0; } }
  .p1-gnav_item {
    display: block;
    position: relative;
    width: calc( 50% - 40px);
    padding: 0;
    border-bottom: 1px solid white;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 400;
    font-size: 2rem;
    line-height: 1;
    letter-spacing: .12rem; }
    @media all and (min-width: 800px) {
      .p1-gnav_item {
        width: auto;
        height: 100%;
        border-bottom-style: none;
        font-size: 1.8rem; } }
    .p1-gnav_item.-pconly {
      display: none; }
      @media all and (min-width: 800px) {
        .p1-gnav_item.-pconly {
          display: block; } }
    .p1-gnav_item.-sponly {
      display: block; }
      @media all and (min-width: 800px) {
        .p1-gnav_item.-sponly {
          display: none; } }
    .p1-gnav_item.-spnoborder {
      border-bottom-style: none; }
    .p1-gnav_item.-contact {
      width: 100%;
      margin-top: 20px;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 400;
      font-size: 3.4rem; }
      @media all and (min-width: 800px) {
        .p1-gnav_item.-contact {
          margin-top: 0;
          font-family: 'Montserrat', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-weight: 300;
          font-size: 1.8rem; } }
      .p1-gnav_item.-contact a {
        color: white; }
  .p1-gnav_itemInner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    padding: 10px 0;
    box-sizing: border-box;
    color: white; }
    @media all and (min-width: 375px) {
      .p1-gnav_itemInner {
        padding: 15px 0; } }
    @media all and (min-width: 800px) {
      .p1-gnav_itemInner {
        -webkit-justify-content: center;
        justify-content: center;
        padding: 0;
        color: #f9d198; } }

.p2-copyright {
  margin-bottom: 7px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-weight: 400;
  font-size: 1.1rem;
  text-align: center;
  line-height: 1;
  color: white; }
  @media all and (min-width: 800px) {
    .p2-copyright {
      font-size: 1.3rem; } }

.p2-ftinfo_privacy {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-weight: 400;
  font-size: 1rem;
  text-align: center; }
  @media all and (min-width: 800px) {
    .p2-ftinfo_privacy {
      font-size: 1.2rem; } }
  .p2-ftinfo_privacy a {
    color: white;
    text-decoration: underline; }

.p3-headline {
  position: relative;
  text-align: left;
  color: #424851; }
  .p3-headline.-center {
    text-align: center; }
  .p3-headline.-ttl01 {
    margin-top: -0.1em;
    margin-bottom: -0.1em;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 700;
    font-size: 3.8rem;
    line-height: 1;
    color: #062e67;
    letter-spacing: 0; }
    @media all and (min-width: 800px) {
      .p3-headline.-ttl01 {
        line-height: 1; } }
    @media all and (min-width: 375px) {
      .p3-headline.-ttl01 {
        font-size: 4.2rem;
        letter-spacing: 1px; } }
    @media all and (min-width: 500px) {
      .p3-headline.-ttl01 {
        font-size: 8.6vw; } }
    @media all and (min-width: 640px) {
      .p3-headline.-ttl01 {
        font-size: 5.6rem; } }
    @media all and (min-width: 800px) {
      .p3-headline.-ttl01 {
        font-size: 5.6rem;
        letter-spacing: 1px; } }
    .p3-headline.-ttl01.-nextsubheadline {
      margin-bottom: 0; }
      @media all and (min-width: 600px) {
        .p3-headline.-ttl01.-nextsubheadline {
          margin-bottom: 0.05em; } }
    .p3-headline.-ttl01.-wh {
      color: white; }
    .p3-headline.-ttl01.-company {
      text-align: center; }
      @media all and (min-width: 650px) {
        .p3-headline.-ttl01.-company {
          text-align: left; } }
    .p3-headline.-ttl01.-ajustx01 {
      transform: translateX(-0.1em); }
    .p3-headline.-ttl01.-informationsp {
      margin-top: -25px; }
      @media all and (min-width: 500px) {
        .p3-headline.-ttl01.-informationsp {
          margin-top: -5.4vw; } }
      @media all and (min-width: 640px) {
        .p3-headline.-ttl01.-informationsp {
          margin-top: -34px; } }
  .p3-headline.-ttl02 img {
    position: relative;
    width: auto;
    height: 74px;
    left: 10px; }
    @media all and (min-width: 375px) {
      .p3-headline.-ttl02 img {
        height: 100px; } }
    @media all and (min-width: 480px) {
      .p3-headline.-ttl02 img {
        height: 120px; } }
    @media all and (min-width: 800px) {
      .p3-headline.-ttl02 img {
        left: 10px;
        height: 146px; } }
    @media all and (min-width: 1120px) {
      .p3-headline.-ttl02 img {
        left: -26.666vw;
        height: 218px; } }
    @media all and (min-width: 1620px) {
      .p3-headline.-ttl02 img {
        left: -340px; } }
  .p3-headline.-ttl02.-pos1 {
    position: absolute;
    bottom: -50px; }
    @media all and (min-width: 800px) {
      .p3-headline.-ttl02.-pos1 {
        bottom: -60px; } }
  .p3-headline.-ttl02.-pos2 {
    position: absolute;
    bottom: -40px; }
    @media all and (min-width: 800px) {
      .p3-headline.-ttl02.-pos2 {
        bottom: -75px; } }
    @media all and (min-width: 1120px) {
      .p3-headline.-ttl02.-pos2 {
        bottom: -115px; } }
  .p3-headline.-ttl02.-pos3 {
    position: absolute;
    bottom: -80px; }
    @media all and (min-width: 800px) {
      .p3-headline.-ttl02.-pos3 {
        bottom: -100px; } }
    @media all and (min-width: 1120px) {
      .p3-headline.-ttl02.-pos3 {
        bottom: -200px; } }
    .p3-headline.-ttl02.-pos3 img {
      height: 72px; }
      @media all and (min-width: 800px) {
        .p3-headline.-ttl02.-pos3 img {
          height: 114px; } }
      @media all and (min-width: 1120px) {
        .p3-headline.-ttl02.-pos3 img {
          height: 142px; } }
  .p3-headline.-ttl02.-pos4 {
    position: absolute;
    z-index: -1;
    bottom: -70px; }
    @media all and (min-width: 800px) {
      .p3-headline.-ttl02.-pos4 {
        bottom: -130px; } }
    @media all and (min-width: 1120px) {
      .p3-headline.-ttl02.-pos4 {
        bottom: -140px; } }
    .p3-headline.-ttl02.-pos4 img {
      height: 74px; }
      @media all and (min-width: 800px) {
        .p3-headline.-ttl02.-pos4 img {
          height: 117px; } }
      @media all and (min-width: 1120px) {
        .p3-headline.-ttl02.-pos4 img {
          height: 146px; } }
  .p3-headline.-ttl02.-pos5 {
    position: absolute;
    top: -20px; }
    @media all and (min-width: 800px) {
      .p3-headline.-ttl02.-pos5 {
        top: -30px; } }
    @media all and (min-width: 1120px) {
      .p3-headline.-ttl02.-pos5 {
        top: auto;
        bottom: -40px; } }
  .p3-headline.-ttl03 img {
    width: auto;
    height: 142px; }
  .p3-headline.-ttl04 {
    font-family: dnp-shuei-mincho-pr6, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-weight: 500;
    font-size: 3.2rem;
    line-height: 1.6;
    color: #062e67;
    letter-spacing: 1px; }
    @media all and (min-width: 800px) {
      .p3-headline.-ttl04 {
        line-height: 1.6; } }
    @media all and (min-width: 480px) {
      .p3-headline.-ttl04 {
        font-size: 3.7rem; } }
    @media all and (min-width: 800px) {
      .p3-headline.-ttl04 {
        font-size: 4.6rem; } }
    @media all and (min-width: 1120px) {
      .p3-headline.-ttl04 {
        font-size: 5.6rem; } }
  .p3-headline.-ttl05 {
    font-family: dnp-shuei-mincho-pr6, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-weight: 500;
    font-size: 2.8rem;
    line-height: 1.4;
    color: #062e67;
    letter-spacing: 1px; }
    @media all and (min-width: 800px) {
      .p3-headline.-ttl05 {
        line-height: 1.6; } }
    @media all and (min-width: 800px) {
      .p3-headline.-ttl05 {
        font-size: 4.2rem; } }
    @media all and (min-width: 1560px) {
      .p3-headline.-ttl05 {
        font-size: 4.6rem; } }
  .p3-headline.-ttl06 {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    line-height: 1.6;
    letter-spacing: 1px; }
    @media all and (min-width: 800px) {
      .p3-headline.-ttl06 {
        font-size: 2.6rem; } }
    @media all and (min-width: 800px) {
      .p3-headline.-ttl06 {
        line-height: 1.7; } }
  .p3-headline.-ttl07 {
    font-family: dnp-shuei-mincho-pr6, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-weight: 500;
    font-size: 3.2rem;
    line-height: 1.6;
    color: #062e67;
    letter-spacing: 1px; }
    @media all and (min-width: 800px) {
      .p3-headline.-ttl07 {
        font-size: 4.6rem; } }
    @media all and (min-width: 800px) {
      .p3-headline.-ttl07 {
        line-height: 1.6; } }
  .p3-headline.-ttl08 {
    font-family: dnp-shuei-mincho-pr6, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-weight: 500;
    font-size: 2.3rem;
    line-height: 1.6;
    color: #062e67;
    letter-spacing: 1px; }
    @media all and (min-width: 800px) {
      .p3-headline.-ttl08 {
        font-size: 3rem; } }
    @media all and (min-width: 800px) {
      .p3-headline.-ttl08 {
        line-height: 1.8; } }
  .p3-headline.-ttl09 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 1;
    color: #062e67; }
    @media all and (min-width: 800px) {
      .p3-headline.-ttl09 {
        font-size: 2rem; } }
    @media all and (min-width: 800px) {
      .p3-headline.-ttl09 {
        line-height: 1; } }

.p3-box {
  position: relative;
  box-sizing: border-box; }
  .p3-box.-base {
    width: 100%; }
    @media all and (min-width: 1120px) {
      .p3-box.-base {
        width: 100%;
        padding-left: 26.666%;
        padding-right: 14.36%;
        margin-right: auto; } }
    .p3-box.-base .p3-box_inner {
      position: relative;
      box-sizing: border-box; }
      .p3-box.-base .p3-box_inner.-narrow {
        padding-left: 0; }
        @media all and (min-width: 800px) {
          .p3-box.-base .p3-box_inner.-narrow {
            padding-left: 4.9382716049383vw; } }
        @media all and (min-width: 1620px) {
          .p3-box.-base .p3-box_inner.-narrow {
            padding-left: 80px; } }
  .p3-box.-divide {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
    max-width: 1120px;
    margin-left: auto;
    margin-right: auto; }
    .p3-box.-divide .p3-box_half {
      position: relative;
      width: 100%; }
      @media all and (min-width: 800px) {
        .p3-box.-divide .p3-box_half {
          width: 50%; } }
    .p3-box.-divide .p3-box_inner {
      position: relative;
      box-sizing: border-box; }
      .p3-box.-divide .p3-box_inner.-narrow {
        padding-left: 0; }
        @media all and (min-width: 800px) {
          .p3-box.-divide .p3-box_inner.-narrow {
            padding-left: 4.9382716049383vw; } }
        @media all and (min-width: 1620px) {
          .p3-box.-divide .p3-box_inner.-narrow {
            padding-left: 80px; } }
  .p3-box.-wide {
    width: 100%; }
    @media all and (min-width: 1120px) {
      .p3-box.-wide {
        width: 100%;
        padding-left: 26.666%;
        padding-right: 7.95%;
        margin-right: auto; } }
    .p3-box.-wide .p3-box_inner {
      position: relative; }
      .p3-box.-wide .p3-box_inner.-narrow {
        padding-left: 0; }
        @media all and (min-width: 800px) {
          .p3-box.-wide .p3-box_inner.-narrow {
            padding-left: 4.9382716049383vw; } }
        @media all and (min-width: 1620px) {
          .p3-box.-wide .p3-box_inner.-narrow {
            padding-left: 80px; } }
  .p3-box.-full {
    width: 100%; }
    @media all and (min-width: 1120px) {
      .p3-box.-full {
        width: 100%;
        padding-left: 26.666%;
        padding-right: 2.822%;
        margin-right: auto; } }
    .p3-box.-full .p3-box_inner {
      position: relative; }
    .p3-box.-full.-company {
      padding: 40px 0 0 0;
      box-sizing: border-box; }
      @media all and (min-width: 650px) {
        .p3-box.-full.-company {
          padding: 50px 0 0 0; } }
      @media all and (min-width: 800px) {
        .p3-box.-full.-company {
          padding: 50px 0 0 0; } }
      @media all and (min-width: 1120px) {
        .p3-box.-full.-company {
          padding: 130px 0 0 26.666%; } }
      .p3-box.-full.-company .p3-box_bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 0;
        background-color: #0d346b;
        border-radius: 0;
        box-sizing: border-box; }
        @media all and (min-width: 650px) {
          .p3-box.-full.-company .p3-box_bg {
            height: 258px;
            border-radius: 0 0 0 100px; } }
        @media all and (min-width: 700px) {
          .p3-box.-full.-company .p3-box_bg {
            height: 270px; } }
        @media all and (min-width: 800px) {
          .p3-box.-full.-company .p3-box_bg {
            height: 300px; } }
        @media all and (min-width: 1000px) {
          .p3-box.-full.-company .p3-box_bg {
            height: 300px; } }
        @media all and (min-width: 1120px) {
          .p3-box.-full.-company .p3-box_bg {
            margin-left: 26.666%;
            height: 430px; } }
      .p3-box.-full.-company .p3-box_inner {
        padding-left: 5vw;
        padding-right: 5vw; }
        @media all and (min-width: 480px) {
          .p3-box.-full.-company .p3-box_inner {
            padding-right: 0; } }
        @media all and (min-width: 800px) {
          .p3-box.-full.-company .p3-box_inner {
            padding-left: 4.9382716049383vw; } }
        @media all and (min-width: 1620px) {
          .p3-box.-full.-company .p3-box_inner {
            padding-left: 80px; } }
    .p3-box.-full.-companylead {
      padding: 0;
      box-sizing: border-box; }
      @media all and (min-width: 1120px) {
        .p3-box.-full.-companylead {
          padding-left: 26.666%; } }
      .p3-box.-full.-companylead .p3-box_inner {
        padding-left: 5vw;
        padding-right: 5vw; }
        @media all and (min-width: 480px) {
          .p3-box.-full.-companylead .p3-box_inner {
            padding-right: 0; } }
        @media all and (min-width: 800px) {
          .p3-box.-full.-companylead .p3-box_inner {
            padding-left: 4.9382716049383vw; } }
        @media all and (min-width: 1620px) {
          .p3-box.-full.-companylead .p3-box_inner {
            padding-left: 80px; } }
    .p3-box.-full.-greetings {
      padding: 100px 0 0 0;
      box-sizing: border-box; }
      @media all and (min-width: 650px) {
        .p3-box.-full.-greetings {
          padding: 110px 0 0 0; } }
      @media all and (min-width: 700px) {
        .p3-box.-full.-greetings {
          padding: 110px 0 0 0; } }
      @media all and (min-width: 800px) {
        .p3-box.-full.-greetings {
          padding: 250px 0 0 0; } }
      @media all and (min-width: 1120px) {
        .p3-box.-full.-greetings {
          padding: 250px 0 0 26.666%; } }
      .p3-box.-full.-greetings .p3-box_bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 0;
        background-color: #0d346b;
        border-radius: 0;
        box-sizing: border-box; }
        @media all and (min-width: 650px) {
          .p3-box.-full.-greetings .p3-box_bg {
            height: 320px;
            border-radius: 0 0 0 100px; } }
        @media all and (min-width: 700px) {
          .p3-box.-full.-greetings .p3-box_bg {
            height: 330px; } }
        @media all and (min-width: 800px) {
          .p3-box.-full.-greetings .p3-box_bg {
            height: 500px; } }
        @media all and (min-width: 1000px) {
          .p3-box.-full.-greetings .p3-box_bg {
            height: 500px; } }
        @media all and (min-width: 1120px) {
          .p3-box.-full.-greetings .p3-box_bg {
            margin-left: 26.666%;
            height: 550px; } }
      .p3-box.-full.-greetings .p3-box_inner {
        padding-left: 5vw;
        padding-right: 5vw; }
        @media all and (min-width: 480px) {
          .p3-box.-full.-greetings .p3-box_inner {
            padding-right: 0; } }
        @media all and (min-width: 800px) {
          .p3-box.-full.-greetings .p3-box_inner {
            padding-left: 4.9382716049383vw; } }
        @media all and (min-width: 1620px) {
          .p3-box.-full.-greetings .p3-box_inner {
            padding-left: 80px; } }
  .p3-box.-wideimg {
    transform: translateX(-5vw);
    width: 100vw; }
    @media all and (min-width: 800px) {
      .p3-box.-wideimg {
        transform: translateX(0);
        width: calc(100% + 40px); } }
    @media all and (min-width: 1120px) {
      .p3-box.-wideimg {
        width: 100%;
        padding-left: 26.666%;
        padding-right: 0;
        margin-right: auto; } }
    .p3-box.-wideimg .p3-box_inner {
      position: relative; }

.p3-text {
  position: relative;
  /*
  &.-lead01 {
    position: relative;
    @include font-montserrat("l");
    @include fontSize(3.2, 2.4);
    color: color-black01(1);
    text-align: justify;
    line-height: 1.65;

    @include breakpoint-pc {
    }
  }
*/
  /*
  &.-t01 {
    text-align: justify;
    line-height: 2;

    @include breakpoint-pc {
      line-height: 2.4;
    }
    
    &.-center {
      text-align: center;
    }
  }

  &.-t02 {
    @include font-montserrat("r");
    @include fontSize(1.3, 1.3);
    text-align: justify;
    line-height: 1.7;
    
    &.-center {
      text-align: center;
    }
  }

  &.-t03 {
    @include font-montserrat("m");
    @include fontSize(2.4, 1.8);
    color: color-black01(1);
    line-height: 1.7;
    @include breakpoint-pc {

    }
    
    &.-center {
      text-align: center;
    }
  }

  &.-t04 {
    @include font-montserrat("m");
    @include fontSize(1.5, 1.3);
    color: color-black01(1);
    text-align: justify;
    line-height: 2;

    @include breakpoint-pc {
      line-height: 2.4;
    }
    
    &.-center {
      text-align: center;
    }
    
    &.-slim {
      line-height: 1.5;

      @include breakpoint-pc {
        line-height: 1.8;
      }
    }
  }

  &.-t05 {
    @include fontSize(1.9, 1.7);
  }

  &.-t06 {
    @include font-montserrat("m");
    font-size: 1rem;
    line-height: 1;
  }

  &.-t07 {
    text-align: justify;
    line-height: 1.7;

    @include breakpoint-pc {
      line-height: 1.8;
    }
    
    &.-center {
      text-align: center;
    }
  }
*/
  /*
  &.-list01 {
    padding-left: 5px;
    box-sizing: border-box;
    text-align: justify;

    & .-item {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include align-items-stretch;
      line-height: 1.7;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
      
      &:before {
        display: block;
        content: "◎";
        padding-right: 5px;
        box-sizing: border-box;
      }
    }
  }

  &.-list02 {
    margin-left: 20px;
    list-style-type: disc;
    box-sizing: border-box;
    @include font-montserrat("m");
    @include fontSize(1.6, 1.5);
    color: color-black01(1);
    text-align: justify;
    line-height: 2.3;

    @include breakpoint-pc {
    }

    & .-item {
      list-style-type: inherit;
    }
  }

  &.-list03 {
    box-sizing: border-box;
    text-align: justify;

    & .-item {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include align-items-stretch;
      line-height: 1.7;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
      
      &:before {
        display: block;
        content: "※";
        padding-right: 5px;
        box-sizing: border-box;
      }
    }
  }

  &.-list04 {
    margin-left: 20px;
    list-style-type: disc;
    box-sizing: border-box;
    @include font-montserrat("r");
    @include fontSize(1.5, 1.4);
    color: color-black01(1);
    text-align: justify;
    line-height: 2.3;

    @include breakpoint-pc {
    }

    & .-item {
      list-style-type: inherit;
    }
  }
*/
  /*
  &.-t04 {
    font-size: 2rem;
    color: color-black01(1);
    line-height: 1.92;
    @include breakpoint-pc {
      font-size: 2.8rem;
    }
  }
  &.-t05 {
    font-size: 1.4rem;
    @include breakpoint-pc {
      font-size: 1.6rem;
    }
  }
  &.-t06 {
    font-size: 1.5rem;
    font-weight: 700;
    color: color-black01(1);
    line-height: 1.8;
    @include breakpoint-mainboxhalf {
      font-size: 1.8rem;
      line-height: 2;
    }
    @include breakpoint-pc {
      font-size: 2.2rem;
    }
  }
  &.-t07 {
    line-height: 1.5;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &.-t08 {
    font-size: 80%;
    line-height: 1.7;
  }
  &.-t09 {
    padding-bottom: 20px;
    border-bottom: 2px solid color-effect02(1);
  }
  &.-t10 {
    padding-bottom: 20px;
  }

  &.-ttl01 {
    @include font-montserrat("m");
    font-size: 4.4vw;
    color: color-black01(1);
    text-align: left;
    line-height: 1;
    @media (min-width:400px) {
      font-size: 1.85rem;
    }
    @include breakpoint-pc {
      font-size: 2.6rem;
    }
    & .-ttlInner01 {
      display: inline-block;
      position: relative;
      z-index: 5;
      width: 100%;
      max-width: 645px;
      padding: 15px 20px 25px 30px;
      background-image: url("../images/recruit/merit_border.png");
      background-position: left bottom;
      background-repeat: no-repeat;
      background-size: auto 100%;
      line-height: 1.4;
      @include breakpoint-mainboxhalf {
        padding: 20px 20px 30px 35px;
      }
      @include breakpoint-mainboxhalf {
        padding: 25px 160px 35px 40px;
      }
      
      & .-yg {
        color: color-black01(1);
      }
    }
  }
  &.-ttl02 {
    font-size: 2rem;
    color: color-effect03(1);
    line-height: 1.35;
    @include font-montserrat(b);
    @media (min-width:520px) {
      text-align: center;
    }
    @include breakpoint-mainboxhalf {
      font-size: 2.4rem;
    }
    @media (min-width:820px) {
      line-height: 1.6;
      font-size: 2.8rem;
    }
    & .br {
      @media (max-width:980px) {
        display: none;
      }
    }
  }

  &.-ttl03 {
    font-size: 90%;
    line-height: 1.3;
    color: color-black01(1);
    @include font-montserrat(b);
  }
*/ }
  .p3-text.-shadow.-bk {
    -moz-text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.58), 0px 0px 16px rgba(0, 0, 0, 0.36);
    -webkit-text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.58), 0px 0px 16px rgba(0, 0, 0, 0.36);
    -ms-text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.58), 0px 0px 16px rgba(0, 0, 0, 0.36);
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.58), 0px 0px 16px rgba(0, 0, 0, 0.36); }
  .p3-text.-shadow.-wh {
    -moz-text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.58), 0px 0px 3px rgba(255, 255, 255, 0.58), 0px 0px 16px white;
    -webkit-text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.58), 0px 0px 3px rgba(255, 255, 255, 0.58), 0px 0px 16px white;
    -ms-text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.58), 0px 0px 3px rgba(255, 255, 255, 0.58), 0px 0px 16px white;
    text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.58), 0px 0px 3px rgba(255, 255, 255, 0.58), 0px 0px 16px white; }
  .p3-text.-wh {
    color: white !important; }
  .p3-text.-black01 {
    color: #424851 !important; }
  .p3-text.-black02 {
    color: #2d2d2d !important; }
  .p3-text.-gray04 {
    color: #686868 !important; }
  .p3-text.-gray05 {
    color: #f2f2f5 !important; }
  .p3-text.-blue01 {
    color: #062e67 !important; }
  .p3-text.-blue02 {
    color: #0d346b !important; }
  .p3-text.-blue03 {
    color: #0953b7 !important; }
  .p3-text.-blue04 {
    color: #70829b !important; }
  .p3-text.-green01 {
    color: #06d3cc !important; }
  .p3-text.-mixed {
    writing-mode: vertical-rl;
    text-align: left;
    text-orientation: upright;
    text-orientation: mixed; }
    .p3-text.-mixed .combine {
      text-combine-upright: all; }
  .p3-text.-upright {
    writing-mode: vertical-rl;
    text-align: left;
    text-orientation: upright;
    text-orientation: upright; }
    .p3-text.-upright .combine {
      text-combine-upright: all; }
  .p3-text.-sideways {
    writing-mode: vertical-rl;
    text-align: left;
    text-orientation: upright;
    text-orientation: sideways; }
    .p3-text.-sideways .combine {
      text-combine-upright: all; }
  .p3-text.-marquee01 {
    position: absolute;
    top: calc( 50% - 8px);
    left: 0;
    transform: translateY(-50%);
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 100;
    font-size: 7rem;
    line-height: 1;
    letter-spacing: 0.6rem;
    color: #b6b6b6;
    mix-blend-mode: luminosity; }
    @media all and (min-width: 800px) {
      .p3-text.-marquee01 {
        top: 50%;
        font-size: 14rem; } }
    @media all and (min-width: 1120px) {
      .p3-text.-marquee01 {
        top: 50%;
        font-size: 20rem; } }
  .p3-text.-marquee02 {
    position: absolute;
    top: -50px;
    left: 0;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 100;
    font-size: 7rem;
    line-height: 1;
    letter-spacing: 0.8rem;
    color: #06d3cc;
    mix-blend-mode: multiply;
    opacity: 0.55; }
    @media all and (min-width: 800px) {
      .p3-text.-marquee02 {
        top: -85px;
        font-size: 12rem; } }
    @media all and (min-width: 1120px) {
      .p3-text.-marquee02 {
        top: -94px;
        font-size: 13rem; } }
  .p3-text.-t01 {
    line-height: 2;
    text-align: justify;
    letter-spacing: 1px; }
    @media all and (min-width: 800px) {
      .p3-text.-t01 {
        line-height: 2.5; } }
    .p3-text.-t01.-center {
      text-align: center; }
    .p3-text.-t01.-lh16 {
      line-height: 1.5; }
      @media all and (min-width: 800px) {
        .p3-text.-t01.-lh16 {
          line-height: 1.6; } }
  .p3-text.-t02 {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1;
    text-align: justify;
    letter-spacing: 1px; }
    @media all and (min-width: 800px) {
      .p3-text.-t02 {
        font-size: 1.4rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t02 {
        line-height: 1; } }
    .p3-text.-t02.-center {
      text-align: center; }
    .p3-text.-t02.-lh17 {
      line-height: 1.7; }
      @media all and (min-width: 800px) {
        .p3-text.-t02.-lh17 {
          line-height: 1.7; } }
  .p3-text.-t03 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1;
    letter-spacing: 1px; }
    @media all and (min-width: 800px) {
      .p3-text.-t03 {
        font-size: 1.3rem; } }
    @media all and (min-width: 1120px) {
      .p3-text.-t03 {
        font-size: 1.5rem; } }
  .p3-text.-t04 {
    font-family: dnp-shuei-mincho-pr6, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.4;
    letter-spacing: 1px; }
    @media all and (min-width: 800px) {
      .p3-text.-t04 {
        line-height: 1.5; } }
    @media all and (min-width: 800px) {
      .p3-text.-t04 {
        font-size: 1.7rem; } }
    @media all and (min-width: 1120px) {
      .p3-text.-t04 {
        font-size: 1.8rem; } }
    .p3-text.-t04.-justify {
      text-align: justify; }
  .p3-text.-t05 {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.4; }
    @media all and (min-width: 800px) {
      .p3-text.-t05 {
        font-size: 1.2rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t05 {
        line-height: 1.5; } }
  .p3-text.-t06 {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1; }
    @media all and (min-width: 1120px) {
      .p3-text.-t06 {
        font-size: 1.3rem; } }
  .p3-text.-t07 {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.6; }
    @media all and (min-width: 800px) {
      .p3-text.-t07 {
        line-height: 1.6; } }
    @media all and (min-width: 1120px) {
      .p3-text.-t07 {
        font-size: 1.5rem; } }
  .p3-text.-t08 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1; }
    @media all and (min-width: 800px) {
      .p3-text.-t08 {
        font-size: 1.8rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t08 {
        line-height: 1; } }
  .p3-text.-t09 {
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2; }
    @media all and (min-width: 800px) {
      .p3-text.-t09 {
        font-size: 1.8rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t09 {
        line-height: 2.2; } }
  .p3-text.-t10 {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.1;
    letter-spacing: 1px; }
    @media all and (min-width: 800px) {
      .p3-text.-t10 {
        font-size: 2rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t10 {
        line-height: 2.3; } }
  .p3-text.-t11 {
    font-family: din-1451-lt-pro-engschrift, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 3.6rem;
    line-height: 1; }
    @media all and (min-width: 800px) {
      .p3-text.-t11 {
        font-size: 4rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t11 {
        line-height: 1; } }
  .p3-text.-t12 {
    font-family: din-1451-lt-pro-engschrift, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 3.6rem;
    line-height: 1; }
    @media all and (min-width: 800px) {
      .p3-text.-t12 {
        font-size: 4.2rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t12 {
        line-height: 1; } }
  .p3-text.-t13 {
    font-family: dnp-shuei-mincho-pr6, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1; }
    @media all and (min-width: 800px) {
      .p3-text.-t13 {
        font-size: 1.5rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t13 {
        line-height: 1; } }
  .p3-text.-t14 {
    font-family: din-1451-lt-pro-engschrift, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 1; }
    @media all and (min-width: 800px) {
      .p3-text.-t14 {
        font-size: 2rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t14 {
        line-height: 1; } }
  .p3-text.-t15 {
    font-family: din-1451-lt-pro-engschrift, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1; }
    @media all and (min-width: 800px) {
      .p3-text.-t15 {
        font-size: 1.4rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t15 {
        line-height: 1; } }
  .p3-text.-t16 {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.8; }
    @media all and (min-width: 800px) {
      .p3-text.-t16 {
        font-size: 1.8rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t16 {
        line-height: 1.8; } }
  .p3-text.-t17 {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.1rem;
    line-height: 1.4; }
    @media all and (min-width: 800px) {
      .p3-text.-t17 {
        font-size: 1.3rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t17 {
        line-height: 1.5; } }
  .p3-text.-t18 {
    font-family: 'linlibrtine_dr';
    font-weight: normal;
    font-style: normal;
    font-size: 2.8rem;
    line-height: 0.83; }
    @media all and (min-width: 375px) {
      .p3-text.-t18 {
        font-size: 3.2rem; } }
    @media all and (min-width: 480px) {
      .p3-text.-t18 {
        font-size: 9.5vw; } }
    @media all and (min-width: 600px) {
      .p3-text.-t18 {
        font-size: 5.6rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t18 {
        font-size: 7.4vw;
        line-height: 0.88; } }
    @media all and (min-width: 1120px) {
      .p3-text.-t18 {
        font-size: 9.2rem;
        line-height: 0.9; } }
  .p3-text.-t19 {
    font-family: din-1451-lt-pro-engschrift, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 8rem;
    line-height: 1;
    letter-spacing: 1px; }
    @media all and (min-width: 800px) {
      .p3-text.-t19 {
        line-height: 1; } }
    @media all and (min-width: 480px) {
      .p3-text.-t19 {
        font-size: 9rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t19 {
        font-size: 13vw; } }
    @media all and (min-width: 1120px) {
      .p3-text.-t19 {
        font-size: 16rem; } }
  .p3-text.-t20 {
    font-family: dnp-shuei-mincho-pr6, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1;
    letter-spacing: 1px; }
    @media all and (min-width: 800px) {
      .p3-text.-t20 {
        line-height: 1; } }
    @media all and (min-width: 480px) {
      .p3-text.-t20 {
        font-size: 1.4rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t20 {
        font-size: 1.6rem; } }
  .p3-text.-t21 {
    font-family: dnp-shuei-mincho-pr6, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 1.375;
    letter-spacing: 0.03em; }
    @media all and (min-width: 800px) {
      .p3-text.-t21 {
        line-height: 1.375; } }
    @media all and (min-width: 375px) {
      .p3-text.-t21 {
        font-size: 2.6rem; } }
    @media all and (min-width: 480px) {
      .p3-text.-t21 {
        font-size: 6.7vw; } }
    @media all and (min-width: 600px) {
      .p3-text.-t21 {
        font-size: 3.9rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t21 {
        font-size: 5vw; } }
    @media all and (min-width: 1120px) {
      .p3-text.-t21 {
        font-size: 5.6rem; } }
  .p3-text.-t22 {
    font-family: dnp-shuei-mincho-pr6, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-weight: 500;
    font-size: 2.3rem;
    line-height: 1.26;
    letter-spacing: 0.03em; }
    @media all and (min-width: 800px) {
      .p3-text.-t22 {
        line-height: 1.26; } }
    @media all and (min-width: 375px) {
      .p3-text.-t22 {
        font-size: 2.5rem; } }
    @media all and (min-width: 480px) {
      .p3-text.-t22 {
        font-size: 6vw; } }
    @media all and (min-width: 600px) {
      .p3-text.-t22 {
        font-size: 3.8rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t22 {
        font-size: 4.6vw; } }
    @media all and (min-width: 1120px) {
      .p3-text.-t22 {
        font-size: 5rem; } }
  .p3-text.-t23 {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1; }
    @media all and (min-width: 800px) {
      .p3-text.-t23 {
        font-size: 1.1rem; } }
  .p3-text.-t24 {
    font-family: 'linlibrtine_dr';
    font-weight: normal;
    font-style: normal;
    font-size: 1.2rem;
    line-height: 0.83; }
    @media all and (min-width: 480px) {
      .p3-text.-t24 {
        font-size: 1.4rem; } }
    @media all and (min-width: 1120px) {
      .p3-text.-t24 {
        font-size: 1.6rem;
        line-height: 0.9; } }
  .p3-text.-t25 {
    font-family: dnp-shuei-mincho-pr6, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-weight: 500;
    font-size: 2.6rem;
    line-height: 1.5;
    letter-spacing: 1px; }
    @media all and (min-width: 800px) {
      .p3-text.-t25 {
        line-height: 1.5; } }
    @media all and (min-width: 800px) {
      .p3-text.-t25 {
        font-size: 2.6rem; } }
    @media all and (min-width: 1120px) {
      .p3-text.-t25 {
        font-size: 2.8rem; } }
    .p3-text.-t25.-justify {
      text-align: justify; }
  .p3-text.-t26 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 600;
    font-size: 2.2rem;
    line-height: 1;
    letter-spacing: 0.04em; }
    @media all and (min-width: 800px) {
      .p3-text.-t26 {
        font-size: 2.4rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t26 {
        line-height: 1; } }
  .p3-text.-t27 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1;
    letter-spacing: 0.04em; }
    @media all and (min-width: 800px) {
      .p3-text.-t27 {
        font-size: 1.2rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t27 {
        line-height: 1; } }
  .p3-text.-t28 {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1.6;
    letter-spacing: 0.04em; }
    @media all and (min-width: 800px) {
      .p3-text.-t28 {
        font-size: 1.8rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t28 {
        line-height: 1.6; } }
  .p3-text.-t29 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1;
    letter-spacing: 0.04em; }
    @media all and (min-width: 800px) {
      .p3-text.-t29 {
        font-size: 1.3rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t29 {
        line-height: 1; } }
  .p3-text.-list01 {
    margin-left: 20px;
    list-style-type: disc;
    box-sizing: border-box;
    line-height: 2;
    text-align: justify;
    letter-spacing: 1px; }
    @media all and (min-width: 800px) {
      .p3-text.-list01 {
        line-height: 2.5; } }
    .p3-text.-list01 .-item {
      list-style-type: inherit; }
  .p3-text.-link01 {
    color: #424851;
    text-decoration: underline; }

.p3-img.-i01 {
  position: relative;
  top: 0;
  left: -5vw;
  width: 100vw;
  height: 250px; }
  @media all and (min-width: 375px) {
    .p3-img.-i01 {
      height: 66.6666666666667vw; } }
  @media all and (min-width: 800px) {
    .p3-img.-i01 {
      position: absolute;
      width: 50vw;
      height: 100%;
      left: auto;
      right: 0; } }
  @media all and (min-width: 1620px) {
    .p3-img.-i01 {
      width: 820px; } }
  .p3-img.-i01 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center 30%; }
    @media all and (min-width: 800px) {
      .p3-img.-i01 img {
        object-position: right top; } }

.p3-img.-i02 {
  position: relative;
  text-align: center; }
  .p3-img.-i02 img {
    width: 224px;
    height: auto; }
    @media all and (min-width: 800px) {
      .p3-img.-i02 img {
        width: 281px; } }

.p3-img.-i03, .p3-img.-i04 {
  position: relative;
  text-align: center; }
  .p3-img.-i03 img, .p3-img.-i04 img {
    width: 100%;
    height: auto; }

.p4-postarea h4 {
  font-size: 2rem;
  font-weight: bold; }

.p4-postarea h5 {
  font-size: 1.7rem;
  font-weight: bold; }

.p4-postarea img {
  width: 100%;
  height: inherit; }
  @media all and (min-width: 650px) and (max-width: 799px) {
    .p4-postarea img.vertically-S {
      width: inherit; } }
  @media all and (min-width: 800px) {
    .p4-postarea img.vertically-S {
      width: inherit; } }
  @media all and (min-width: 650px) and (max-width: 799px) {
    .p4-postarea img.horizontally-S {
      width: inherit; } }
  @media all and (min-width: 800px) {
    .p4-postarea img.horizontally-S {
      width: inherit; } }

.p4-pager {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
  padding: 35px 0 5px;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-weight: 500;
  font-size: 2rem;
  color: #062e67;
  text-align: center;
  line-height: 1;
  /* pager非表示時(1ページのみの場合) */ }
  @media all and (min-width: 800px) {
    .p4-pager {
      font-size: 2.4rem; } }
  @media all and (min-width: 800px) {
    .p4-pager {
      padding: 70px 0 10px; } }
  @media all and (min-width: 1120px) {
    .p4-pager {
      -webkit-justify-content: flex-start;
      justify-content: flex-start; } }
  .p4-pager .page-numbers {
    display: block;
    width: 18px;
    margin: 0 5px;
    padding: 5px 0;
    border-bottom: 3px solid transparent;
    box-sizing: border-box;
    color: #062e67;
    /*
    &.first {
      background-image: url("../images/common/icon_tri_gr.png");
      transform: rotate(180deg); //lastと同じ画像を左右反転して使いたい場合は、この行を有効にする
    }
    &.last {
      background-image: url("../images/common/icon_tri_gr.png");
    }
*/ }
    @media all and (min-width: 480px) {
      .p4-pager .page-numbers {
        width: 21px; } }
    .p4-pager .page-numbers.current {
      color: #062e67;
      border-bottom-color: #062e67; }
    .p4-pager .page-numbers.prev, .p4-pager .page-numbers.next {
      display: block;
      width: 22px;
      height: 17px;
      margin: 0 5px;
      padding: 0;
      box-sizing: border-box;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain; }
      @media all and (min-width: 800px) {
        .p4-pager .page-numbers.prev, .p4-pager .page-numbers.next {
          width: 26px; } }
    .p4-pager .page-numbers.prev {
      background-image: url("../images/common/arw_prev.png"); }
      @media all and (min-width: 1120px) {
        .p4-pager .page-numbers.prev {
          margin-left: 0; } }
    .p4-pager .page-numbers.next {
      background-image: url("../images/common/arw_next.png"); }
      @media all and (min-width: 1120px) {
        .p4-pager .page-numbers.next {
          margin-right: 0; } }
    .p4-pager .page-numbers.-dummy {
      display: block; }
      @media all and (min-width: 1120px) {
        .p4-pager .page-numbers.-dummy {
          display: none; } }
  .p4-pager-nodisp {
    height: 15px; }
    @media all and (min-width: 800px) {
      .p4-pager-nodisp {
        height: 30px; } }

.p5-form {
  width: 100%; }
  .p5-form_input {
    width: 100%;
    margin-bottom: 10px;
    padding: 8px 14px;
    border: 1px solid transparent;
    border-bottom-color: #062e67;
    box-sizing: border-box;
    color: #424851; }
    @media all and (min-width: 560px) {
      .p5-form_input {
        padding: 8px 15px;
        margin-bottom: 15px; } }
    .p5-form_input:focus {
      border-color: rgba(6, 46, 103, 0.5); }
    .p5-form_input.-txt {
      width: 100%; }
    .p5-form_input.-txtarea {
      width: 100%;
      height: 300px;
      border-color: #062e67; }
    .p5-form_input.-check {
      width: 18px;
      height: 18px;
      margin-left: 3px;
      vertical-align: 3px; }
  .p5-form_select {
    position: relative;
    border: 1px solid #062e67; }
    .p5-form_select:before {
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      content: "";
      width: 50px;
      height: 100%;
      border-left: 1px solid #062e67;
      pointer-events: none; }
    .p5-form_select:after {
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 18px;
      transform: translateY(-50%);
      content: "";
      width: 14px;
      height: 7px;
      pointer-events: none;
      background-image: url("../images/common/tri_down_blue.png");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain; }
    .p5-form_select select {
      width: 100%;
      padding: 14px;
      box-sizing: border-box;
      color: #424851;
      line-height: 1 !important; }
  .p5-form_check .mwform-checkbox-field label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .p5-form_check .mwform-checkbox-field label .mwform-checkbox-field-text {
      font-family: 'Noto Sans JP', sans-serif;
      font-style: normal;
      font-weight: 500; }
  .p5-form .-required {
    color: red; }
  .p5-form .-link {
    margin-bottom: 15px; }
    .p5-form .-link a {
      width: 100%;
      max-width: 270px;
      display: block;
      line-height: 1;
      text-align: center;
      font-size: 1.5rem;
      font-weight: 500;
      padding: 8px 5%;
      color: white;
      background: #424851;
      border-radius: 100px;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 500; }
      @media all and (min-width: 560px) {
        .p5-form .-link a {
          width: 310px;
          max-width: 310px;
          padding: 10px 20px;
          font-size: 1.7rem; } }
  .p5-form_btn {
    margin-left: 20px; }
    @media all and (min-width: 1120px) {
      .p5-form_btn {
        margin-left: 70px; } }
    .p5-form_btn.-back {
      margin-top: 15px;
      margin-left: -5px; }
      @media all and (min-width: 800px) {
        .p5-form_btn.-back {
          margin-top: 25px;
          margin-left: 20px; } }
      @media all and (min-width: 1120px) {
        .p5-form_btn.-back {
          margin-left: 70px; } }
  .p5-form_btns {
    padding-top: 40px;
    padding-bottom: 60px;
    box-sizing: border-box; }
    @media all and (min-width: 480px) {
      .p5-form_btns {
        padding-top: 50px;
        padding-bottom: 80px; } }
    @media all and (min-width: 800px) {
      .p5-form_btns {
        padding-bottom: 100px; } }
    @media all and (min-width: 1120px) {
      .p5-form_btns {
        padding-top: 30px;
        padding-bottom: 180px; } }

.mwform-checkbox-field {
  display: block;
  margin-bottom: 5px; }
  .mwform-checkbox-fieldtext {
    vertical-align: 5px;
    padding-left: 5px; }

.mwform-checkbox-field {
  line-height: 1; }

.mw_wp_form .error {
  display: block;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem !important;
  color: red !important; }
  @media all and (min-width: 800px) {
    .mw_wp_form .error {
      font-size: 1.4rem !important; } }

.mw_wp_form_input .p5-form_btn.-back, .mw_wp_form_input .p5-form_btn.-submit, .mw_wp_form_input .p5-form_btn.-top {
  display: none; }

.mw_wp_form_input .p5-form_lead.-confirm {
  display: none; }

.mw_wp_form_input .p5-form_thx {
  display: none; }

.mw_wp_form_confirm .p5-form_lastinput {
  margin-bottom: 0 !important; }

.mw_wp_form_confirm .p5-form_btn.-confirm, .mw_wp_form_confirm .p5-form_btn.-top {
  display: none; }

.mw_wp_form_confirm .error {
  display: none; }

.mw_wp_form_confirm .p5-form_ttl.u-mb10_half {
  margin-bottom: 0 !important; }
  @media all and (min-width: 800px) {
    .mw_wp_form_confirm .p5-form_ttl.u-mb10_half {
      margin-bottom: 0 !important; } }

.mw_wp_form_confirm .p5-form_inputbox, .mw_wp_form_confirm .p5-form_select {
  padding: 6px 0;
  box-sizing: border-box;
  font-size: 1.6rem;
  line-height: 1.8; }
  @media all and (min-width: 800px) {
    .mw_wp_form_confirm .p5-form_inputbox, .mw_wp_form_confirm .p5-form_select {
      font-size: 1.8rem; } }
  @media all and (min-width: 800px) {
    .mw_wp_form_confirm .p5-form_inputbox, .mw_wp_form_confirm .p5-form_select {
      line-height: 1.8; } }

.mw_wp_form_confirm .p3-text.-ttl03 {
  padding-left: 1vw;
  padding-right: 1vw; }
  @media all and (min-width: 560px) {
    .mw_wp_form_confirm .p3-text.-ttl03 {
      padding-left: 1.5vw;
      padding-right: 1.5vw; } }
  @media (min-width: 900px) {
    .mw_wp_form_confirm .p3-text.-ttl03 {
      padding-left: 20px;
      padding-right: 20px; } }

.mw_wp_form_confirm .p5-form_select {
  border-style: none; }
  .mw_wp_form_confirm .p5-form_select:before, .mw_wp_form_confirm .p5-form_select:after {
    display: none; }

.mw_wp_form_confirm .p5-form_privacy {
  display: none; }

.mw_wp_form_confirm .p5-form_lead.-input {
  display: none; }

.mw_wp_form_confirm .p5-form_btns {
  padding-bottom: 30px; }
  @media all and (min-width: 480px) {
    .mw_wp_form_confirm .p5-form_btns {
      padding-bottom: 50px; } }
  @media all and (min-width: 800px) {
    .mw_wp_form_confirm .p5-form_btns {
      padding-bottom: 60px; } }
  @media all and (min-width: 1120px) {
    .mw_wp_form_confirm .p5-form_btns {
      padding-bottom: 110px; } }

.mw_wp_form_complete .p5-form_btn.-back, .mw_wp_form_complete .p5-form_btn.-confirm, .mw_wp_form_complete .p5-form_btn.xzssxsaazaa-submit {
  display: none; }

.mw_wp_form_complete .p5-form_lead.-input, .mw_wp_form_complete .p5-form_lead.-confirm {
  display: none; }

.u-m0 {
  margin: 0px !important; }

.u-mtb0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important; }

.u-mlr0 {
  margin-left: 0px !important;
  margin-right: 0px !important; }

.u-mt0 {
  margin-top: 0px !important; }

.u-mb0 {
  margin-bottom: 0px !important; }

.u-ml0 {
  margin-left: 0px !important; }

.u-mr0 {
  margin-right: 0px !important; }

.u-p0 {
  padding: 0px !important; }

.u-pt0 {
  padding-top: 0px !important; }

.u-pb0 {
  padding-bottom: 0px !important; }

.u-pl0 {
  padding-left: 0px !important; }

.u-pr0 {
  padding-right: 0px !important; }

.u-m5 {
  margin: 5px !important; }

.u-mtb5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.u-mlr5 {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.u-mt5 {
  margin-top: 5px !important; }

.u-mb5 {
  margin-bottom: 5px !important; }

.u-ml5 {
  margin-left: 5px !important; }

.u-mr5 {
  margin-right: 5px !important; }

.u-p5 {
  padding: 5px !important; }

.u-pt5 {
  padding-top: 5px !important; }

.u-pb5 {
  padding-bottom: 5px !important; }

.u-pl5 {
  padding-left: 5px !important; }

.u-pr5 {
  padding-right: 5px !important; }

.u-m10 {
  margin: 10px !important; }

.u-mtb10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important; }

.u-mlr10 {
  margin-left: 10px !important;
  margin-right: 10px !important; }

.u-mt10 {
  margin-top: 10px !important; }

.u-mb10 {
  margin-bottom: 10px !important; }

.u-ml10 {
  margin-left: 10px !important; }

.u-mr10 {
  margin-right: 10px !important; }

.u-p10 {
  padding: 10px !important; }

.u-pt10 {
  padding-top: 10px !important; }

.u-pb10 {
  padding-bottom: 10px !important; }

.u-pl10 {
  padding-left: 10px !important; }

.u-pr10 {
  padding-right: 10px !important; }

.u-m15 {
  margin: 15px !important; }

.u-mtb15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.u-mlr15 {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.u-mt15 {
  margin-top: 15px !important; }

.u-mb15 {
  margin-bottom: 15px !important; }

.u-ml15 {
  margin-left: 15px !important; }

.u-mr15 {
  margin-right: 15px !important; }

.u-p15 {
  padding: 15px !important; }

.u-pt15 {
  padding-top: 15px !important; }

.u-pb15 {
  padding-bottom: 15px !important; }

.u-pl15 {
  padding-left: 15px !important; }

.u-pr15 {
  padding-right: 15px !important; }

.u-m20 {
  margin: 20px !important; }

.u-mtb20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important; }

.u-mlr20 {
  margin-left: 20px !important;
  margin-right: 20px !important; }

.u-mt20 {
  margin-top: 20px !important; }

.u-mb20 {
  margin-bottom: 20px !important; }

.u-ml20 {
  margin-left: 20px !important; }

.u-mr20 {
  margin-right: 20px !important; }

.u-p20 {
  padding: 20px !important; }

.u-pt20 {
  padding-top: 20px !important; }

.u-pb20 {
  padding-bottom: 20px !important; }

.u-pl20 {
  padding-left: 20px !important; }

.u-pr20 {
  padding-right: 20px !important; }

.u-m25 {
  margin: 25px !important; }

.u-mtb25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important; }

.u-mlr25 {
  margin-left: 25px !important;
  margin-right: 25px !important; }

.u-mt25 {
  margin-top: 25px !important; }

.u-mb25 {
  margin-bottom: 25px !important; }

.u-ml25 {
  margin-left: 25px !important; }

.u-mr25 {
  margin-right: 25px !important; }

.u-p25 {
  padding: 25px !important; }

.u-pt25 {
  padding-top: 25px !important; }

.u-pb25 {
  padding-bottom: 25px !important; }

.u-pl25 {
  padding-left: 25px !important; }

.u-pr25 {
  padding-right: 25px !important; }

.u-m30 {
  margin: 30px !important; }

.u-mtb30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.u-mlr30 {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.u-mt30 {
  margin-top: 30px !important; }

.u-mb30 {
  margin-bottom: 30px !important; }

.u-ml30 {
  margin-left: 30px !important; }

.u-mr30 {
  margin-right: 30px !important; }

.u-p30 {
  padding: 30px !important; }

.u-pt30 {
  padding-top: 30px !important; }

.u-pb30 {
  padding-bottom: 30px !important; }

.u-pl30 {
  padding-left: 30px !important; }

.u-pr30 {
  padding-right: 30px !important; }

.u-m35 {
  margin: 35px !important; }

.u-mtb35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important; }

.u-mlr35 {
  margin-left: 35px !important;
  margin-right: 35px !important; }

.u-mt35 {
  margin-top: 35px !important; }

.u-mb35 {
  margin-bottom: 35px !important; }

.u-ml35 {
  margin-left: 35px !important; }

.u-mr35 {
  margin-right: 35px !important; }

.u-p35 {
  padding: 35px !important; }

.u-pt35 {
  padding-top: 35px !important; }

.u-pb35 {
  padding-bottom: 35px !important; }

.u-pl35 {
  padding-left: 35px !important; }

.u-pr35 {
  padding-right: 35px !important; }

.u-m40 {
  margin: 40px !important; }

.u-mtb40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important; }

.u-mlr40 {
  margin-left: 40px !important;
  margin-right: 40px !important; }

.u-mt40 {
  margin-top: 40px !important; }

.u-mb40 {
  margin-bottom: 40px !important; }

.u-ml40 {
  margin-left: 40px !important; }

.u-mr40 {
  margin-right: 40px !important; }

.u-p40 {
  padding: 40px !important; }

.u-pt40 {
  padding-top: 40px !important; }

.u-pb40 {
  padding-bottom: 40px !important; }

.u-pl40 {
  padding-left: 40px !important; }

.u-pr40 {
  padding-right: 40px !important; }

.u-m45 {
  margin: 45px !important; }

.u-mtb45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important; }

.u-mlr45 {
  margin-left: 45px !important;
  margin-right: 45px !important; }

.u-mt45 {
  margin-top: 45px !important; }

.u-mb45 {
  margin-bottom: 45px !important; }

.u-ml45 {
  margin-left: 45px !important; }

.u-mr45 {
  margin-right: 45px !important; }

.u-p45 {
  padding: 45px !important; }

.u-pt45 {
  padding-top: 45px !important; }

.u-pb45 {
  padding-bottom: 45px !important; }

.u-pl45 {
  padding-left: 45px !important; }

.u-pr45 {
  padding-right: 45px !important; }

.u-m50 {
  margin: 50px !important; }

.u-mtb50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.u-mlr50 {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.u-mt50 {
  margin-top: 50px !important; }

.u-mb50 {
  margin-bottom: 50px !important; }

.u-ml50 {
  margin-left: 50px !important; }

.u-mr50 {
  margin-right: 50px !important; }

.u-p50 {
  padding: 50px !important; }

.u-pt50 {
  padding-top: 50px !important; }

.u-pb50 {
  padding-bottom: 50px !important; }

.u-pl50 {
  padding-left: 50px !important; }

.u-pr50 {
  padding-right: 50px !important; }

.u-m55 {
  margin: 55px !important; }

.u-mtb55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important; }

.u-mlr55 {
  margin-left: 55px !important;
  margin-right: 55px !important; }

.u-mt55 {
  margin-top: 55px !important; }

.u-mb55 {
  margin-bottom: 55px !important; }

.u-ml55 {
  margin-left: 55px !important; }

.u-mr55 {
  margin-right: 55px !important; }

.u-p55 {
  padding: 55px !important; }

.u-pt55 {
  padding-top: 55px !important; }

.u-pb55 {
  padding-bottom: 55px !important; }

.u-pl55 {
  padding-left: 55px !important; }

.u-pr55 {
  padding-right: 55px !important; }

.u-m60 {
  margin: 60px !important; }

.u-mtb60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important; }

.u-mlr60 {
  margin-left: 60px !important;
  margin-right: 60px !important; }

.u-mt60 {
  margin-top: 60px !important; }

.u-mb60 {
  margin-bottom: 60px !important; }

.u-ml60 {
  margin-left: 60px !important; }

.u-mr60 {
  margin-right: 60px !important; }

.u-p60 {
  padding: 60px !important; }

.u-pt60 {
  padding-top: 60px !important; }

.u-pb60 {
  padding-bottom: 60px !important; }

.u-pl60 {
  padding-left: 60px !important; }

.u-pr60 {
  padding-right: 60px !important; }

.u-m65 {
  margin: 65px !important; }

.u-mtb65 {
  margin-top: 65px !important;
  margin-bottom: 65px !important; }

.u-mlr65 {
  margin-left: 65px !important;
  margin-right: 65px !important; }

.u-mt65 {
  margin-top: 65px !important; }

.u-mb65 {
  margin-bottom: 65px !important; }

.u-ml65 {
  margin-left: 65px !important; }

.u-mr65 {
  margin-right: 65px !important; }

.u-p65 {
  padding: 65px !important; }

.u-pt65 {
  padding-top: 65px !important; }

.u-pb65 {
  padding-bottom: 65px !important; }

.u-pl65 {
  padding-left: 65px !important; }

.u-pr65 {
  padding-right: 65px !important; }

.u-m70 {
  margin: 70px !important; }

.u-mtb70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.u-mlr70 {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.u-mt70 {
  margin-top: 70px !important; }

.u-mb70 {
  margin-bottom: 70px !important; }

.u-ml70 {
  margin-left: 70px !important; }

.u-mr70 {
  margin-right: 70px !important; }

.u-p70 {
  padding: 70px !important; }

.u-pt70 {
  padding-top: 70px !important; }

.u-pb70 {
  padding-bottom: 70px !important; }

.u-pl70 {
  padding-left: 70px !important; }

.u-pr70 {
  padding-right: 70px !important; }

.u-m75 {
  margin: 75px !important; }

.u-mtb75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important; }

.u-mlr75 {
  margin-left: 75px !important;
  margin-right: 75px !important; }

.u-mt75 {
  margin-top: 75px !important; }

.u-mb75 {
  margin-bottom: 75px !important; }

.u-ml75 {
  margin-left: 75px !important; }

.u-mr75 {
  margin-right: 75px !important; }

.u-p75 {
  padding: 75px !important; }

.u-pt75 {
  padding-top: 75px !important; }

.u-pb75 {
  padding-bottom: 75px !important; }

.u-pl75 {
  padding-left: 75px !important; }

.u-pr75 {
  padding-right: 75px !important; }

.u-m80 {
  margin: 80px !important; }

.u-mtb80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important; }

.u-mlr80 {
  margin-left: 80px !important;
  margin-right: 80px !important; }

.u-mt80 {
  margin-top: 80px !important; }

.u-mb80 {
  margin-bottom: 80px !important; }

.u-ml80 {
  margin-left: 80px !important; }

.u-mr80 {
  margin-right: 80px !important; }

.u-p80 {
  padding: 80px !important; }

.u-pt80 {
  padding-top: 80px !important; }

.u-pb80 {
  padding-bottom: 80px !important; }

.u-pl80 {
  padding-left: 80px !important; }

.u-pr80 {
  padding-right: 80px !important; }

.u-m85 {
  margin: 85px !important; }

.u-mtb85 {
  margin-top: 85px !important;
  margin-bottom: 85px !important; }

.u-mlr85 {
  margin-left: 85px !important;
  margin-right: 85px !important; }

.u-mt85 {
  margin-top: 85px !important; }

.u-mb85 {
  margin-bottom: 85px !important; }

.u-ml85 {
  margin-left: 85px !important; }

.u-mr85 {
  margin-right: 85px !important; }

.u-p85 {
  padding: 85px !important; }

.u-pt85 {
  padding-top: 85px !important; }

.u-pb85 {
  padding-bottom: 85px !important; }

.u-pl85 {
  padding-left: 85px !important; }

.u-pr85 {
  padding-right: 85px !important; }

.u-m90 {
  margin: 90px !important; }

.u-mtb90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important; }

.u-mlr90 {
  margin-left: 90px !important;
  margin-right: 90px !important; }

.u-mt90 {
  margin-top: 90px !important; }

.u-mb90 {
  margin-bottom: 90px !important; }

.u-ml90 {
  margin-left: 90px !important; }

.u-mr90 {
  margin-right: 90px !important; }

.u-p90 {
  padding: 90px !important; }

.u-pt90 {
  padding-top: 90px !important; }

.u-pb90 {
  padding-bottom: 90px !important; }

.u-pl90 {
  padding-left: 90px !important; }

.u-pr90 {
  padding-right: 90px !important; }

.u-m95 {
  margin: 95px !important; }

.u-mtb95 {
  margin-top: 95px !important;
  margin-bottom: 95px !important; }

.u-mlr95 {
  margin-left: 95px !important;
  margin-right: 95px !important; }

.u-mt95 {
  margin-top: 95px !important; }

.u-mb95 {
  margin-bottom: 95px !important; }

.u-ml95 {
  margin-left: 95px !important; }

.u-mr95 {
  margin-right: 95px !important; }

.u-p95 {
  padding: 95px !important; }

.u-pt95 {
  padding-top: 95px !important; }

.u-pb95 {
  padding-bottom: 95px !important; }

.u-pl95 {
  padding-left: 95px !important; }

.u-pr95 {
  padding-right: 95px !important; }

.u-m100 {
  margin: 100px !important; }

.u-mtb100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important; }

.u-mlr100 {
  margin-left: 100px !important;
  margin-right: 100px !important; }

.u-mt100 {
  margin-top: 100px !important; }

.u-mb100 {
  margin-bottom: 100px !important; }

.u-ml100 {
  margin-left: 100px !important; }

.u-mr100 {
  margin-right: 100px !important; }

.u-p100 {
  padding: 100px !important; }

.u-pt100 {
  padding-top: 100px !important; }

.u-pb100 {
  padding-bottom: 100px !important; }

.u-pl100 {
  padding-left: 100px !important; }

.u-pr100 {
  padding-right: 100px !important; }

.u-m105 {
  margin: 105px !important; }

.u-mtb105 {
  margin-top: 105px !important;
  margin-bottom: 105px !important; }

.u-mlr105 {
  margin-left: 105px !important;
  margin-right: 105px !important; }

.u-mt105 {
  margin-top: 105px !important; }

.u-mb105 {
  margin-bottom: 105px !important; }

.u-ml105 {
  margin-left: 105px !important; }

.u-mr105 {
  margin-right: 105px !important; }

.u-p105 {
  padding: 105px !important; }

.u-pt105 {
  padding-top: 105px !important; }

.u-pb105 {
  padding-bottom: 105px !important; }

.u-pl105 {
  padding-left: 105px !important; }

.u-pr105 {
  padding-right: 105px !important; }

.u-m110 {
  margin: 110px !important; }

.u-mtb110 {
  margin-top: 110px !important;
  margin-bottom: 110px !important; }

.u-mlr110 {
  margin-left: 110px !important;
  margin-right: 110px !important; }

.u-mt110 {
  margin-top: 110px !important; }

.u-mb110 {
  margin-bottom: 110px !important; }

.u-ml110 {
  margin-left: 110px !important; }

.u-mr110 {
  margin-right: 110px !important; }

.u-p110 {
  padding: 110px !important; }

.u-pt110 {
  padding-top: 110px !important; }

.u-pb110 {
  padding-bottom: 110px !important; }

.u-pl110 {
  padding-left: 110px !important; }

.u-pr110 {
  padding-right: 110px !important; }

.u-m115 {
  margin: 115px !important; }

.u-mtb115 {
  margin-top: 115px !important;
  margin-bottom: 115px !important; }

.u-mlr115 {
  margin-left: 115px !important;
  margin-right: 115px !important; }

.u-mt115 {
  margin-top: 115px !important; }

.u-mb115 {
  margin-bottom: 115px !important; }

.u-ml115 {
  margin-left: 115px !important; }

.u-mr115 {
  margin-right: 115px !important; }

.u-p115 {
  padding: 115px !important; }

.u-pt115 {
  padding-top: 115px !important; }

.u-pb115 {
  padding-bottom: 115px !important; }

.u-pl115 {
  padding-left: 115px !important; }

.u-pr115 {
  padding-right: 115px !important; }

.u-m120 {
  margin: 120px !important; }

.u-mtb120 {
  margin-top: 120px !important;
  margin-bottom: 120px !important; }

.u-mlr120 {
  margin-left: 120px !important;
  margin-right: 120px !important; }

.u-mt120 {
  margin-top: 120px !important; }

.u-mb120 {
  margin-bottom: 120px !important; }

.u-ml120 {
  margin-left: 120px !important; }

.u-mr120 {
  margin-right: 120px !important; }

.u-p120 {
  padding: 120px !important; }

.u-pt120 {
  padding-top: 120px !important; }

.u-pb120 {
  padding-bottom: 120px !important; }

.u-pl120 {
  padding-left: 120px !important; }

.u-pr120 {
  padding-right: 120px !important; }

.u-m125 {
  margin: 125px !important; }

.u-mtb125 {
  margin-top: 125px !important;
  margin-bottom: 125px !important; }

.u-mlr125 {
  margin-left: 125px !important;
  margin-right: 125px !important; }

.u-mt125 {
  margin-top: 125px !important; }

.u-mb125 {
  margin-bottom: 125px !important; }

.u-ml125 {
  margin-left: 125px !important; }

.u-mr125 {
  margin-right: 125px !important; }

.u-p125 {
  padding: 125px !important; }

.u-pt125 {
  padding-top: 125px !important; }

.u-pb125 {
  padding-bottom: 125px !important; }

.u-pl125 {
  padding-left: 125px !important; }

.u-pr125 {
  padding-right: 125px !important; }

.u-m130 {
  margin: 130px !important; }

.u-mtb130 {
  margin-top: 130px !important;
  margin-bottom: 130px !important; }

.u-mlr130 {
  margin-left: 130px !important;
  margin-right: 130px !important; }

.u-mt130 {
  margin-top: 130px !important; }

.u-mb130 {
  margin-bottom: 130px !important; }

.u-ml130 {
  margin-left: 130px !important; }

.u-mr130 {
  margin-right: 130px !important; }

.u-p130 {
  padding: 130px !important; }

.u-pt130 {
  padding-top: 130px !important; }

.u-pb130 {
  padding-bottom: 130px !important; }

.u-pl130 {
  padding-left: 130px !important; }

.u-pr130 {
  padding-right: 130px !important; }

.u-m135 {
  margin: 135px !important; }

.u-mtb135 {
  margin-top: 135px !important;
  margin-bottom: 135px !important; }

.u-mlr135 {
  margin-left: 135px !important;
  margin-right: 135px !important; }

.u-mt135 {
  margin-top: 135px !important; }

.u-mb135 {
  margin-bottom: 135px !important; }

.u-ml135 {
  margin-left: 135px !important; }

.u-mr135 {
  margin-right: 135px !important; }

.u-p135 {
  padding: 135px !important; }

.u-pt135 {
  padding-top: 135px !important; }

.u-pb135 {
  padding-bottom: 135px !important; }

.u-pl135 {
  padding-left: 135px !important; }

.u-pr135 {
  padding-right: 135px !important; }

.u-m140 {
  margin: 140px !important; }

.u-mtb140 {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.u-mlr140 {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.u-mt140 {
  margin-top: 140px !important; }

.u-mb140 {
  margin-bottom: 140px !important; }

.u-ml140 {
  margin-left: 140px !important; }

.u-mr140 {
  margin-right: 140px !important; }

.u-p140 {
  padding: 140px !important; }

.u-pt140 {
  padding-top: 140px !important; }

.u-pb140 {
  padding-bottom: 140px !important; }

.u-pl140 {
  padding-left: 140px !important; }

.u-pr140 {
  padding-right: 140px !important; }

.u-m145 {
  margin: 145px !important; }

.u-mtb145 {
  margin-top: 145px !important;
  margin-bottom: 145px !important; }

.u-mlr145 {
  margin-left: 145px !important;
  margin-right: 145px !important; }

.u-mt145 {
  margin-top: 145px !important; }

.u-mb145 {
  margin-bottom: 145px !important; }

.u-ml145 {
  margin-left: 145px !important; }

.u-mr145 {
  margin-right: 145px !important; }

.u-p145 {
  padding: 145px !important; }

.u-pt145 {
  padding-top: 145px !important; }

.u-pb145 {
  padding-bottom: 145px !important; }

.u-pl145 {
  padding-left: 145px !important; }

.u-pr145 {
  padding-right: 145px !important; }

.u-m150 {
  margin: 150px !important; }

.u-mtb150 {
  margin-top: 150px !important;
  margin-bottom: 150px !important; }

.u-mlr150 {
  margin-left: 150px !important;
  margin-right: 150px !important; }

.u-mt150 {
  margin-top: 150px !important; }

.u-mb150 {
  margin-bottom: 150px !important; }

.u-ml150 {
  margin-left: 150px !important; }

.u-mr150 {
  margin-right: 150px !important; }

.u-p150 {
  padding: 150px !important; }

.u-pt150 {
  padding-top: 150px !important; }

.u-pb150 {
  padding-bottom: 150px !important; }

.u-pl150 {
  padding-left: 150px !important; }

.u-pr150 {
  padding-right: 150px !important; }

.u-m155 {
  margin: 155px !important; }

.u-mtb155 {
  margin-top: 155px !important;
  margin-bottom: 155px !important; }

.u-mlr155 {
  margin-left: 155px !important;
  margin-right: 155px !important; }

.u-mt155 {
  margin-top: 155px !important; }

.u-mb155 {
  margin-bottom: 155px !important; }

.u-ml155 {
  margin-left: 155px !important; }

.u-mr155 {
  margin-right: 155px !important; }

.u-p155 {
  padding: 155px !important; }

.u-pt155 {
  padding-top: 155px !important; }

.u-pb155 {
  padding-bottom: 155px !important; }

.u-pl155 {
  padding-left: 155px !important; }

.u-pr155 {
  padding-right: 155px !important; }

.u-m160 {
  margin: 160px !important; }

.u-mtb160 {
  margin-top: 160px !important;
  margin-bottom: 160px !important; }

.u-mlr160 {
  margin-left: 160px !important;
  margin-right: 160px !important; }

.u-mt160 {
  margin-top: 160px !important; }

.u-mb160 {
  margin-bottom: 160px !important; }

.u-ml160 {
  margin-left: 160px !important; }

.u-mr160 {
  margin-right: 160px !important; }

.u-p160 {
  padding: 160px !important; }

.u-pt160 {
  padding-top: 160px !important; }

.u-pb160 {
  padding-bottom: 160px !important; }

.u-pl160 {
  padding-left: 160px !important; }

.u-pr160 {
  padding-right: 160px !important; }

.u-m165 {
  margin: 165px !important; }

.u-mtb165 {
  margin-top: 165px !important;
  margin-bottom: 165px !important; }

.u-mlr165 {
  margin-left: 165px !important;
  margin-right: 165px !important; }

.u-mt165 {
  margin-top: 165px !important; }

.u-mb165 {
  margin-bottom: 165px !important; }

.u-ml165 {
  margin-left: 165px !important; }

.u-mr165 {
  margin-right: 165px !important; }

.u-p165 {
  padding: 165px !important; }

.u-pt165 {
  padding-top: 165px !important; }

.u-pb165 {
  padding-bottom: 165px !important; }

.u-pl165 {
  padding-left: 165px !important; }

.u-pr165 {
  padding-right: 165px !important; }

.u-m170 {
  margin: 170px !important; }

.u-mtb170 {
  margin-top: 170px !important;
  margin-bottom: 170px !important; }

.u-mlr170 {
  margin-left: 170px !important;
  margin-right: 170px !important; }

.u-mt170 {
  margin-top: 170px !important; }

.u-mb170 {
  margin-bottom: 170px !important; }

.u-ml170 {
  margin-left: 170px !important; }

.u-mr170 {
  margin-right: 170px !important; }

.u-p170 {
  padding: 170px !important; }

.u-pt170 {
  padding-top: 170px !important; }

.u-pb170 {
  padding-bottom: 170px !important; }

.u-pl170 {
  padding-left: 170px !important; }

.u-pr170 {
  padding-right: 170px !important; }

.u-m175 {
  margin: 175px !important; }

.u-mtb175 {
  margin-top: 175px !important;
  margin-bottom: 175px !important; }

.u-mlr175 {
  margin-left: 175px !important;
  margin-right: 175px !important; }

.u-mt175 {
  margin-top: 175px !important; }

.u-mb175 {
  margin-bottom: 175px !important; }

.u-ml175 {
  margin-left: 175px !important; }

.u-mr175 {
  margin-right: 175px !important; }

.u-p175 {
  padding: 175px !important; }

.u-pt175 {
  padding-top: 175px !important; }

.u-pb175 {
  padding-bottom: 175px !important; }

.u-pl175 {
  padding-left: 175px !important; }

.u-pr175 {
  padding-right: 175px !important; }

.u-m180 {
  margin: 180px !important; }

.u-mtb180 {
  margin-top: 180px !important;
  margin-bottom: 180px !important; }

.u-mlr180 {
  margin-left: 180px !important;
  margin-right: 180px !important; }

.u-mt180 {
  margin-top: 180px !important; }

.u-mb180 {
  margin-bottom: 180px !important; }

.u-ml180 {
  margin-left: 180px !important; }

.u-mr180 {
  margin-right: 180px !important; }

.u-p180 {
  padding: 180px !important; }

.u-pt180 {
  padding-top: 180px !important; }

.u-pb180 {
  padding-bottom: 180px !important; }

.u-pl180 {
  padding-left: 180px !important; }

.u-pr180 {
  padding-right: 180px !important; }

.u-m-auto {
  margin-left: auto !important;
  margin-right: auto !important; }

.u-m-pcAuto-spLeft {
  margin-left: 0 !important;
  margin-right: auto !important; }
  @media all and (min-width: 800px) {
    .u-m-pcAuto-spLeft {
      margin-left: auto !important;
      margin-right: auto !important; } }

.u-m-pcAuto-spRight {
  margin-left: auto !important;
  margin-right: 0 !important; }
  @media all and (min-width: 800px) {
    .u-m-pcAuto-spRight {
      margin-left: auto !important;
      margin-right: auto !important; } }

.u-mb10_half {
  margin-bottom: 5px !important; }
  @media all and (min-width: 800px) {
    .u-mb10_half {
      margin-bottom: 10px !important; } }

.u-mb20_half {
  margin-bottom: 10px !important; }
  @media all and (min-width: 800px) {
    .u-mb20_half {
      margin-bottom: 20px !important; } }

.u-mb30_half {
  margin-bottom: 15px !important; }
  @media all and (min-width: 800px) {
    .u-mb30_half {
      margin-bottom: 30px !important; } }

.u-mb40_half {
  margin-bottom: 20px !important; }
  @media all and (min-width: 800px) {
    .u-mb40_half {
      margin-bottom: 40px !important; } }

.u-mb50_half {
  margin-bottom: 25px !important; }
  @media all and (min-width: 800px) {
    .u-mb50_half {
      margin-bottom: 50px !important; } }

.u-mb60_half {
  margin-bottom: 30px !important; }
  @media all and (min-width: 800px) {
    .u-mb60_half {
      margin-bottom: 60px !important; } }

.u-mb70_half {
  margin-bottom: 35px !important; }
  @media all and (min-width: 800px) {
    .u-mb70_half {
      margin-bottom: 70px !important; } }

.u-mb80_half {
  margin-bottom: 40px !important; }
  @media all and (min-width: 800px) {
    .u-mb80_half {
      margin-bottom: 80px !important; } }

.u-mb90_half {
  margin-bottom: 45px !important; }
  @media all and (min-width: 800px) {
    .u-mb90_half {
      margin-bottom: 90px !important; } }

.u-mb100_half {
  margin-bottom: 50px !important; }
  @media all and (min-width: 800px) {
    .u-mb100_half {
      margin-bottom: 100px !important; } }

.u-mb110_half {
  margin-bottom: 55px !important; }
  @media all and (min-width: 800px) {
    .u-mb110_half {
      margin-bottom: 110px !important; } }

.u-mb120_half {
  margin-bottom: 60px !important; }
  @media all and (min-width: 800px) {
    .u-mb120_half {
      margin-bottom: 120px !important; } }

.u-mb130_half {
  margin-bottom: 65px !important; }
  @media all and (min-width: 800px) {
    .u-mb130_half {
      margin-bottom: 130px !important; } }

.u-mb140_half {
  margin-bottom: 70px !important; }
  @media all and (min-width: 800px) {
    .u-mb140_half {
      margin-bottom: 140px !important; } }

.u-mb150_half {
  margin-bottom: 75px !important; }
  @media all and (min-width: 800px) {
    .u-mb150_half {
      margin-bottom: 150px !important; } }

.u-mb160_half {
  margin-bottom: 80px !important; }
  @media all and (min-width: 800px) {
    .u-mb160_half {
      margin-bottom: 160px !important; } }

.u-mb170_half {
  margin-bottom: 85px !important; }
  @media all and (min-width: 800px) {
    .u-mb170_half {
      margin-bottom: 170px !important; } }

.u-mb180_half {
  margin-bottom: 90px !important; }
  @media all and (min-width: 800px) {
    .u-mb180_half {
      margin-bottom: 180px !important; } }

.u-mb15_23rd {
  margin-bottom: 10px !important; }
  @media all and (min-width: 800px) {
    .u-mb15_23rd {
      margin-bottom: 15px !important; } }

.u-mb30_23rd {
  margin-bottom: 20px !important; }
  @media all and (min-width: 800px) {
    .u-mb30_23rd {
      margin-bottom: 30px !important; } }

.u-mb45_23rd {
  margin-bottom: 30px !important; }
  @media all and (min-width: 800px) {
    .u-mb45_23rd {
      margin-bottom: 45px !important; } }

.u-mb60_23rd {
  margin-bottom: 40px !important; }
  @media all and (min-width: 800px) {
    .u-mb60_23rd {
      margin-bottom: 60px !important; } }

.u-mb75_23rd {
  margin-bottom: 50px !important; }
  @media all and (min-width: 800px) {
    .u-mb75_23rd {
      margin-bottom: 75px !important; } }

.u-mb90_23rd {
  margin-bottom: 60px !important; }
  @media all and (min-width: 800px) {
    .u-mb90_23rd {
      margin-bottom: 90px !important; } }

.u-mb105_23rd {
  margin-bottom: 70px !important; }
  @media all and (min-width: 800px) {
    .u-mb105_23rd {
      margin-bottom: 105px !important; } }

.u-mb120_23rd {
  margin-bottom: 80px !important; }
  @media all and (min-width: 800px) {
    .u-mb120_23rd {
      margin-bottom: 120px !important; } }

.u-mb135_23rd {
  margin-bottom: 90px !important; }
  @media all and (min-width: 800px) {
    .u-mb135_23rd {
      margin-bottom: 135px !important; } }

.u-mb150_23rd {
  margin-bottom: 100px !important; }
  @media all and (min-width: 800px) {
    .u-mb150_23rd {
      margin-bottom: 150px !important; } }

.u-mb165_23rd {
  margin-bottom: 110px !important; }
  @media all and (min-width: 800px) {
    .u-mb165_23rd {
      margin-bottom: 165px !important; } }

.u-mb180_23rd {
  margin-bottom: 120px !important; }
  @media all and (min-width: 800px) {
    .u-mb180_23rd {
      margin-bottom: 180px !important; } }

.u-mb195_23rd {
  margin-bottom: 130px !important; }
  @media all and (min-width: 800px) {
    .u-mb195_23rd {
      margin-bottom: 195px !important; } }

.u-mb210_23rd {
  margin-bottom: 140px !important; }
  @media all and (min-width: 800px) {
    .u-mb210_23rd {
      margin-bottom: 210px !important; } }

.u-mb225_23rd {
  margin-bottom: 150px !important; }
  @media all and (min-width: 800px) {
    .u-mb225_23rd {
      margin-bottom: 225px !important; } }

.u-mb240_23rd {
  margin-bottom: 160px !important; }
  @media all and (min-width: 800px) {
    .u-mb240_23rd {
      margin-bottom: 240px !important; } }

.u-mb255_23rd {
  margin-bottom: 170px !important; }
  @media all and (min-width: 800px) {
    .u-mb255_23rd {
      margin-bottom: 255px !important; } }

.u-mb270_23rd {
  margin-bottom: 180px !important; }
  @media all and (min-width: 800px) {
    .u-mb270_23rd {
      margin-bottom: 270px !important; } }

.u-mb10_level {
  margin-bottom: 3.33333px !important; }
  @media all and (min-width: 500px) {
    .u-mb10_level {
      margin-bottom: 5px !important; } }
  @media all and (min-width: 800px) {
    .u-mb10_level {
      margin-bottom: 10px !important; } }

.u-mb20_level {
  margin-bottom: 6.66667px !important; }
  @media all and (min-width: 500px) {
    .u-mb20_level {
      margin-bottom: 10px !important; } }
  @media all and (min-width: 800px) {
    .u-mb20_level {
      margin-bottom: 20px !important; } }

.u-mb30_level {
  margin-bottom: 10px !important; }
  @media all and (min-width: 500px) {
    .u-mb30_level {
      margin-bottom: 15px !important; } }
  @media all and (min-width: 800px) {
    .u-mb30_level {
      margin-bottom: 30px !important; } }

.u-mb40_level {
  margin-bottom: 13.33333px !important; }
  @media all and (min-width: 500px) {
    .u-mb40_level {
      margin-bottom: 20px !important; } }
  @media all and (min-width: 800px) {
    .u-mb40_level {
      margin-bottom: 40px !important; } }

.u-mb50_level {
  margin-bottom: 16.66667px !important; }
  @media all and (min-width: 500px) {
    .u-mb50_level {
      margin-bottom: 25px !important; } }
  @media all and (min-width: 800px) {
    .u-mb50_level {
      margin-bottom: 50px !important; } }

.u-mb60_level {
  margin-bottom: 20px !important; }
  @media all and (min-width: 500px) {
    .u-mb60_level {
      margin-bottom: 30px !important; } }
  @media all and (min-width: 800px) {
    .u-mb60_level {
      margin-bottom: 60px !important; } }

.u-mb70_level {
  margin-bottom: 23.33333px !important; }
  @media all and (min-width: 500px) {
    .u-mb70_level {
      margin-bottom: 35px !important; } }
  @media all and (min-width: 800px) {
    .u-mb70_level {
      margin-bottom: 70px !important; } }

.u-mb80_level {
  margin-bottom: 26.66667px !important; }
  @media all and (min-width: 500px) {
    .u-mb80_level {
      margin-bottom: 40px !important; } }
  @media all and (min-width: 800px) {
    .u-mb80_level {
      margin-bottom: 80px !important; } }

.u-mb90_level {
  margin-bottom: 30px !important; }
  @media all and (min-width: 500px) {
    .u-mb90_level {
      margin-bottom: 45px !important; } }
  @media all and (min-width: 800px) {
    .u-mb90_level {
      margin-bottom: 90px !important; } }

.u-mb100_level {
  margin-bottom: 33.33333px !important; }
  @media all and (min-width: 500px) {
    .u-mb100_level {
      margin-bottom: 50px !important; } }
  @media all and (min-width: 800px) {
    .u-mb100_level {
      margin-bottom: 100px !important; } }

.u-mb110_level {
  margin-bottom: 36.66667px !important; }
  @media all and (min-width: 500px) {
    .u-mb110_level {
      margin-bottom: 55px !important; } }
  @media all and (min-width: 800px) {
    .u-mb110_level {
      margin-bottom: 110px !important; } }

.u-mb120_level {
  margin-bottom: 40px !important; }
  @media all and (min-width: 500px) {
    .u-mb120_level {
      margin-bottom: 60px !important; } }
  @media all and (min-width: 800px) {
    .u-mb120_level {
      margin-bottom: 120px !important; } }

.u-mb130_level {
  margin-bottom: 43.33333px !important; }
  @media all and (min-width: 500px) {
    .u-mb130_level {
      margin-bottom: 65px !important; } }
  @media all and (min-width: 800px) {
    .u-mb130_level {
      margin-bottom: 130px !important; } }

.u-mb140_level {
  margin-bottom: 46.66667px !important; }
  @media all and (min-width: 500px) {
    .u-mb140_level {
      margin-bottom: 70px !important; } }
  @media all and (min-width: 800px) {
    .u-mb140_level {
      margin-bottom: 140px !important; } }

.u-mb150_level {
  margin-bottom: 50px !important; }
  @media all and (min-width: 500px) {
    .u-mb150_level {
      margin-bottom: 75px !important; } }
  @media all and (min-width: 800px) {
    .u-mb150_level {
      margin-bottom: 150px !important; } }

.u-mb160_level {
  margin-bottom: 53.33333px !important; }
  @media all and (min-width: 500px) {
    .u-mb160_level {
      margin-bottom: 80px !important; } }
  @media all and (min-width: 800px) {
    .u-mb160_level {
      margin-bottom: 160px !important; } }

.u-mb170_level {
  margin-bottom: 56.66667px !important; }
  @media all and (min-width: 500px) {
    .u-mb170_level {
      margin-bottom: 85px !important; } }
  @media all and (min-width: 800px) {
    .u-mb170_level {
      margin-bottom: 170px !important; } }

.u-mb180_level {
  margin-bottom: 60px !important; }
  @media all and (min-width: 500px) {
    .u-mb180_level {
      margin-bottom: 90px !important; } }
  @media all and (min-width: 800px) {
    .u-mb180_level {
      margin-bottom: 180px !important; } }

.u-mb-minus10_half {
  margin-bottom: -5px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus10_half {
      margin-bottom: -10px !important; } }

.u-mb-minus20_half {
  margin-bottom: -10px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus20_half {
      margin-bottom: -20px !important; } }

.u-mb-minus30_half {
  margin-bottom: -15px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus30_half {
      margin-bottom: -30px !important; } }

.u-mb-minus40_half {
  margin-bottom: -20px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus40_half {
      margin-bottom: -40px !important; } }

.u-mb-minus50_half {
  margin-bottom: -25px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus50_half {
      margin-bottom: -50px !important; } }

.u-mb-minus60_half {
  margin-bottom: -30px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus60_half {
      margin-bottom: -60px !important; } }

.u-mb-minus70_half {
  margin-bottom: -35px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus70_half {
      margin-bottom: -70px !important; } }

.u-mb-minus80_half {
  margin-bottom: -40px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus80_half {
      margin-bottom: -80px !important; } }

.u-mb-minus90_half {
  margin-bottom: -45px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus90_half {
      margin-bottom: -90px !important; } }

.u-mb-minus100_half {
  margin-bottom: -50px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus100_half {
      margin-bottom: -100px !important; } }

.u-mb-minus110_half {
  margin-bottom: -55px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus110_half {
      margin-bottom: -110px !important; } }

.u-mb-minus120_half {
  margin-bottom: -60px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus120_half {
      margin-bottom: -120px !important; } }

.u-mb-minus130_half {
  margin-bottom: -65px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus130_half {
      margin-bottom: -130px !important; } }

.u-mb-minus140_half {
  margin-bottom: -70px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus140_half {
      margin-bottom: -140px !important; } }

.u-mb-minus150_half {
  margin-bottom: -75px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus150_half {
      margin-bottom: -150px !important; } }

.u-mb-minus160_half {
  margin-bottom: -80px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus160_half {
      margin-bottom: -160px !important; } }

.u-mb-minus170_half {
  margin-bottom: -85px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus170_half {
      margin-bottom: -170px !important; } }

.u-mb-minus180_half {
  margin-bottom: -90px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus180_half {
      margin-bottom: -180px !important; } }

.u-mb15_half {
  margin-bottom: calc(15px / 2) !important; }
  @media all and (min-width: 800px) {
    .u-mb15_half {
      margin-bottom: 15px !important; } }

.u-mb25_half {
  margin-bottom: calc(25px / 2) !important; }
  @media all and (min-width: 800px) {
    .u-mb25_half {
      margin-bottom: 25px !important; } }

.u-mb36_15 {
  margin-bottom: 15px !important; }
  @media all and (min-width: 800px) {
    .u-mb36_15 {
      margin-bottom: 36px !important; } }

.u-mb50_30 {
  margin-bottom: 30px !important; }
  @media all and (min-width: 800px) {
    .u-mb50_30 {
      margin-bottom: 50px !important; } }

.u-mb70_20 {
  margin-bottom: 20px !important; }
  @media all and (min-width: 800px) {
    .u-mb70_20 {
      margin-bottom: 70px !important; } }

.u-mb80_20 {
  margin-bottom: 20px !important; }
  @media all and (min-width: 800px) {
    .u-mb80_20 {
      margin-bottom: 80px !important; } }

.u-pb20_0 {
  padding-bottom: 0 !important; }
  @media all and (min-width: 800px) {
    .u-pb20_0 {
      padding-bottom: 20px !important; } }

.u-mb20_0_0 {
  margin-bottom: 0 !important; }
  @media all and (min-width: 800px) {
    .u-mb20_0_0 {
      margin-bottom: 0 !important; } }
  @media all and (min-width: 1120px) {
    .u-mb20_0_0 {
      margin-bottom: 20px !important; } }

.u-mb60_30_10 {
  margin-bottom: 10px !important; }
  @media all and (min-width: 800px) {
    .u-mb60_30_10 {
      margin-bottom: 30px !important; } }
  @media all and (min-width: 1120px) {
    .u-mb60_30_10 {
      margin-bottom: 60px !important; } }

.u-mb70_50_25 {
  margin-bottom: 25px !important; }
  @media all and (min-width: 800px) {
    .u-mb70_50_25 {
      margin-bottom: 50px !important; } }
  @media all and (min-width: 1120px) {
    .u-mb70_50_25 {
      margin-bottom: 70px !important; } }

.u-mb60_40_15 {
  margin-bottom: 15px !important; }
  @media all and (min-width: 800px) {
    .u-mb60_40_15 {
      margin-bottom: 40px !important; } }
  @media all and (min-width: 1120px) {
    .u-mb60_40_15 {
      margin-bottom: 60px !important; } }

.u-mb90_50_40 {
  margin-bottom: 40px !important; }
  @media all and (min-width: 800px) {
    .u-mb90_50_40 {
      margin-bottom: 50px !important; } }
  @media all and (min-width: 1120px) {
    .u-mb90_50_40 {
      margin-bottom: 90px !important; } }

.u-mb90_50_50 {
  margin-bottom: 50px !important; }
  @media all and (min-width: 800px) {
    .u-mb90_50_50 {
      margin-bottom: 50px !important; } }
  @media all and (min-width: 1120px) {
    .u-mb90_50_50 {
      margin-bottom: 90px !important; } }

.u-mb100_80_20 {
  margin-bottom: 20px !important; }
  @media all and (min-width: 800px) {
    .u-mb100_80_20 {
      margin-bottom: 80px !important; } }
  @media all and (min-width: 1120px) {
    .u-mb100_80_20 {
      margin-bottom: 100px !important; } }

.u-mb110_70_30 {
  margin-bottom: 30px !important; }
  @media all and (min-width: 800px) {
    .u-mb110_70_30 {
      margin-bottom: 70px !important; } }
  @media all and (min-width: 1120px) {
    .u-mb110_70_30 {
      margin-bottom: 110px !important; } }

.u-mb130_120_60 {
  margin-bottom: 60px !important; }
  @media all and (min-width: 800px) {
    .u-mb130_120_60 {
      margin-bottom: 120px !important; } }
  @media all and (min-width: 1120px) {
    .u-mb130_120_60 {
      margin-bottom: 130px !important; } }

.u-mb_business_ttl {
  margin-bottom: 20px !important; }
  @media all and (min-width: 375px) {
    .u-mb_business_ttl {
      margin-bottom: 30px !important; } }
  @media all and (min-width: 500px) {
    .u-mb_business_ttl {
      margin-bottom: 40px !important; } }
  @media all and (min-width: 800px) {
    .u-mb_business_ttl {
      margin-bottom: 60px !important; } }

.u-mb_business_lead {
  margin-bottom: 50px !important; }
  @media all and (min-width: 480px) {
    .u-mb_business_lead {
      margin-bottom: 70px !important; } }
  @media all and (min-width: 800px) {
    .u-mb_business_lead {
      margin-bottom: 60px !important; } }
  @media all and (min-width: 1120px) {
    .u-mb_business_lead {
      margin-bottom: 120px !important; } }

.u-mb_company_ttl {
  margin-bottom: 15px !important; }
  @media all and (min-width: 480px) {
    .u-mb_company_ttl {
      margin-bottom: 30px !important; } }
  @media all and (min-width: 1120px) {
    .u-mb_company_ttl {
      margin-bottom: 70px !important; } }

.u-mb_company_kvbtmsec {
  margin-bottom: -65px !important; }
  @media all and (min-width: 480px) {
    .u-mb_company_kvbtmsec {
      margin-bottom: -80px !important; } }
  @media all and (min-width: 700px) {
    .u-mb_company_kvbtmsec {
      margin-bottom: -80px !important; } }
  @media all and (min-width: 800px) {
    .u-mb_company_kvbtmsec {
      margin-bottom: -160px !important; } }
  @media all and (min-width: 1120px) {
    .u-mb_company_kvbtmsec {
      margin-bottom: -215px !important; } }

.u-mb_tocontact_ttl {
  margin-bottom: 30px !important; }
  @media all and (min-width: 800px) {
    .u-mb_tocontact_ttl {
      margin-bottom: 40px !important; } }
  @media all and (min-width: 1120px) {
    .u-mb_tocontact_ttl {
      margin-bottom: 50px !important; } }
  @media all and (min-width: 1300px) {
    .u-mb_tocontact_ttl {
      margin-bottom: 62px !important; } }

.u-pr_btnpad {
  padding-right: 5vw;
  box-sizing: border-box; }
  @media all and (min-width: 800px) {
    .u-pr_btnpad {
      padding-right: 60px; } }

/* 画面外にいる状態 */
.js-fadeIn {
  opacity: 0.1;
  transform: translate(0, 30px);
  transition: all 1000ms; }

/* 画面内に入った状態 */
.js-fadeIn.js-scrollIn {
  opacity: 1;
  transform: translate(0, 0); }

.js-moveleft.js-ismove {
  animation: 0.35s ease-in-out 0s 1 both scrollMoveLeft; }
  @media all and (min-width: 1120px) {
    .js-moveleft.js-ismove {
      animation: 0.4s ease-in-out 0s 1 both scrollMoveLeft; } }
  @media all and (min-width: 1620px) {
    .js-moveleft.js-ismove {
      animation: 0.45s ease-in-out 0s 1 both scrollMoveLeft; } }

.js-drawwave {
  display: inline-block;
  position: relative;
  padding-left: 3px;
  padding-right: 3px;
  box-sizing: border-box;
  color: rgba(6, 46, 103, 0); }
  @media all and (min-width: 800px) {
    .js-drawwave {
      padding-left: 4px;
      padding-right: 4px; } }
  .js-drawwave:before {
    display: inline-block;
    position: absolute;
    top: 8%;
    left: 0%;
    right: 100%;
    content: "";
    height: 84%;
    background-color: #062e67; }
    @media all and (min-width: 800px) {
      .js-drawwave:before {
        top: 13%;
        height: 74%; } }
  .js-drawwave.-do {
    animation: drawwaveShow 1.5s both; }
    .js-drawwave.-do:before {
      animation: drawwaveIntro 1.5s ease-in-out; }

@keyframes drawerWrapOut {
  0% {
    height: calc(100vh - 100px);
    @media all and (min-width: 800px) {
      height: calc(100vh - 80px); } }
  99% {
    height: calc(100vh - 100px);
    @media all and (min-width: 800px) {
      height: calc(100vh - 80px); } }
  100% {
    height: auto; } }

@keyframes drawerIn {
  0% {
    transform: translateY(-100vh);
    background: rgba(6, 46, 103, 0); }
  1% {
    background: rgba(6, 46, 103, 0.95); }
  100% {
    background: rgba(6, 46, 103, 0.95);
    transform: translateY(0); } }

@keyframes drawerOut {
  0% {
    transform: translateY(0);
    background: rgba(6, 46, 103, 0.95); }
  99% {
    background: rgba(6, 46, 103, 0.95); }
  100% {
    background: rgba(6, 46, 103, 0);
    transform: translateY(-100vh); } }

@keyframes drawerOutHeaderBgc {
  0% {
    background: rgba(6, 46, 103, 0.95); }
  99% {
    background: rgba(6, 46, 103, 0.95); }
  100% {
    background: rgba(6, 46, 103, 0); } }

@keyframes mvMove {
  0% {
    transform: scale(1.04) translateX(0); }
  100% {
    transform: scale(1.04) translateX(1%); } }

@keyframes mvSize {
  0% {
    transform: scale(1.04); }
  100% {
    transform: scale(1.04); } }

@keyframes marquee01 {
  0% {
    transform: translate(100%, 0); }
  100% {
    transform: translate(-100%, 0); } }

@keyframes marquee01Copy {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(-200%, 0); } }

@keyframes marquee02 {
  0% {
    transform: translate(100%, 0); }
  100% {
    transform: translate(-100%, 0); } }

@keyframes marquee02Copy {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(-200%, 0); } }

@media all and (min-width: 800px) {
  @keyframes marquee02 {
    0% {
      transform: translate(100%, 0); }
    100% {
      transform: translate(-100%, 0); } }
  @keyframes marquee02Copy {
    0% {
      transform: translate(0, 0); }
    100% {
      transform: translate(-200%, 0); } } }

@keyframes scrollMoveLeft {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(-100px);
    @media all and (min-width: 1120px) {
      transform: translateX(-200px); }
    @media all and (min-width: 1620px) {
      transform: translateX(-320px); } } }

@keyframes moveArrowLR {
  0% {
    transform: translate(0px, 0px);
    animation-timing-function: ease-out; }
  50% {
    transform: translate(20px, 0px);
    animation-timing-function: ease-out; }
  100% {
    transform: translate(0px, 0px);
    animation-timing-function: ease-out; } }

@keyframes drawwaveIntro {
  0% {
    left: 0%;
    right: 100%; }
  50% {
    left: 0%;
    right: 0%; }
  100% {
    left: 100%;
    right: 0%; } }

@keyframes drawwaveShow {
  0%, 50% {
    color: rgba(6, 46, 103, 0); }
  50.00001%, 100% {
    color: #062e67; } }
