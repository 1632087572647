@mixin btnsize($pc, $sp) {
  width: 100%;

  @include breakpoint-sp {
    max-width: $sp;
  }
  @include breakpoint-pc {
    width: $pc;
  }
}

@mixin btn($status) {
  clear: both;
  text-align: center;

  &_inner, a {
    display: block;
    width: 100%;
    padding: 11px 0 12px;
    box-sizing: border-box;
    @include font-montserrat("m");
    color: color-wh(1);
    line-height: 1;
    @extend %hover;
//    cursor: pointer;

    @include breakpoint-pc {
    }
  }

  @if $status == 1 {
    &_inner, a {
      @include font-montserrat("m");
      font-size: 3.4rem;
      color: color-black01(1);

      @media all and (min-width: 640px) {
        font-size: 4rem;
      }
    }

    &_inner {
      a {
        padding: 0;
      }
    }
  }
  @else if $status == 2 {
    &_inner, a {
      @include font-montserrat("m");
      font-size: 1.6rem;
      color: color-blue02(1);
      @extend %nohover;

      @include breakpoint-mainbox {
        font-size: 1.6rem;
      }
    }

    &_inner {
      a {
        padding: 0;
      }
    }
  }
  @else if $status == 3 {
    &_inner, a {
      position: relative;
      width: auto;
      padding-left: 20px;
      padding-right: 120px;
      box-sizing: border-box;
      @include font-ntsan("m");
      font-size: 2.2rem;
      color: color-blue01(1);
      @extend %nohover;

      @include breakpoint-pc {
        font-size: 2.6rem;
      }
    }

    &_inner {
      a {
        padding: 0;
      }
    }
  }
  @else if $status == 4 {
    &_inner, a {
      position: relative;
      width: auto;
      padding-left: 100px;
      padding-right: 20px;
      box-sizing: border-box;
      @include font-dnpshumin-pr6("m");
      font-size: 1.4rem;
      color: color-blue01(1);
      @extend %nohover;

      @include breakpoint-pc {
        padding-left: 110px;
        font-size: 1.6rem;
      }
    }

    &_inner {
      a {
        padding: 0;
      }
    }
  }
}


.p0-btn1 {
  position: relative;
  @include btnsize(340px, 340px);
  @include btn(1);
  box-sizing: border-box;

  &.-w100per {
    width: 100%;
  }
  
  &.-ls02 {
    letter-spacing: 0.2rem;
  }

  &.-blue {
    .p0-btn1_inner, a {
      &:before {
        background-image: url("../images/common/icon/contact_blue.png");
      }
      &:after {
        background-image: url("../images/common/arw_r_blue.png");
      }
    }
  }

  &.-cyan {  
    .p0-btn1_inner, a {
      &:before {
        background-image: url("../images/common/icon/contact_cyan.png");
      }
      &:after {
        background-image: url("../images/common/arw_r_cyan.png");
      }
    }
  }
  
  &.-movearwlr {
    .p0-btn1_inner, a {
      &:after {
        animation: 1.8s ease-out 0s infinite normal forwards running moveArrowLR;
      }
    }
  }
  
  &_inner, a {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-row;
    @include flex-justify-center;
    @include align-items-center;
    
    &:before {
      position: relative;
      width: 28px;
      height: 23px;
      content: "";
      margin-left: 10px;
      margin-right: 20px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      @extend %hover;
  
      @media all and (min-width: 640px) {
        width: 31px;
        height: 25px;
        margin-left: 20px;
        margin-right: 30px;
      }
    }
  
    &:after {
      position: relative;
      width: 54px;
      height: 10px;
      content: "";
      margin-left: 14px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      @extend %hover;
  
      @media all and (min-width: 640px) {
        margin-left: 22px;
      }
    }
  }
}


.p0-btn2 {
  display: inline-block;
  position: relative;
  width: auto;
//  @include btnsize(340px, 340px);
  @include btn(2);
  box-sizing: border-box;

  &.-w100per {
    width: 100%;
  }

  &.-blue {
    .p0-btn2_inner, a {
      &:after {
        background-image: url("../images/common/arw_r_blue.png");
      }
    }
  }

  &.-cyan {  
    .p0-btn2_inner, a {
      &:after {
        background-image: url("../images/common/arw_r_cyan.png");
      }
    }
  }
  
  &.-bgblue03 {
    background-color: color-blue03(1);
    
    &.-br05 {
      border-radius: 5px;
    }
    
    .p0-btn2_inner, a {
      padding: 8px 14px 9px 14px; // 35px * 0.4 = 14px
      box-sizing: border-box;
      color: color-wh(1);

      @include breakpoint-sp {
        padding-left: 30px; // 50px * 0.6 = 30px
        padding-right: 21px; // 35px * 0.6 = 21px
      }
      @include breakpoint-tb {
        padding-left: 50px;
        padding-right: 35px;
      }

      &:after {
        width: 27px;
        background-image: url("../images/common/arw_r_white.png");

        @include breakpoint-sp {
          width: 54px;
        }
      }
    }
  }
  
  &_inner, a {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-row;
    @include flex-justify-flex-end;
    @include align-items-center;
  
    &:after {
      position: relative;
      width: 54px;
      height: 10px;
      content: "";
      margin-left: 12px;
      background-position: right center;
      background-repeat: no-repeat;
      background-size: auto 100%;
      @extend %hover;
  
      @include breakpoint-mainbox {
        margin-left: 16px;
      }
    }

    &.-movearwright {
      &:after {
        @extend %nohover;
      }

      &:hover {
        transform: translateX(0);

        &:after {
          @include breakpoint-tb {
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
            -webkit-transition-duration: 0.2s;
            transition-duration: 0.2s;
            -webkit-transition-property: transform;
            transition-property: transform;    
            transform: translateX(20px);
          }
        }    
      }
    }

    &.-movearwright2 {
      &:after {
        @extend %nohover;
      }

      &:hover {
        transform: translateX(0);

        &:after {
          @include breakpoint-tb {
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
            -webkit-transition-duration: 0.2s;
            transition-duration: 0.2s;
            -webkit-transition-property: transform;
            transition-property: transform;    
            transform: translateX(4px);
          }
        }    
      }
    }

  }
}

.p0-btn3 {
  display: inline-block;
  position: relative;
  width: auto;
//  @include btnsize(340px, 340px);
  @include btn(3);
  box-sizing: border-box;

  &.-w100per {
    width: 100%;
  }

  &.-blue {
    &:after {
      background-image: url("../images/common/arw_r_blue.png");
    }
  }

  &.-cyan {  
    &:after {
      background-image: url("../images/common/arw_r_cyan.png");
    }
  }
  
  &:after {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 40px;
    transform: translate(0, -50%);
    width: 54px;
    height: 10px;
    content: "";
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    pointer-events: none;
    @extend %hover;
  }

  &.-movearwright {
    &:after {
      @extend %nohover;
    }

    &:hover {
      &:after {
        @include breakpoint-tb {
          -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
          -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
          -webkit-transition-property: transform;
          transition-property: transform;    
          transform: translate(20px, -50%);
        }
      }    
    }
  }

}


.p0-btn4 {
  display: inline-block;
  position: relative;
  width: auto;
//  @include btnsize(340px, 340px);
  @include btn(4);
  box-sizing: border-box;

  &.-w100per {
    width: 100%;
  }

  &.-blue {
    &:after {
      background-image: url("../images/common/arw_r_blue.png");
    }
  }

  &.-cyan {  
    &:after {
      background-image: url("../images/common/arw_r_cyan.png");
    }
  }
  
  &:after {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translate(0, -50%);
    width: 54px;
    height: 10px;
    content: "";
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    pointer-events: none;
    @extend %hover;
  }

  &.-movearwleft {
    &:after {
      transform: translate(0, -50%) scale(-1, 1);
      @extend %nohover;
    }

    &:hover {
      &:after {
        @include breakpoint-tb {
          -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
          -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
          -webkit-transition-property: transform;
          transition-property: transform;    
          transform: translate(-20px, -50%) scale(-1, 1);
        }
      }    
    }
  }

}


///// 横並びボタン /////
.p0-flexbtns {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-column;
  @include flex-justify-flex-start;
  @include align-items-stretch;

  @include breakpoint-pc {
    @include flex-wrap-nowrap;
    @include flex-direction-row;
  }
  
  &.-column {
    @include flex-direction-column;

    @include breakpoint-pc {
      @include flex-direction-column;
    }
  }
  
  &.-left {
    @include flex-justify-flex-start;
  }
  
  &.-center {
    @include flex-justify-center;
    @include align-items-center;
    
    @include breakpoint-pc {
      @include align-items-stretch;
    }
  }
  
  &.-right {
    @include flex-justify-flex-end;
  }
  
  &_item {
    margin-bottom: 20px;
    
    @include breakpoint-pc {
      margin-bottom: 0;
      margin-right: 2vw;
    }
    @include breakpoint-content {
      margin-right: 20px;
    }
    
    &:last-of-type {
      margin-bottom: 0;

      @include breakpoint-pc {
        margin-right: 0;
      }
      @include breakpoint-content {
        margin-right: 0;
      }
    }
  }
}