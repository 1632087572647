.p0-brand {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-row;
  @include flex-justify-flex-start;
  @include align-items-stretch;
  gap: 35px 5vw;
  position: relative;
  width: 100%;
  max-width: 360px;
  margin: 0 auto;

  @include breakpoint-sp {
    gap: 35px 20px;
  }
  @include breakpoint-pc {
    gap: 0 30px;
    max-width: 910px;
//    max-width: 730px;
    margin-left: 0;
  }

  &_item {
    width: calc( 50% - (5vw / 2) ); //5vwのgapが1つあり、1行に2列あるため、5vw / 2をマイナス

    @include breakpoint-sp {
      width: calc( 50% - (20px / 2) ); //20pxのgapが1つあり、1行に2列あるため、20px / 2をマイナス
    }
    @include breakpoint-pc {
      width: calc( 25% - ((30px * 3) / 4) ); //3pxのgapが3つあり、1行に4列あるため、9px / 4をマイナス
    }
  }
  
  &_sitelink {
    display: block;

/*
    .p0-brand_img img, .p0-brand_name {
      filter:alpha(opacity=100);
      opacity:1;
      transition: all 0.3s ease-in-out;
    }
*/
    &:hover, &:focus {
      .p0-brand_name {
        &_txt {
          border-bottom-color: color-wh(1);
        }
      }
/*
      .p0-brand_img img, .p0-brand_name {
        filter:alpha(opacity=70);
        opacity:0.7;
      }
*/
    }
    
    .p0-brand_name {
      &_txt {
        @include flexbox;
        @include flex-wrap-nowrap;
        @include flex-direction-row;
        @include flex-justify-center;
        @include align-items-center;
        border-bottom: 1px solid color-wh(0);
  
        &:after {
          display: inline-block;
          position: relative;
          width: 14px;
          height: 14px;
          content: "";
          margin-left: 6px;
          background-image: url("../images/common/icon/exlink.png");
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
      
          @include breakpoint-pc {
            margin-left: 8px;
          }
        }
      }
    }
  }
  
  &_img {
    margin-bottom: 12px;
//    background-color: color-wh(1);

    @include breakpoint-pc {
      margin-bottom: 15px;
    }
  }
  
  &_name {  
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-row;
    @include flex-justify-center;
    @include align-items-center;

    &_txt {
      padding-bottom: 3px;
      box-sizing: border-box;
      -webkit-transition-timing-function: linear;
      transition-timing-function: linear;
      -webkit-transition-duration: 0.25s;
      transition-duration: 0.25s;
      -webkit-transition-property: border-bottom-color;
      transition-property: border-bottom-color;
    }
  }
  
  &_sns {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-row;
    @include flex-justify-center;
    @include align-items-center;
    gap: 0 14px;

    a {
      display: inline-block;
    }

    img {
      width: auto;
      height: 29px;
    }
  }
}