.p0-business {
  display: block;
  margin-bottom: 160px;
  
  @include breakpoint-tb {
    margin-bottom: 80px;
  }
  @include breakpoint-pc {
    margin-bottom: 130px;
  }
  @include breakpoint-mainbox {
    margin-bottom: 170px;
  }

  &_item {
    display: block;
    width: calc( 100% - #{$front-mv-border-width-sp} );
    max-width: 1400px;
    height: 200px;
    margin: 10px 0 120px auto;
    padding: 0;
    box-sizing: border-box;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 100%;

    @include breakpoint-tb {
      height: 380px;
      margin-top: 30px;
      margin-bottom: 0;
    }
    @include breakpoint-pc {
      width: calc( 100% - #{$front-mv-border-width-pc} );
      height: 502px;
      margin-top: 80px;
    }
    @include breakpoint-mainbox {
      width: calc( 100% - #{$section-content-pad-pc} - #{$section-content-pad-pc} );
      margin-right: auto;
      padding: 0 30px;
    }
    @include breakpoint-max {
      width: $maxinner;
    }

    &:hover {
//      background-color: color-blue03(1);
    }
    
    &:last-of-type {
      margin-bottom: 0;
    }

    &.-b01 {
      .p0-business_box {        
        background-image: url("../images/front/business_bg1_pc.jpg");

        @include breakpoint-tb {
        }
        @include breakpoint-mainbox {
        }
      }
    }
    
    &.-b02 {
      .p0-business_box {        
        background-image: url("../images/front/business_bg2_pc.jpg");
        background-position: left -20px center;

        @include breakpoint-tb {
          background-position: left -30px center;
        }
        @include breakpoint-pc {
          background-position: left center;
        }
      }
    }
    
    &.-b03 {
      .p0-business_box {
        background-image: url("../images/front/business_bg3_pc.jpg");

        @include breakpoint-tb {
        }
        @include breakpoint-pc {
        }
      }
    }

    &.-movearwright {
      &:hover {
        transform: translateX(0);

        .p0-business_more {      
          &:after {
            @extend %nohover;

            @include breakpoint-tb {
              -webkit-transition-timing-function: ease-out;
              transition-timing-function: ease-out;
              -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s;
              -webkit-transition-property: transform;
              transition-property: transform;    
              transform: translateX(20px);
            }
          }
        }
      }
    }
  }
  
  &_box {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 30px 0;
    box-sizing: border-box;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    @include breakpoint-pc {
      padding: 41px 0;
    }
  }

  &_inner {
    position: absolute;
    bottom: 20px;
    left: 0;
    transform: translateY(100%);
    width: calc(100% - #{$front-mv-border-width-sp});
//    height: 105px;
    padding: 15px 0 30px 0;
    box-sizing: border-box;
    background-color: color-wh(1);

    @include breakpoint-tb {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-row-reverse;
      @include flex-justify-flex-start;
      @include align-items-stretch;
      position: relative;
      bottom: auto;
      left: auto;
      transform: translateY(0);
//      width: 200px;
      width: 34vw;
      height: 100%;
      margin: 0 0 0 auto;
      padding-top: 40px;
      padding-right: 3.5vw;
      padding-bottom: 0;
    }
    @include breakpoint-business {
      width: 250px;
      padding-right: 5vw;
    }
    @include breakpoint-pc {
      width: 29vw;
      height: 100%;
      padding-right: 4vw;
    }
    @include breakpoint-mainbox {
      width: 340px;
      padding-right: 60px;
    }
  }
  
  &_num {
    display: inline-block;
    position: absolute !important;
    bottom: 0;
    left: -3.2vw;
    z-index: 1;
    color: color-gray05(0.8);
    letter-spacing: .15rem;
    
    @include breakpoint-tb {
      bottom: auto;
      left: auto;
      top: 0;
      right: 0;
      transform: translateY(-25%);
    }
    @include breakpoint-pc {
      right: 0;
      transform: translateY(-35%);
    }
    @include breakpoint-mainbox {
      right: 10px;
      transform: translateY(-50%);
    }
  }
  
  &_en {
    position: absolute !important;
    top: 0;
    right: 0;
    transform: translate(0, -45%);
    letter-spacing: 0.03em;

    @include breakpoint-tb {
      right: auto;
      top: 20px;
      left: 0;
      transform: translate(-0.48em, 0);
      @include tategaki('sideways');
    }
    @include breakpoint-pc {
      transform: translate(-0.5em, 0);
    }
  }
  
  &_ttl {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-row;
    @include flex-justify-flex-start;
    @include align-items-center;
    position: relative;
    margin-bottom: 10px;

    @include breakpoint-tb {
      margin-bottom: 0;
      @include tategaki('upright');
    }

    &:before {
      position: relative;
      width: 7px;
      height: 13px;
      content: "";
      margin-right: 5px;
      background-image: url("../images/common/tri_r_cyan.png");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
  
      @include breakpoint-tb {
        transform: rotate(90deg);
        width: 9px;
        height: 18px;
        margin-bottom: 2px;
        margin-right: 0;
      }
      @include breakpoint-pc {
        margin-bottom: 12px;
      }
      @include breakpoint-mainbox {
      }
    }
  }
  
  &_txt {
    margin-right: 0;

    @include breakpoint-tb {
      margin-right: 16px;
      @include tategaki('upright');
    }
    @include breakpoint-business {
      margin-right: 25px;
    }
    @include breakpoint-pc {
      margin-right: 28px;
    }
    @include breakpoint-mainbox {
    }
    
    &.-line2 {
      margin-right: 0;

      @include breakpoint-tb {
        margin-right: 16px;
      }
      @include breakpoint-business {
        margin-right: 25px;
      }
      @include breakpoint-pc {
        margin-right: 28px;
      }
    }

    &.-line3 {
      margin-right: 0;

      @include breakpoint-tb {
        margin-right: 10px;
      }
      @include breakpoint-business {
        margin-right: 16px;
      }
      @include breakpoint-pc {
        margin-right: 20px;
      }
    }
    
    & .-lsminus006 {
      letter-spacing: -0.06em;
    }
  }

  &_img {
    
  }
  
  &_more {
    &:after {
      display: inline-block;
      position: absolute;
      bottom: 10px;
      right: 15px;
      width: 54px;
      height: 10px;
      content: "";
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      @extend %hover;

      @include breakpoint-tb {
        bottom: 30px;
        right: 5vw;
      }
      @include breakpoint-pc {
        right: 40px;
      }
      @include breakpoint-mainbox {
        right: 60px;
      }
    }

    &.-blue {
      &:after {
        background-image: url("../images/common/arw_r_blue.png");
      }
    }
  }
  
  ///// ビジネスページの見出し /////
  &_headline {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-row;
    @include flex-justify-flex-start;
    @include align-items-start;
    position: relative;
    
    &.-leftborder {
      .p0-business_headline_num {
        &:before {
          display: block;
          position: absolute;
          top: 50%;
          left: -2vw;
          transform: translate(-100vw, -50%);
          content: "";
          width: 100vw;
          height: 1px;
          background-color: color-blue01(1);

          @include breakpoint-pc {
            left: -20px;
          }
        }
      }
    }

    &_num {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include align-items-center;
      position: relative;
      letter-spacing: .15rem;
  
      &:after {
        position: relative;
        width: 7px;
        height: 13px;
        content: "";
        margin-left: 11px;
        margin-right: 21px;
        background-image: url("../images/common/tri_r_cyan.png");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
    
        @include breakpoint-pc {
          width: 9px;
          height: 18px;
          margin-left: 15px;
          margin-right: 35px;
        }
        @include breakpoint-mainbox {
          left: 0;
          margin-left: 19px;
          margin-right: 37px;
        }
      }
    }
    
    &_txt {
      margin-top: -3px;
    }
    
    &_en {
      margin-bottom: 1px;
      letter-spacing: 2px;

      @include breakpoint-pc {
        margin-bottom: 3px;
      }
    }
    
    &_jp {
      padding-bottom: 2px;
      box-sizing: border-box;

      @include breakpoint-pc {
        padding-bottom: 4px;
      }
    }
  }
  
  //スライドショー
  &_slider {
    padding: 40px 0;
    box-sizing: border-box;

    @include breakpoint-mainbox {
      padding: 80px 0;
    }

    &_item {
      position: relative;
    }
    
    &_img {
      position: relative;
      margin-bottom: 7px;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;

      @include breakpoint-pc {
        margin-bottom: 14px;
      }
    }
    
    &_name {
      margin-bottom: 1px;
    }
    
    &_location {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include align-items-center;

      &:before {
        display: inline-block;
        position: relative;
        width: 7px;
        height: 10px;
        content: "";
        margin-right: 4px;
        background-image: url("../images/common/icon/pin_cyan.png");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;

        @include breakpoint-pc {
          width: 9px;
          height: 13px;
        }
      }
    }
    
    &_sectional {
      display: inline-block;
      position: absolute !important;
      bottom: 0;
      left: 0;
      padding: 3px 7px 4px;
      box-sizing: border-box;
      background-color: color-wh(1);
    }
  }
    
  &_slider_pagination {
    &.swiper-pagination-bullets {
      bottom: 10px !important;
      height: 20px !important;
      
      @include breakpoint-mainbox {
        bottom: 20px !important;
      }

      .swiper-pagination-bullet {
        width: 8px !important;
        height: 8px !important;
        opacity: 1;
        background-color: color-green01(0.28);

        &-active {
          background-color: color-green01(1);
        }
      }
    }
  }
}