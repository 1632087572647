// sub pages settings
$pgList: about,care,flow,online,faq;

//header nav menu
$drawer-space-sp: 100px;
$drawer-space-pc: 80px;

//front main visual border width
$front-mv-border-width-sp: 5vw;
$front-mv-border-width-pc: 40px;
$front-mv-border-width-mb: 30px;

//front main visual border width
$section-content-pad-sp: 5vw;
$section-content-pad-pc: 60px;

//gnav item gap
$gnav-item-gap-sp: 40px;
$gnav-item-gap-pc: 26px;

//topics list gap
$topics-list-gap-sp: 20px;
$topics-list-gap-pc: 40px;
$topics-list-gap-mb: 60px;
