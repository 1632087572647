.p2-ftinfo {
  &_privacy {
    @include font-montserrat("r");
    @include fontSize(1.2, 1);
    text-align: center;

    a {
      color: color-wh(1);
      text-decoration: underline;
    }
  }
}