.p0-hero {
  box-sizing: border-box;

  @include breakpoint-pc {
  }
  
  &_content {
    width: 100%;
    height: 100%;
  }
  
  &_ttl {
    @include font-montserrat("sb");
    font-size: 5.2rem;
    @include lineHeight(1, 1);
    text-align: center;
    color: color-wh(1);
    mix-blend-mode: exclusion;

    @media all and (min-width: 580px) {
      font-size: 9vw;
    }
    @include breakpoint-pc {
      font-size: 8vw;
      letter-spacing: 1px;
    }
    @media all and (min-width: 1050px) {
      font-size: 7.6rem;
    }
  }
  
  /* トップページ */
  &.-front {
    position: relative;
    height: 80vh;
    box-sizing: border-box;
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-property: height, border;
    transition-property: height, border;
    border: 0 solid color-wh(1);

    @include breakpoint-pc {
      height: 840px;
    }
    @include breakpoint-max {
      height: 43.2vw;
    }

    &.js-isscroll {
      $front-mv-border-topwidth-sp: 130px;
      $front-mv-border-topwidth-sp-half: $front-mv-border-topwidth-sp / 2;
      border-width: $front-mv-border-topwidth-sp $front-mv-border-width-sp calc(#{$front-mv-border-width-sp} / 3 * 2) $front-mv-border-width-sp;

      @include breakpoint-pc {
        border-width: $front-mv-border-width-pc $front-mv-border-width-pc 20px $front-mv-border-width-pc;
      }
      @include breakpoint-mainbox {
        border-width: $front-mv-border-width-pc $front-mv-border-width-mb 20px $front-mv-border-width-mb;
      }

      & .p0-hero_bg {
        video {  
          @include breakpoint-max {
            width: 100%;
            height: auto;
          }
        }
      }
    }

    &.js-init {
      height: 100vh;  //全画面はロード時のみ
      border-width: 0 0 0 0;

      @include breakpoint-mainbox {
        border-width: 0 0 0 0;
      }
    }

    & .p0-hero_bg {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;

      @include breakpoint-pc {
      }
      
      video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        height: 100%;
        min-width: 100%;
        min-height: 100%;
      }
    }

    & .p0-hero_content {
      width: 100%;
      height: 100%;

      @include breakpoint-pc {
      }
    }

    & .p0-hero_logo {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      transform: translate(-50%, -50%);
      width: 150px;
      height: auto;
      -webkit-transition-timing-function: ease-in;
      transition-timing-function: ease-in;
      -webkit-transition-duration: 0.5s;
      transition-duration: 0.5s;
      -webkit-transition-property: top;
      transition-property: top;

      @include breakpoint-pc {
        width: 254px;

      }

      &_img {
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    
    & .p0-hero_scroll {
      display: inline-block;
      position: absolute;
      bottom: calc( 30px + ( #{$front-mv-border-width-sp} / 3 * 2 ) );
      left: 50%;
      z-index: 1;
      transform: translate(-50%, 0);
      width: 12px;
      height: auto;
      opacity: 0;  //ファーストビュー時のみ表示する
      -webkit-transition-timing-function: ease-in;
      transition-timing-function: ease-in;
      -webkit-transition-duration: 0.5s;
      transition-duration: 0.5s;
      -webkit-transition-property: opacity;
      transition-property: opacity;

      @include breakpoint-pc {
        bottom: calc( 50px + #{$front-mv-border-width-pc} );
        width: 18px;
      }
      @include breakpoint-mainbox {
        bottom: calc( 48px + #{$front-mv-border-width-mb} );
      }

      &_img {
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    &.js-init {  //ファーストビュー時のみ表示する
      & .p0-hero_scroll {
        opacity: 1;
      }
    }
  }
  
  /* 下層ページ */
  &.-lowerpage {
    @include breakpoint-pc {
    }
      
    .p0-hero_logo {
      display: none;
      position: absolute;
      top: 30px;
      left: 50%;
      transform: translateX(-50%);
      mix-blend-mode: exclusion;

      @include breakpoint-pc {
        display: block;
        top: 40px;
      }

      img {
        width: 82px;
        height: auto;

        @include breakpoint-pc {
          width: 90px;
        }
        @media all and (min-width: 1050px) {
          width: 118px;
        }
      }
    }
    
    & .p0-hero_ttl {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  
  ///// トピックス /////
  &.-topics {
    & .p0-hero_inner {
//      background-image: url("../images/topics/kv_pc.jpg");
    }
  }
  
  ///// カンパニー /////
  &.-company {
    width: 90%;
    height: 357px;  //height: 44.6808510638298vw;で、800px時のheightが357px
    margin: 130px 5vw calc(#{$front-mv-border-width-sp} / 3 * 2);
    box-sizing: border-box;

    @include breakpoint-pc {
      width: calc( 100% - #{$front-mv-border-width-pc} - #{$front-mv-border-width-pc} );
      height: 44.6808510638298vw;
      margin: #{$front-mv-border-width-pc} #{$front-mv-border-width-pc} 20px;
    }
    @include breakpoint-mainbox {
      width: calc( 100% - #{$front-mv-border-width-mb} - #{$front-mv-border-width-mb} );
      margin: #{$front-mv-border-width-mb} #{$front-mv-border-width-mb} 20px;
    }

    & .p0-hero_bg {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
      background-image: url("../images/company/kv_pc.jpg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  ///// ビジネス /////
  &.-business {
    width: 90%;
    height: 357px;  //height: 44.6808510638298vw;で、800px時のheightが357px
    margin: 130px 5vw calc(#{$front-mv-border-width-sp} / 3 * 2);
    box-sizing: border-box;

    @include breakpoint-pc {
      width: calc( 100% - #{$front-mv-border-width-pc} - #{$front-mv-border-width-pc} );
      height: 44.6808510638298vw;
      margin: #{$front-mv-border-width-pc} #{$front-mv-border-width-pc} 20px;
    }
    @include breakpoint-mainbox {
      width: calc( 100% - #{$front-mv-border-width-mb} - #{$front-mv-border-width-mb} );
      margin: #{$front-mv-border-width-mb} #{$front-mv-border-width-mb} 20px;
    }

    & .p0-hero_bg {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
//      height: 0;
//      padding-top: 44.6808510638298%; //840(動画高さ) / 1880(動画幅) * 100
/*
      background-image: url("../images/business/kv_pc.jpg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
*/
      @include breakpoint-pc {
      }
      
      video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        height: 100%;
        min-width: 100%;
        min-height: 100%;
//        width: 100%;
//        height: 100%;
      }
    }
  }


  ///// スライドショー /////
  &_slider {
    display: block;
    position: relative;
    width: 100%;  //全画面表示
    height: 100%;  //全画面表示

    .swiper-container-mv {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    .swiper-wrapper .swiper-slide {
      width: 100%;
      height: 100%;
      overflow: hidden;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }

    .slide-item {
      position: relative;
      width: 100%;
      height: 100%;

      &_inner {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        animation: 9s linear 0s 1 both mvSize;
      }

      &.swiper-slide-active {
        .slide-item_inner {
          animation: 9s linear 0s 1 both mvMove;
        }
      }
    }

    &_dots {
      position: absolute;
      left: 3%;
      bottom: 33px;
      @include breakpoint-pc {
        left: 0;
      }
      & ul {
        width: 10px;
      }
      & li {
        display: block;
        width: 10px;
        height: 10px;
        margin-bottom: 10px;
        border-radius: 100px;
        background: color-wh(.8);
        &:last-child {
          margin-bottom: 0;
        }
      }
      & button {
        width: 10px;
        height: 10px;
        background: color-effect01(.4);
        text-indent: -9999px;
        border-radius: 100px;
      }
      & .slick-active button {
        background: color-effect01(1);
      }
    }
  }
}