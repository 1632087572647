// fadein effect
/* 画面外にいる状態 */
.js-fadeIn {
  opacity : 0.1;
  transform : translate(0, 30px);
  transition : all 1000ms;
}
 
/* 画面内に入った状態 */
.js-fadeIn.js-scrollIn {
  opacity : 1;
  transform : translate(0, 0);
}


////// 画面中央に来たら、左にスライドする /////
.js-moveleft {
  &.js-ismove {
    animation: 0.35s ease-in-out 0s 1 both scrollMoveLeft;

    @include breakpoint-mainbox {
      animation: 0.4s ease-in-out 0s 1 both scrollMoveLeft;
    }
    @include breakpoint-max {
      animation: 0.45s ease-in-out 0s 1 both scrollMoveLeft;
    }
  }
}


////// 最初はテキストを塗りつぶし長方形で覆い隠し、画面中央に来たら塗りつぶし長方形を外す /////
.js-drawwave {
  display: inline-block;
  position: relative;
  padding-left: 3px;
  padding-right: 3px;
  box-sizing: border-box;
  color: color-blue01(0);

  @include breakpoint-pc {
    padding-left: 4px;
    padding-right: 4px;
  }

  &:before {
    display: inline-block;
    position: absolute;
    top: 8%;  //(100% - 84%) / 2
    left: 0%;
    right: 100%;
    content: "";
    height: 84%;
    background-color: color-blue01(1);

    @include breakpoint-pc {
      top: 13%;  //(100% - 74%) / 2
      height: 74%;
    }
  }

  &.-do {
    animation: drawwaveShow 1.5s both;

    &:before {
      animation: drawwaveIntro 1.5s ease-in-out;
    }
  }
}


///// ドロワー /////
@keyframes drawerWrapOut {
  0% {
    height: calc(100vh - #{$drawer-space-sp});

    @include breakpoint-pc {
      height: calc(100vh - #{$drawer-space-pc});
    }
  }
  99% {
    height: calc(100vh - #{$drawer-space-sp});

    @include breakpoint-pc {
      height: calc(100vh - #{$drawer-space-pc});
    }
  }
  100% {
    height: auto;
  }
}

@keyframes drawerIn {
  0% {
    transform: translateY(-100vh);
    background: color-blue01(0);
  }
  1% {
    background: color-blue01(0.95);
  }
  100% {
    background: color-blue01(0.95);
    transform: translateY(0);
  }
}
@keyframes drawerOut {
  0% {
    transform: translateY(0);
    background: color-blue01(0.95);
  }
  99% {
    background: color-blue01(0.95);
  }
  100% {
    background: color-blue01(0);
    transform: translateY(-100vh);
  }
}

@keyframes drawerOutHeaderBgc {
  0% {
    background: color-blue01(0.95);
  }
  99% {
    background: color-blue01(0.95);
  }
  100% {
    background: color-blue01(0);
  }
}


///// メインビジュアル微移動(アクティブスライド用) /////
@keyframes mvMove {
  0% {
    transform: scale(1.04) translateX(0);
  }
  100% {
    transform: scale(1.04) translateX(1%);
  }
}
///// メインビジュアルサイズ調整(非アクティブスライド用) /////
@keyframes mvSize {
  0% {
    transform: scale(1.04);
  }
  100% {
    transform: scale(1.04);
  }
}


///// テキストを流して表示する /////
@keyframes marquee01 {
  0% {
    transform: translate(100%, 0);  //アニメーション開始地点をブラウザ右端にする
  }
  100% {
    transform: translate(-100%, 0);  //アニメーションの終点をブラウザ左端からmarquee01の幅の分だけ左に移動した位置にする
  }
}
@keyframes marquee01Copy {
  0% {
    transform: translate(0, 0);  //アニメーション開始地点をブラウザ右端にする
  }
  100% {
    transform: translate(-200%, 0);  //アニメーションの終点をブラウザ左端からmarquee01の幅の分だけ左に移動した位置にする
  }
}

@keyframes marquee02 {
  0% {
    transform: translate(100%, 0);  //アニメーション開始地点をブラウザ右端にする
  }
  100% {
    transform: translate(-100%, 0);  //アニメーションの終点はをブラウザ左端からmarquee02の幅の分だけ左に移動した位置にする
  }
}
@keyframes marquee02Copy {
  0% {
    transform: translate(0, 0);  //アニメーション開始地点をブラウザ右端にする
  }
  100% {
    transform: translate(-200%, 0);  //アニメーションの終点はをブラウザ左端からmarquee02の幅の分だけ左に移動した位置にする
  }
}

@include breakpoint-pc {
  @keyframes marquee02 {
    0% {
      transform: translate(100%, 0);//アニメーション開始地点をブラウザ右端にする
    }
    100% {
      transform: translate(-100%, 0);  //アニメーションの終点はをブラウザ左端からmarquee02の幅の分だけ左に移動した位置にする
    }
  }
  @keyframes marquee02Copy {
    0% {
      transform: translate(0, 0);//アニメーション開始地点をブラウザ右端にする
    }
    100% {
      transform: translate(-200%, 0);  //アニメーションの終点はをブラウザ左端からmarquee02の幅の分だけ左に移動した位置にする
    }
  }
}


///// スクロール時左移動 /////
@keyframes scrollMoveLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100px);
//    transform: translateX(-50px);

    @include breakpoint-mainbox {
      transform: translateX(-200px);
//      transform: translateX(-100px);
    }
    @include breakpoint-max {
      transform: translateX(-320px);
//      transform: translateX(-160px);
    }
  }
}


///// 矢印が左右移動を繰り返す /////
@keyframes moveArrowLR {
  0% {
    transform: translate(0px, 0px);
    animation-timing-function: ease-out;
  }
  50% {
    transform: translate(20px, 0px);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translate(0px, 0px);
    animation-timing-function: ease-out;
  }
}


///// /////
@keyframes drawwaveIntro {
  0% {
    left:0%;
    right:100%;
  }
  50% {
    left:0%;
    right:0%;
  }
  100% {
    left:100%;
    right:0%;
  }
}

@keyframes drawwaveShow {
  0%, 50% {
    color: color-blue01(0);
  }

  50.00001%, 100% {
    color: color-blue01(1);
  }
}
