.p4-pager {
  @include flexbox;
  @include flex-wrap-nowrap;
  @include flex-justify-center;
  @include align-items-center;
  margin: 0 auto;
  padding: 35px 0 5px;
  box-sizing: border-box;
  @include font-montserrat("m");
  @include fontSize(2.4, 2);
  color: color-blue01(1);
  text-align: center;
  line-height: 1;

  @include breakpoint-pc {
    padding: 70px 0 10px;
  }
  @include breakpoint-mainbox {
    @include flex-justify-flex-start;
  }

  .page-numbers {
    display: block;
    width: 18px;
    margin: 0 5px;
    padding: 5px 0;
    border-bottom: 3px solid transparent;
    box-sizing: border-box;
    color: color-blue01(1);
    @extend %hover;

    @include breakpoint-tb {
      width: 21px;
//      margin: 0 6px;
//      padding: 6px 0;
    }
    @include breakpoint-pc {
//      margin: 0 12px;
    }
    &.current {
      color: color-blue01(1);
      border-bottom-color: color-blue01(1);
      @extend %nohover;
    }
    &.dots {
    }
    &.prev, &.next {
//    &.prev, &.next, &.first, &.last {
      display: block;
      width: 22px;
      height: 17px; // 14px + 3px(border-bottomの高さ)
      margin: 0 5px;
      padding: 0;
      box-sizing: border-box;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      @extend %hover;

      @include breakpoint-pc {
        width: 26px;
      }
    }
    &.prev {
      background-image: url("../images/common/arw_prev.png");
//      transform: rotate(180deg); //nextと同じ画像を左右反転して使いたい場合は、この行を有効にする

      @include breakpoint-mainbox {
        margin-left: 0;
      }
    }
    &.next {
      background-image: url("../images/common/arw_next.png");

      @include breakpoint-mainbox {
        margin-right: 0;
      }
    }
/*
    &.first {
      background-image: url("../images/common/icon_tri_gr.png");
      transform: rotate(180deg); //lastと同じ画像を左右反転して使いたい場合は、この行を有効にする
    }
    &.last {
      background-image: url("../images/common/icon_tri_gr.png");
    }
*/

    &.-dummy {
      display: block;
      
      @include breakpoint-mainbox {
        display: none;
      }
    }
  }
  
  /* pager非表示時(1ページのみの場合) */
  &-nodisp {
    height: 15px;
    @include breakpoint-pc {
      height: 30px;
    }
  }
}