.p0-history {
  position: relative;
  width: 100%;
  margin: -5px auto 65px 0;

  @include breakpoint-pc {
    margin-top: -10px;
    margin-bottom: 130px;
  }

  &_row {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-justify-flex-start;
    @include align-items-stretch;
    position: relative;
    z-index: 1;
    padding: 15px 0;
    box-sizing: border-box;

    @include breakpoint-pc {
      padding: 30px 0;
    }
    
    &:first-of-type {
      .p0-history_y {
        &:before {
          transform: translate(50%, -10px);
          height: calc(100% + 15px + 15px + 10px);

          @include breakpoint-pc {
            height: calc(100% + 30px + 30px + 10px);
          }
        }
      }
    }
    
    &:last-of-type {
      .p0-history_y {
        &:before {
          height: calc(100% + 15px + 15px + 10px);

          @include breakpoint-pc {
            height: calc(100% + 30px + 30px + 20px);
          }
        }
      }
    }
    
    &.-sameyear {
      padding-top: 0;
      
      @include breakpoint-pc {
        padding-top: 0;
      }
      
      .p0-history_y {
        &:after {
          display: none;
        }
      }
    }
    
    &_end {
      display: block;
      width: 100%;
      height: 15px;
      padding: 0;

      @include breakpoint-pc {
        height: 30px;
      }
    }
  }

  &_y {
    @include flex-shrink-0;
    position: relative;
    width: 85px;
    padding: 0 35px 0 0;
    box-sizing: border-box;

    @include breakpoint-pc {
      width: 130px;
      padding: 0 35px 0 0;
    }

    &:before {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, 0);
      content: "";
      width: 3px;
      height: calc(100% + 15px + 15px);
      background-color: color-gray06(1);

      @include breakpoint-pc {
        height: calc(100% + 30px + 30px);
      }
    }
    
    &:after {
      display: block;
      position: absolute;
      top: 4px;
      right: 0;
      transform: translate(50%, 0);
      width: 15px;
      height: 15px;
      content: "";
      border-radius: 50%;
      background-color: color-green01(1);

      @include breakpoint-pc {
        top: 6px;
      }
    }
    
    &_above {
      margin-bottom: 5px;
      white-space: nowrap;
      
      &.-empty {
        margin-bottom: 0;
      }
    }
  }

  &_tag {
    display: inline-block;
    margin-bottom: 10px;
    padding: 4px 5px;
    box-sizing: border-box;
    color: color-wh(1);

    @include breakpoint-pc {
      margin-bottom: 13px;
    }
    
    & + .p0-history_lead {
      margin-top: 0;

      @include breakpoint-pc {
        margin-top: 0;
      }
    }
  }

  &_lead {
    margin-top: -4px;
    margin-bottom: 5px;

    @include breakpoint-pc {
      margin-top: -2px;
      margin-bottom: 7px;
    }
  }

  &_txt {
    width: 100%;
    padding-top: 1px;
    padding-left: 20px;
    box-sizing: border-box;
    line-height: 1;

    @include breakpoint-pc {
      padding-top: 0;
      padding-left: 30px;
    }

    p {
      padding-top: 1px;
      box-sizing: border-box;
    }
  }
}
