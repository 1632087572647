/* トップページのみで使用するCSS */
.p0-front {
  &_maincontent {
    display: block;

    &.js-init {
      display: none;
    }
  }
  
  &_separationbox {
    &_img1 {
      position: relative;
      width: auto;
      height: 130px;

      @include breakpoint-sp {
        height: 34.6vw;  //130px / 375px * 100
      }
      @include breakpoint-pc {
        height: 35.3vw;  //300px / 800px * 100
      }
      @include breakpoint-mainbox {
        height: 480px;
      }

      img {
        position: relative;
        top: -1px;
        left: 50%;
        transform: translateX(-50%);
        width: auto;
        height: calc( 100% + 2px );
      }
    }
  }
}