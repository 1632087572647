.p5-form {
  width: 100%;

  &_input {
    width: 100%;
    margin-bottom: 10px;
    padding: 8px 14px;
    border: 1px solid transparent;
    border-bottom-color: color-blue01(1);
    box-sizing: border-box;
    color: color-black01(1);
    
    @include breakpoint-mainboxhalf {
      padding: 8px 15px;
      margin-bottom: 15px;
    }

    &:focus {
      border-color: color-blue01(.5);
    }

    &.-txt {
      width: 100%;
    }

    &.-txtarea {
      width: 100%;
      height: 300px;
      border-color: color-blue01(1);
    }

    &.-check {
      width: 18px;
      height: 18px;
      margin-left: 3px;
      vertical-align: 3px;
    }
  }
  
  &_select {
    position: relative;
    border: 1px solid color-blue01(1);
    
    &:before {
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      content: "";
      width: 50px;
      height: 100%;
      border-left: 1px solid color-blue01(1);
      pointer-events: none;
    }

    &:after {
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 18px;
      transform: translateY(-50%);
      content: "";
      width: 14px;
      height: 7px;
      pointer-events: none;
      background-image: url("../images/common/tri_down_blue.png");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    
    select {
      width: 100%;
      padding: 14px;
      box-sizing: border-box;
      color: color-black01(1);
      line-height: 1 !important;
    }
  }
  
  &_check {
    .mwform-checkbox-field {
      label {
        @include flexbox;
        @include flex-wrap-nowrap;
        @include flex-direction-row;
        @include flex-justify-flex-start;
        @include align-items-center;
        
        .mwform-checkbox-field-text {
          @include font-ntsan("m");
        }
      }
    }
  }
  
  & .-required {
    color: color-red01(1);
  }

  & .-link {
    margin-bottom: 15px;

    & a {
      width: 100%;
      max-width: 270px;
      display: block;
      line-height: 1;
      text-align: center;
      font-size: 1.5rem;
      font-weight: 500;
      padding: 8px 5%;
      color: color-wh(1);
      background: color-black01(1);
      border-radius: 100px;
      @include font-montserrat("m");
      @include breakpoint-mainboxhalf {
        width: 310px;
        max-width: 310px;
        padding: 10px 20px;
        font-size: 1.7rem;
      }
    }
  }

  &_btn {
    margin-left: 20px;

    @include breakpoint-mainbox {
      margin-left: 70px;
    }
    
    &.-back {
      margin-top: 15px;
      margin-left: -5px;
      
      @include breakpoint-pc {
        margin-top: 25px;
        margin-left: 20px;
      }
      @include breakpoint-mainbox {
        margin-left: 70px;
      }
    }
  }

  &_btns {
    padding-top: 40px;
    padding-bottom: 60px;
    box-sizing: border-box;

    @include breakpoint-tb {
      padding-top: 50px;
      padding-bottom: 80px;
    }
    @include breakpoint-pc {
      padding-bottom: 100px;
    }
    @include breakpoint-mainbox {
      padding-top: 30px;
      padding-bottom: 180px;
    }
  }
}

.mwform-checkbox-field {
  display: block;
  margin-bottom: 5px;

  &text {
    vertical-align: 5px;
    padding-left: 5px;
  }
}

.mwform-checkbox-field {
  line-height: 1;
}

.mw_wp_form .error {
  display: block;
  @include font-ntsan("m");
  font-size: 1.2rem !important;
  color: color-red01(1) !important;

  @include breakpoint-pc {
    font-size: 1.4rem !important;
  }
}


///// 入力ページ /////
.mw_wp_form_input {
  & .p5-form_btn.-back, & .p5-form_btn.-submit, & .p5-form_btn.-top {
    display: none;
  }

  & .p5-form_lead.-confirm {
    display: none;
  }
  & .p5-form_thx {
    display: none;
  }
}


///// 確認(プレビュー)ページ /////
.mw_wp_form_confirm {
  & .p5-form_lastinput {
    margin-bottom: 0 !important;
  }
  
  & .p5-form_btn.-confirm, & .p5-form_btn.-top {
    display: none;
  }

  & .error {
    display: none;
  }
  
  & .p5-form_ttl.u-mb10_half {
    margin-bottom: 0 !important;

    @include breakpoint-pc {
      margin-bottom: 0 !important;
    }
  }
  
  & .p5-form_inputbox, & .p5-form_select {
    padding: 6px 0;
    box-sizing: border-box;
    @include fontSize(1.8, 1.6);
    @include lineHeight(1.8, 1.8);
  }

  & .p3-text.-ttl03 {
    @include innerBox();
  }
  
  & .p5-form_select {
    border-style: none;
    
    &:before, &:after {
      display: none;
    }

    select {
      
    }
  }

  & .p5-form_privacy {
    display: none;
  }

  & .p5-form_lead.-input {
    display: none;
  }
  
  & .p5-form_btns {
    padding-bottom: 30px;

    @include breakpoint-tb {
      padding-bottom: 50px;
    }
    @include breakpoint-pc {
      padding-bottom: 60px;
    }
    @include breakpoint-mainbox {
      padding-bottom: 110px;
    }
  }
}

.mw_wp_form_complete {
  & .p5-form_btn.-back, & .p5-form_btn.-confirm, & .p5-form_btn.xzssxsaazaa-submit {
    display: none;
  }

  & .p5-form_lead.-input, & .p5-form_lead.-confirm {
    display: none;
  }
}