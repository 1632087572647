.p3-box {
  position: relative;
  box-sizing: border-box;


  // トップページの基礎ボックス(タイトル＆文章の組み合わせが入る)
  &.-base {
    width: 100%;
    
    @include breakpoint-mainbox {
//      width: 58.974%; // 920px / 1560px * 100
//      margin-left: 26.666%;
      width: 100%;
      padding-left: 26.666%;
      padding-right: 14.36%;  //100% - 58.974% - 26.666%
      margin-right: auto;
    }

    .p3-box_inner {
      position: relative;
      box-sizing: border-box;
      
      &.-narrow {
        padding-left: 0;

        @include breakpoint-pc {
          padding-left: 4.9382716049383vw;  //80px / 1620px * 100
        }
        @include breakpoint-max {
          padding-left: 80px;
        }
      }
    }
  }


  // 2分割ボックス(1120pxを560pxずつ2分割する)
  &.-divide {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-space-between;
    @include align-items-stretch;
    width: 100%;
    max-width: 1120px;
    margin-left: auto;
    margin-right: auto;
    
    @include breakpoint-mainbox {
    }
    
    .p3-box_half {
      position: relative;
      width: 100%;
      
      @include breakpoint-pc {
        width: 50%;
      }
    }

    .p3-box_inner {
      position: relative;
      box-sizing: border-box;
      
      &.-narrow {
        padding-left: 0;

        @include breakpoint-pc {
          padding-left: 4.9382716049383vw;  //80px / 1620px * 100
        }
        @include breakpoint-max {
          padding-left: 80px;
        }
      }
    }
  }


  // トップページの幅広ボックス
  &.-wide {
    width: 100%;
    
    @include breakpoint-mainbox {
//      width: 65.384%; // 1020px / 1560px * 100
//      margin-left: 26.666%;
      width: 100%;
      padding-left: 26.666%;
      padding-right: 7.95%;  //100% - 65.384% - 26.666%
      margin-right: auto;
    }

    .p3-box_inner {
      position: relative;
      
      &.-narrow {
        padding-left: 0;

        @include breakpoint-pc {
          padding-left: 4.9382716049383vw;  //80px / 1620px * 100
        }
        @include breakpoint-max {
          padding-left: 80px;
        }
      }
    }

  }


  // トップページの最大ボックス
  &.-full {
    width: 100%;
    
    @include breakpoint-mainbox {
//      width: 70.512%; //1100px / 1560px * 100
//      margin-left: 26.666%;
      width: 100%;
      padding-left: 26.666%;
      padding-right: 2.822%;  //100% - 70.512% - 26.666%
      margin-right: auto;
    }

    .p3-box_inner {
      position: relative;
    }
    
    // トップページのCompany部分
    &.-company {
//      padding-left: 26.666%;
      padding: 40px 0 0 0;
      box-sizing: border-box;

      @include breakpoint-company {
        padding: 50px 0 0 0;
//        border-radius: 0 0 0 100px;
      }
      @include breakpoint-pc {
        padding: 50px 0 0 0;
//        border-radius: 0 0 0 100px;
      }
      @include breakpoint-mainbox {
        padding: 130px 0 0 26.666%;
      }
      
      .p3-box_bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 0;
        background-color: color-blue02(1);
        border-radius: 0;
        box-sizing: border-box;

        @include breakpoint-company {
          height: 258px;
          border-radius: 0 0 0 100px;
        }
        @media all and (min-width: 700px) {
          height: 270px;
        }
        @include breakpoint-pc {
          height: 300px;
        }
        @include breakpoint-content {
          height: 300px;
        }
        @include breakpoint-mainbox {
          margin-left: 26.666%;
          height: 430px;
        }
      }

      .p3-box_inner {
        padding-left: 5vw;
        padding-right: 5vw;

        @include breakpoint-tb {
          padding-right: 0;
        }
        @include breakpoint-pc {
          padding-left: 4.9382716049383vw;  //80px / 1620px * 100
        }
        @include breakpoint-max {
          padding-left: 80px;
        }
      }
    }

    &.-companylead {
      padding: 0;
      box-sizing: border-box;

      @include breakpoint-mainbox {
        padding-left: 26.666%;
      }

      .p3-box_inner {
        padding-left: 5vw;
        padding-right: 5vw;

        @include breakpoint-tb {
          padding-right: 0;
        }
        @include breakpoint-pc {
          padding-left: 4.9382716049383vw;  //80px / 1620px * 100
        }
        @include breakpoint-max {
          padding-left: 80px;
        }
      }
    }

    // CompanyページのGREETINGS部分
    &.-greetings {
//      padding-left: 26.666%;
//      padding: 40px 0 0 0;
      padding: 100px 0 0 0;
      box-sizing: border-box;

      @include breakpoint-company {
//        padding: 50px 0 0 0;
        padding: 110px 0 0 0;
//        border-radius: 0 0 0 100px;
      }
      @media all and (min-width: 700px) {
        padding: 110px 0 0 0;
      }
      @include breakpoint-pc {
//        padding: 50px 0 0 0;
        padding: 250px 0 0 0;
//        border-radius: 0 0 0 100px;
      }
      @include breakpoint-mainbox {
//        padding: 130px 0 0 26.666%;
        padding: 250px 0 0 26.666%;
      }
      
      .p3-box_bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 0;
        background-color: color-blue02(1);
        border-radius: 0;
        box-sizing: border-box;

        @include breakpoint-company {
//          height: 258px;
          height: 320px;
          border-radius: 0 0 0 100px;
        }
        @media all and (min-width: 700px) {
//          height: 270px;
          height: 330px;
        }
        @include breakpoint-pc {
//          height: 300px;
          height: 500px;
        }
        @include breakpoint-content {
//          height: 300px;
          height: 500px;
        }
        @include breakpoint-mainbox {
          margin-left: 26.666%;
//          height: 430px;
          height: 550px;
        }
      }

      .p3-box_inner {
        padding-left: 5vw;
        padding-right: 5vw;

        @include breakpoint-tb {
          padding-right: 0;
        }
        @include breakpoint-pc {
          padding-left: 4.9382716049383vw;  //80px / 1620px * 100
        }
        @include breakpoint-max {
          padding-left: 80px;
        }
      }
    }
  }
    
  
  //幅広画像ボックス
  &.-wideimg {
    transform: translateX(-5vw);
    width: 100vw;
    
    @include breakpoint-pc {
      transform: translateX(0);
      width: calc(100% + #{$front-mv-border-width-pc});
    }
    @include breakpoint-mainbox {
//      width: calc(73.333% + #{$section-content-pad-pc});
//      margin-left: 26.666%;
      width: 100%;
      padding-left: 26.666%;
      padding-right: 0;
      margin-right: auto;
    }

    .p3-box_inner {
      position: relative;
    }
  }
}