.p0-marquee {
  ///// 流れて表示される文字 /////
  display: flex;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100vw;
  height: auto;
//  width: 100vw;
//  height: 100%;
//  transform: translateX(-50%);

  &_txt {
    flex: 0 0 auto;

    ///// マーキーテキスト /////
    &.-marquee01 {
      position: relative;
      animation: 24s marquee01 -12s linear infinite;  //テキストを流して表示する。24秒かけて移動する。奇数番目は12秒前にずらす
      white-space: nowrap;

      &:nth-child(odd) {
        @include breakpoint-pc {
          animation-duration: 30s;
          animation-delay: -15s;
        }
        @include breakpoint-max {
          animation-duration: 36s;
          animation-delay: -18s;
        }
      }
      &:nth-child(even) {
        animation: 24s marquee01Copy linear infinite; //テキストを流して表示する。24秒かけて移動する。12秒ずらして流す

        @include breakpoint-pc {
          animation-duration: 30s;
        }
        @include breakpoint-max {
          animation-duration: 36s;
        }
      }
    }
  
    &.-marquee02 {
      position: relative;
      animation: 24s marquee02 -12s linear infinite;  //テキストを流して表示する。24秒かけて移動する。奇数番目は12秒前にずらす
      white-space: nowrap;

      &:nth-child(odd) {
        @include breakpoint-pc {
          animation-duration: 30s;
          animation-delay: -15s;
        }
        @include breakpoint-max {
          animation-duration: 36s;
          animation-delay: -18s;
        }
      }
      &:nth-child(even) {
        animation: 24s marquee02Copy linear infinite; //テキストを流して表示する。24秒かけて移動する。12秒ずらして流す

        @include breakpoint-pc {
          animation-duration: 30s;
        }
        @include breakpoint-max {
          animation-duration: 36s;
        }
      }
    }
  }
}