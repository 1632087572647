.p0-ceo {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-column;
  @include flex-justify-flex-start;
  @include align-items-center;
  position: relative;
  width: 100%;
  padding-bottom: 30px;
  box-sizing: border-box;

  @include breakpoint-company {
    @include flex-direction-row;
    @include align-items-stretch;
  }
  @include breakpoint-pc {
    padding-bottom: 0;
  }
  @include breakpoint-mainbox {
  }

  &_img {
    width: 300px;
    max-width: 100%;

    @include breakpoint-sp {
      max-width: none;
    }
    @include breakpoint-company {
      width: 37.5vw;
    }
    @include breakpoint-pc {
      width: 300px;
    }
  }

  &_txt {
    width: 300px;
    max-width: 100%;
    padding-left: 0;
    box-sizing: border-box;

    @include breakpoint-sp {
      max-width: none;
    }
    @include breakpoint-company {
      width: calc( 100% - 300px );
      padding-left: 20px;
    }
    @include breakpoint-pc {
      padding-left: 25px;
    }
    @include breakpoint-mainbox {
      padding-left: 2.4691358024691vw;  //40px * 1620px * 100
    }
    @include breakpoint-max {
      padding-left: 40px;
    }
    
    &_head {
      position: relative;
      transform: translateX(-11px);
      margin-top: -46px;
      margin-bottom: 20px;

      @include breakpoint-company {
        transform: translateX(0);
        width: auto;
        margin-top: 0;
        margin-bottom: 40px;
      }
      @media all and (min-width: 700px) {
        margin-bottom: 60px;
      }
      @include breakpoint-pc {
        margin-bottom: 80px;
      }
    }
    
    &_body {
      transform: translateX(0);
      padding-left: 0;
      box-sizing: border-box;
  
      @include breakpoint-company {
        transform: translateX(0);
        padding-left: 23px;
      }
      @include breakpoint-pc {
        padding-left: 25px;
      }
      @include breakpoint-mainbox {
        padding-left: 25px;
      }
      @include breakpoint-max {
        padding-left: 25px;
      }
    }

    &_greeting {
      width: 100%;
      padding-top: 15px;
      box-sizing: border-box;

      img {
        width: 193px;
        height: auto;
        
        @include breakpoint-company {
          width: 193px;
        }
        @include breakpoint-pc {
          width: 219px;
        }
      }
    }
    
    &_ttl {
      position: absolute;
      bottom: 10px;
      left: 100px;
      padding: 4px 13px 4px 17px;
      box-sizing: border-box;
      @include font-dnpshumin-pr6("b");
      @include fontSize(1.8, 1.5);
      color: color-blue01(1);
      line-height: 1;
      letter-spacing: 6px;
      background-color: color-green01(1);

      @include breakpoint-company {
      }
      @include breakpoint-pc {
        bottom: 3px;
        left: 95px;
        padding: 4px 16px 4px 22px;
      }
      @include breakpoint-mainbox {
        bottom: 0;
      }
    }
    
    &_cnamejp {
      margin-bottom: 5px;
      @include fontSize(1.5, 1.3);
      color: color-wh(1);

      @include breakpoint-company {
        color: color-blue01(1);
      }
      @include breakpoint-pc {
        margin-bottom: 8px;
        color: color-blue01(1);
      }
    }

    &_cnameen {
      margin-bottom: 10px;
      background-image: url("../images/front/company_name_en_wh.png");
      background-position: left top;
      background-repeat: no-repeat;
      background-size: 165px auto;

      @include breakpoint-company {
        background-image: url("../images/front/company_name_en_bl.png");
        background-size: 165px auto;
      }
      @include breakpoint-pc {
        background-size: 187px auto;
      }

      img {
        width: 165px;
        opacity: 0;
        
        @include breakpoint-company {
          width: 165px;
        }
        @include breakpoint-pc {
          width: 187px;
        }
      }
    }
    
    &_name {
      @include font-dnpshumin-pr6("b");
      @include fontSize(3, 2.4);
      color: color-wh(1);
      letter-spacing: 1px;

      @include breakpoint-company {
        color: color-blue01(1);
      }
      @include breakpoint-pc {
        color: color-blue01(1);
      }
      
      span {
        @include font-montserrat("sb");
        @include fontSize(1.5, 1.3);
        line-height: 1;
        letter-spacing: 0.5px;
        color: color-green01(1);

        &:before {
          @include breakpoint-company {
            content: "\a";
            white-space: pre;
          }
          @include breakpoint-pc {
            content: "";
            white-space: normal;
          }
        }
      }
    }
  }

}