$separatebox-gap-pc: 40px;
$separatebox-gap-sp: 5vw;

.p0-separatebox {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-row;
  @include flex-justify-space-between;
  @include align-items-stretch;
  gap: $separatebox-gap-sp;
  position: relative;
  width: 100%;

  @include breakpoint-pc {
    gap: $separatebox-gap-pc;
  }

  &_item {
    width: calc(50% - (#{$separatebox-gap-sp} / 2));

    @include breakpoint-pc {
      width: calc(50% - (#{$separatebox-gap-pc} / 2));
    }
  }
}