.p0-info {
  padding: 22px 0 15px;
  border-bottom: 1px solid color-gray02(1);
  box-sizing: border-box;

  @include breakpoint-pc {
    padding: 27px 0 20px;
  }
  
  &:first-of-type {
    margin-top: 20px;
    border-top: 1px solid color-gray02(1);

    @include breakpoint-pc {
      margin-top: 0;
      border-top-style: none;
    }
  }
}