.l-header {
  position: relative;
  top: 0;
  left: auto;
  z-index: 1000;
  width: 100%;
  height: $drawer-space-sp;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;

  @include breakpoint-pc {
    position: absolute;
    width: 100%;
//    width: calc(100% - #{$front-mv-border-width-pc} - #{$front-mv-border-width-pc});
    height: $drawer-space-pc;
    background-image: none;
    mix-blend-mode: exclusion;
  }
  @include breakpoint-max {
    width: $maxinner;
  }
  
  &.js-isscroll {
    position: fixed;
    top: 0;

    @include breakpoint-pc {
      width: calc(100% - #{$front-mv-border-width-pc} - #{$front-mv-border-width-pc});
    }
    @include breakpoint-mainbox {
      width: calc(100% - #{$front-mv-border-width-mb} - #{$front-mv-border-width-mb});
    }
    @include breakpoint-max {
      width: $maxinner;
    }
  }
  &.js-noscroll {
    position: relative;
    top: 0;

    @include breakpoint-pc {
    }
  }

  &.js-isopen {
    background: color-blue01(0.95);

    @include breakpoint-pc {
      background: none;
    }
  }
  
  &.js-isclose {  
    animation: 0.3s ease-in 0s 1 both drawerOutHeaderBgc;

    @include breakpoint-pc {
      animation: none;
    }
  }

  // headerの入れ物
  &_wrap {
    position: relative;
    overflow: visible;
    width: 100%;
    height: 0;
    margin: 0 auto;

    @include breakpoint-pc {
      width: calc(100% - #{$front-mv-border-width-pc} - #{$front-mv-border-width-pc});
      height: $drawer-space-pc;
    }
    @include breakpoint-mainbox {
      width: calc(100% - #{$front-mv-border-width-mb} - #{$front-mv-border-width-mb});
    }
    @include breakpoint-max {
      width: $maxinner;
    }
  }
  
  &_inner {
    width: 100%;
    height: 100%;
    max-width: $max;  //ヘッダーグロナビの中身の最大横幅
    margin: 0 auto;
  }
}