.p1-drawer {
//  display: block;
  @include flexbox;
  @include flex-wrap-nowrap;
  @include flex-direction-column;
  @include flex-justify-space-between;
  @include align-items-stretch;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  transform: translateY(-100vh);

  @include breakpoint-pc {
    position: relative;
    height: $drawer-space-pc;
    transform: translateY(0) !important;
  }
  @include breakpoint-content {
    height: $drawer-space-pc;
  }
  
  &.js-isopen {
    animation: 0.6s ease-out 0s 1 both drawerIn;
//    background: color-black01(0.95);
//    transform: translateY(0);
//    transition: all linear;
  }
  
  &.js-isclose {
    background-color: red;
    animation: 0.4s ease-in 0s 1 both drawerOut;
  }
  
  // ドロワーの入れ物
  &-wrap {
    position: absolute;
    top: $drawer-space-sp;
    left: 0;
    width: 100%;
    overflow: hidden;

    @include breakpoint-pc {
      position: relative;
      top: 0;
      height: $drawer-space-pc;
    }

    &.js-isopen {
      height: calc(100vh - #{$drawer-space-sp});

      @include breakpoint-pc {
        height: calc(100vh - #{$drawer-space-pc});
      }
    }
    
    &.js-isclose {  
      animation: 0.3s ease-in 0s 1 both drawerWrapOut;
    }
  }

  ///// ハンバーガーアイコンの設置スペース /////
  &-open {
    @include flexbox;
    @include flex-justify-center;
    @include align-items-center;
    position: fixed;
    top: 29px;
    right: 5vw;
    z-index: 1200;/* 重なり順を一番上に */
    width: 40px;
    height: 34px;
    box-sizing: border-box;
    cursor: pointer;
    mix-blend-mode: exclusion;

    @include breakpoint-pc {
      display: none;
    }
    
    &.js-init {
      display: none;
    }

    ///// ハンバーガーメニューのアイコン /////
    & span, & span:before, & span:after {
      display: block;
      position: absolute;
      left: 0;
      height: 2px;
      width: 40px;
      content: '';
//      border-radius: 3px;
      background-color: color-yellow01(1);
      transition: 0.5s;
    }

    & span {
      width: 25px;
      
      &::before {
        bottom: 14px;
        transform-origin: top left;
      }
      &::after {
        top: 14px;
        transform-origin: bottom left;
      }
    }
    
    //オープン時(×ボタン)
    &.js-isopen {
      span {
        background-color: color-black01(0);
      }
      
      span::before {
        transform: translate(6px, 1px) rotate(40.36deg);
      }
      span::after {
        transform: translate(6px, -1px) rotate(-40.36deg);
      }
    }
  }
}

