.p0-bnrs {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-row;
  @include flex-justify-space-between;
  @include align-items-stretch;
  width: calc( 100% - #{$front-mv-border-width-sp} );
  max-width: 1400px;
  margin-left: auto;
  margin-right: 0;
  padding: 0;
  
  @include breakpoint-pc {
    @include flex-row-reverse;
    width: calc( 100% - #{$front-mv-border-width-pc} - #{$front-mv-border-width-pc} );
    margin-right: auto;
  }
  @include breakpoint-mainbox {
    width: calc( 100% - #{$section-content-pad-pc} - #{$section-content-pad-pc} );
    padding: 0 30px;
  }
  @include breakpoint-max {
    width: $maxinner;
  }
  
  @include breakpoint-tb {
  }
  @include breakpoint-pc {
  }
  @include breakpoint-mainbox {
  }

  &_item {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-column;
    @include flex-justify-flex-end;
    @include align-items-stretch;
    position: relative;
    width: 100%;
    height: 200px;
    margin: 10px 0 120px auto;
    padding: 0;
    box-sizing: border-box;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;

    @include breakpoint-tb {
      display: block;
      height: 380px;
      margin-top: 30px;
      margin-bottom: 0;
    }
    @include breakpoint-pc {
      width: calc(50% - 20px);
      height: auto;
      margin-top: 0;
      margin-left: 0;
    }
    @include breakpoint-mainbox {
    }
    @include breakpoint-max {
    }

    &:hover {
    }
    
    &:first-of-type {
      margin-top: 0;
    }

    &.-bnr01 {
      background-image: url("../images/front/company_info_bg.jpg");
    }
    
    &.-bnr02 {
      background-image: url("../images/front/company_history_bg.jpg");
    }

    &.-movearwright {
      &:hover {
        transform: translateX(0);

        .p0-bnrs_more {      
          &:after {
            @extend %nohover;

            @include breakpoint-tb {
              -webkit-transition-timing-function: ease-out;
              transition-timing-function: ease-out;
              -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s;
              -webkit-transition-property: transform;
              transition-property: transform;    
              transform: translateX(20px);
            }
          }
        }
      }
    }
  }
  
  &_box {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 30px 0;
    box-sizing: border-box;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    @include breakpoint-pc {
      padding: 41px 0;
    }
  }

  &_inner {
    position: absolute;
    bottom: 20px;
    left: 0;
    transform: translateY(100%);
    width: calc(100% - #{$front-mv-border-width-sp});
//    height: 105px;
    padding: 15px 0 30px 0;
    box-sizing: border-box;
    background-color: color-wh(1);

    @include breakpoint-tb {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-row-reverse;
      @include flex-justify-flex-start;
      @include align-items-stretch;
      position: relative;
      bottom: auto;
      left: auto;
      transform: translateY(0);
//      width: 200px;
      width: 34vw;
      height: 100%;
      margin: 0 0 0 auto;
      padding-top: 40px;
      padding-right: 3.5vw;
      padding-bottom: 0;
    }
    @include breakpoint-business {
      width: 250px;
      padding-right: 5vw;
    }
    @include breakpoint-pc {
      width: 29vw;
      height: 100%;
      padding-right: 4vw;
    }
    @include breakpoint-mainbox {
      width: 340px;
      padding-right: 60px;
    }
  }
  
  &_bg {
    display: none;

    @include breakpoint-pc {
      display: block;
    }
  }
  
  &_ttl {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-row;
    @include flex-justify-flex-start;
    @include align-items-center;
    position: relative;
    transform: translateY(90px);
    width: calc(100% - #{$front-mv-border-width-sp} );
    padding: 15px 0 10px;
    box-sizing: border-box;
    background-color: color-wh(1);

    @include breakpoint-tb {
      position: absolute;
      top: 0;
      right: 5vw;
      transform: translateY(0);
      width: auto;
      padding: 0 14px 30px 14px;
      @include tategaki('sideways');
    }
    @include breakpoint-pc {
      right: 20px;
    }

    &:before {
      position: relative;
      width: 7px;
      height: 13px;
      content: "";
      margin-right: 5px;
      background-image: url("../images/common/tri_r_cyan.png");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
  
      @include breakpoint-tb {
        transform: rotate(90deg);
        width: 9px;
        height: 18px;
        margin-bottom: 2px;
        margin-right: 0;
      }
      @include breakpoint-pc {
        margin-bottom: 12px;
      }
      @include breakpoint-mainbox {
      }
    }
  }
  
  &_txt {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-row;
    @include flex-justify-space-between;
    @include align-items-center;
    position: relative;
    transform: translateY(90px);
    width: calc(100% - #{$front-mv-border-width-sp} );
    padding: 0 0 30px 0;
    box-sizing: border-box;
    background-color: color-wh(1);

    @include breakpoint-tb {
      @include flex-justify-flex-start;
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateY(0);
      width: auto;
      padding: 10px 20px 10px 30px;
    }
    @include breakpoint-business {
    }
    @include breakpoint-pc {
    }
    @include breakpoint-mainbox {
    }
  }

  &_img {
    
  }
  
  &_more {
    &:after {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include align-items-center;
      position: absolute;
      bottom: 10px;
      right: 15px;
      width: 54px;
      height: 10px;
      content: "";
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      @extend %hover;

      @include breakpoint-tb {
        position: relative;
        bottom: auto;
        margin-left: 30px;
      }
      @include breakpoint-pc {
      }
      @include breakpoint-mainbox {
      }
    }

    &.-blue {
      &:after {
        background-image: url("../images/common/arw_r_blue.png");
      }
    }
  }
}