.p3-headline {
  position: relative;
  text-align: left;
  color: color-black01(1);

  // テキスト寄せ
  &.-center {
    text-align: center;
  }


  // 英語テキスト見出し
  &.-ttl01 {
    margin-top: -0.1em;
    margin-bottom: -0.1em;
    @include font-montserrat("bd");
    font-size: 3.8rem;
    @include lineHeight(1, 1);
    color: color-blue01(1);
    letter-spacing: 0;

    @include breakpoint-sp {
      font-size: 4.2rem;
      letter-spacing: 1px;
    }
    @include breakpoint-contenthalf {
      font-size: 8.6vw;
    }
    @media all and (min-width: 640px) {
      font-size: 5.6rem;
    }
    @include breakpoint-pc {
      font-size: 5.6rem;
      letter-spacing: 1px;
    }
    
    &.-nextsubheadline {
      margin-bottom: 0;
      
      @include breakpoint-business {
        margin-bottom: 0.05em;
      }
    }

    &.-wh {
      color: color-wh(1);
    }
    
    &.-company {
      text-align: center;

      @include breakpoint-company {
        text-align: left;
      }
    }
    
    &.-ajustx01 {
      transform: translateX(-0.1em);
    }
    
    //トップページのINFORMATIONのスマホ時表示調整
    &.-informationsp {
      margin-top: -25px;

      @include breakpoint-contenthalf {
        margin-top: -5.4vw;
      }
      @media all and (min-width: 640px) {
        margin-top: -34px;
      }
    }
  }
  
  // 英語画像見出し(サイズ大)
  &.-ttl02 {
    img {
      position: relative;
      width: auto;
      height: 74px;
      left: 10px;

      @include breakpoint-sp {
        height: 100px;
      }
      @include breakpoint-tb {
        height: 120px;
      }
      @include breakpoint-pc {
        left: 10px;
        height: 146px;
      }
      @include breakpoint-mainbox {
        left: -26.666vw;
        height: 218px;
      }
      @include breakpoint-max {
        left: -340px;
      }
    }
    
    //-ttl01見出しは、-posxでそれぞれ位置を指定する
    // VISION
    &.-pos1 {
      position: absolute;
      bottom: -50px;

      @include breakpoint-pc {
        bottom: -60px;
      }
    }

    // BUSINESS
    &.-pos2 {
      position: absolute;
      bottom: -40px;

      @include breakpoint-pc {
        bottom: -75px;
      }
      @include breakpoint-mainbox {
        bottom: -115px;
      }
    }

    // BRAND
    &.-pos3 {
      position: absolute;
      bottom: -80px;

      @include breakpoint-pc {
        bottom: -100px;
      }
      @include breakpoint-mainbox {
        bottom: -200px;
      }

      img {
        height: 72px;

        @include breakpoint-pc {
          height: 114px;
        }
        @include breakpoint-mainbox {
          height: 142px;
        }
      }
    }

    // トップページのCOMPANY
    &.-pos4 {
      position: absolute;
      z-index: -1;
      bottom: -70px;

      @include breakpoint-pc {
        bottom: -130px;
      }
      @include breakpoint-mainbox {
        bottom: -140px;
      }

      img {
        height: 74px;

        @include breakpoint-pc {
          height: 117px;
        }
        @include breakpoint-mainbox {
          height: 146px;
        }
      }
    }

    // COMPANYページのCOMPANY
    &.-pos5 {
      position: absolute;
      top: -20px;

      @include breakpoint-pc {
        top: -30px;
      }
      @include breakpoint-mainbox {
        top: auto;
        bottom: -40px;
      }
    }
  }
  
  // 英語画像見出し(サイズ中)
  &.-ttl03 {
    img {
      width: auto;
      height: 142px;
    }
  }
  
  // 日本語見出し1
  &.-ttl04 {
    @include font-dnpshumin-pr6("m");
    font-size: 3.2rem;
    @include lineHeight(1.6, 1.6);
    color: color-blue01(1);
    letter-spacing: 1px;

    @include breakpoint-tb {
      font-size: 3.7rem;
    }
    @include breakpoint-pc {
      font-size: 4.6rem;
    }
    @include breakpoint-mainbox {
      font-size: 5.6rem;
    }
  }
  
  // 日本語見出し2
  &.-ttl05 {
    @include font-dnpshumin-pr6("m");
    font-size: 2.8rem;
    @include lineHeight(1.6, 1.4);
    color: color-blue01(1);
    letter-spacing: 1px;

    @include breakpoint-pc {
      font-size: 4.2rem;
    }
    @include breakpoint-maxinner {
      font-size: 4.6rem;
    }
  }
  
  &.-ttl06 {
    @include font-ntsan("b");
    @include fontSize(2.6, 2);
    @include lineHeight(1.7, 1.6);
    letter-spacing: 1px;
  }
  
  // TOPICS記事ページ見出し
  &.-ttl07 {
    @include font-dnpshumin-pr6("m");
    @include fontSize(4.6, 3.2);
    @include lineHeight(1.6, 1.6);
    color: color-blue01(1);
    letter-spacing: 1px;
  }
  
  // TOPICSカテゴリページのカテゴリ名見出し
  &.-ttl08 {
    @include font-dnpshumin-pr6("m");
    @include fontSize(3, 2.3);
    @include lineHeight(1.8, 1.6);
    color: color-blue01(1);
    letter-spacing: 1px;
  }

  
  // TOPICSカテゴリ一覧の見出し
  &.-ttl09 {
    @include font-montserrat("sb");
    @include fontSize(2, 1.8);
    @include lineHeight(1, 1);
    color: color-blue01(1);
  }

}