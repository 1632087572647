.p3-img {
  // トップページのINFORMATION写真
  &.-i01 {
    position: relative;
    top: 0;
    left: -5vw;
    width: 100vw;
    height: 250px;
    
    @include breakpoint-sp {
      height: 66.6666666666667vw; //250px / 375px * 100
    }
    @include breakpoint-pc {
      position: absolute;
      width: 50vw;
      height: 100%;
      left: auto;
      right: 0;
    }
    @include breakpoint-max {
      width: 820px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center 30%;

      @include breakpoint-pc {
        object-position: right top;
      }
    }
  }
  
  // ビジネスページのagritarioロゴ
  &.-i02 {
    position: relative;
    text-align: center;

    img {
      width: 224px; //281px * 0.8
      height: auto;
      
      @include breakpoint-pc {
        width: 281px;
      }
    }
  }
  
  // ビジネスページのライフスタイルの写真(-i03、-i04)
  &.-i03, &.-i04 {
    position: relative;
    text-align: center;

    img {
      width: 100%;
      height: auto;
    }
  }
}