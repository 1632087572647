.p0-tocontact {
  position: relative;

  //左右の波部分
  &_left, &_right {
    display: inline-block;
    position: absolute;
    bottom: 0;
    height: 100px;
    
    @include breakpoint-sp {
      height: 111px;
    }
    @include breakpoint-tb {
      height: 136px;  //111px + ( (161px - 111px ) / 2)
    }
    @include breakpoint-mainboxhalf {
      height: 136px;
    }
    @include breakpoint-pc {
      height: 161px;
    }
    @include breakpoint-mainbox {
      height: 233px;  //161px + ( (305px - 161px ) / 2)
    }
    @media all and (min-width: 1300px) {
      height: 305px;
    }
    @include breakpoint-maxinner {
    }
    
    img {
      width: auto;
      height: 100%;
    }
  }

  &_left {
    left: 0;
  }
  &_right {
    right: 0;
  }

  //左右の帯
  &:before, &:after {
    display: block;
    position: absolute;
    bottom: 0;
    content: "";
    width: 100vw;
    height: 100px;
    background-color: color-blue03(1);
    
    
    @include breakpoint-sp {
      height: 111px;
    }
    @include breakpoint-tb {
      height: 136px;  //111px + ( (161px - 111px ) / 2)
    }
    @include breakpoint-mainboxhalf {
      height: 136px;
    }
    @include breakpoint-pc {
      height: 161px;
    }
    @include breakpoint-mainbox {
      height: 233px;  //(305px - 161px ) / 2
    }
    @media all and (min-width: 1300px) {
      height: 305px;
    }
    @include breakpoint-maxinner {
    }
  }
  &:before {
    left: 0;
    transform: translateX(-100%);
  }
  &:after {
    right: 0;
    transform: translateX(100%);
  }
  
  //お問い合わせへのリンクアイコン
  &_box {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-center;
    @include align-items-stretch;
    gap: 0 0;
    margin-top: -5px;
    padding-bottom: 40px;
    box-sizing: border-box;

    @include breakpoint-mainboxhalf {
      gap: 0 5px;
    }
    @media all and (min-width: 680px) {
      gap: 0 10px;
    }
    @include breakpoint-pc {
      gap: 0 10px;
      padding-bottom: 30px;
    }
    @media all and (min-width: 850px) {
      gap: 0 20px;
    }
    @include breakpoint-mainbox {
      gap: 0 35px;
      padding-bottom: 50px;
    }
    @media all and (min-width: 1300px) {
      gap: 0 50px;
      padding-bottom: 70px;
    }
    @include breakpoint-maxinner {
    }

  }
  
  &_item {
    width: 100px;
    box-sizing: border-box;
    
    &:nth-of-type(1), &:nth-of-type(3) {
      padding-top: 0;

      @include breakpoint-pc {
        padding-top: 60px;
      }
    }
    
    &:nth-of-type(1) {
      transform: translateX(40px);
      
      @include breakpoint-mainboxhalf {
        transform: translateX(0);
      }
    }

    &:nth-of-type(2) {
      padding-top: 105px;

      @include breakpoint-sp {
//        padding-top: 80px;
      }
      @include breakpoint-mainboxhalf {
        padding-top: 40px;
      }
      @include breakpoint-pc {
        padding-top: 0;
      }
    }

    &:nth-of-type(3) {
      transform: translateX(-40px);
      
      @include breakpoint-mainboxhalf {
        transform: translateX(0);
      }
    }

    @media all and (min-width: 340px) {
      width: 110px;
    }
    @media all and (min-width: 680px) {
      width: 130px;
    }
    @include breakpoint-pc {
      width: 150px;
    }
    @include breakpoint-mainbox {
      width: 165px;
    }
    @media all and (min-width: 1300px) {
      width: 180px;
    }
    @include breakpoint-maxinner {
    }
  }
  
  ///// お問い合わせページの確認・送信ボタン /////
  &.-contactform {
    //左右の波部分
    .p0-tocontact_left, .p0-tocontact_right {
      display: none;
      
      @include breakpoint-tb {
        display: inline-block;
      }
    }
  }
}
