.p0-topics {
  ///// お知らせ一覧 /////
  &_list {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-flex-start;
    @include align-items-stretch;
    gap: 5vw;

    @include breakpoint-sp {
      gap: 5vw;
    }
    @include breakpoint-pc {
      gap: $topics-list-gap-pc;
    }
    @include breakpoint-mainbox {
      gap: $topics-list-gap-mb;
    }

    &_item {
      position: relative;
      width: calc(50% - (5vw / 2));
      
      @include breakpoint-sp {
        width: calc(50% - (5vw / 2));
      }
      @include breakpoint-pc {
        width: calc(( 100% / 3 ) - ( #{$topics-list-gap-pc} * 2 / 3));
      }
      @include breakpoint-mainbox {
        width: calc(( 100% / 3 ) - ( #{$topics-list-gap-mb} * 2 / 3));
      }
    }

    &_img {
      width: 100%;
      background-color: color-wh(1);
      
      &_inner {
        position: relative;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        
        &.-deco {
          &:before {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 11px 11px 0;
            border-color: transparent color-green01(1) transparent transparent;
            
            @include breakpoint-pc {
              border-width: 0 15px 15px 0;
            }
            @include breakpoint-mainbox {
              border-width: 0 20px 20px 0;
            }
          }
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    &_dt {
      margin-bottom: 5px;
    }
    
    &_ttl {
    }
    
    &_cat {
      color: color-blue04(1);

      span {
        margin-right: 10px;
      }
    }
  }
  
  
  ///// お知らせカテゴリ一覧 /////
  &_cats {
    padding-bottom: 30px;
    border-bottom: 1px solid color-blue04(1);
    box-sizing: border-box;
 
    @include breakpoint-pc {
    }

    &_ttl {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include align-items-center;
      position: relative;
      
      &:after {
        display: block;
        position: relative;
        width: 100%;
        height: 1px;
        content: "";
        margin-left: 10px;
        background-color: color-blue04(1);
      }
      
      &_txt {
        @include flex-shrink-0;
      }
    }
    
    &_list {
    }
    
    &_item {
      padding-bottom: 5px;
      box-sizing: border-box;

      &.-current {
        border-bottom: 1px solid color-blue01(1);
      }
    }
  }


  ///// お知らせ記事 /////
  &_article {
  }
  
  
}