@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700&family=Noto+Sans+JP:wght@400;500;700&family=Shippori+Mincho:wght@400;500&display=swap');

@mixin font-gothic() {
  font-family:"Helvetica Neue","Segoe UI","Hiragino Kaku Gothic ProN","Hiragino Sans",Meiryo,sans-serif;
}


// Montserrat
@mixin font-montserrat($status) {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;

  @if $status == 't' {
    font-weight: 100;
  } @else if $status == 'l' {
    font-weight: 300;
  } @else if $status == 'r' {
    font-weight: 400;
  } @else if $status == 'm' {
    font-weight: 500;
  } @else if $status == 'sb' {
    font-weight: 600;
  } @else if $status == 'bd' {
    font-weight: 700;
  }
}


//しっぽり明朝 | Shippori Mincho
@mixin font-sprmin($status) {
  font-family: 'Shippori Mincho', serif;
  font-style: normal;
  font-weight: 400;

  @if $status == 'r' {
    font-weight: 400;
  } @else if $status == 'm' {
    font-weight: 500;
  }
}


//源の角ゴシック | noto sans
@mixin font-ntsan($status) {
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;

  @if $status == 'r' {
    font-weight: 400;
  } @else if $status == 'm' {
    font-weight: 500;
  } @else if $status == 'b' {
    font-weight: 700;
  }
}


//DNP 秀英明朝 Pr6 | DNP Shuei Mincho Pr6
@mixin font-dnpshumin-pr6($status) {
  font-family: dnp-shuei-mincho-pr6, sans-serif;
  font-style: normal;
  font-weight: 500;

  @if $status == 'm' {
    font-weight: 500;
  } @else if $status == 'b' {
    font-weight: 600;
  }
}


//Lato
@mixin font-din1451() {
  font-family: din-1451-lt-pro-engschrift, sans-serif;
  font-style: normal;
  font-weight: 400;
}


//Linux Libertine Display
@font-face {
  font-family: "linlibrtine_dr";
  src:url("/assets/font/LinLibertine_DR.eot?") format("eot"),
    url("/assets/font/LinLibertine_DR.woff") format("woff"),
    url("/assets/font/LinLibertine_DR.ttf") format("truetype"),
    url("/assets/font/LinLibertine_DR.svg#LinLibertineDisplay") format("svg");
  font-weight: normal;
  font-style: normal;
}
@mixin font-lldr {
  font-family: 'linlibrtine_dr';
  font-weight: normal;
  font-style: normal;
}

