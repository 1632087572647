$max: 1620px; // 最大幅 - 1620px
$maxinner: 1560px; // KV幅 - 1560px
$mainbox: 1120px; // メインボックス幅 - 1120px
$content: 1000px; // メインコンテンツ幅 - 1000px
$pc: 800px; // PC - 800px
$company: 650px; // Company表示切り替え幅 - 650px
$business: 600px; //Businessの表示切り替え幅 - 600px
$mainboxhalf: 560px; // メインボックス幅の半分 - 560px
$contenthalf: 500px; // メインコンテンツ幅の半分 - 500px
$tb: 480px; // タブレット - 480px
$sp: 375px; // スマホ - 375px

@mixin max-breakpoint-pc {
  @media all and (max-width: calc(#{$pc} - 1px)) {
    @content;
  }
}
@mixin max-breakpoint-tb {
  @media all and (max-width: calc(#{$tb} - 1px)) {
    @content;
  }
}
@mixin max-breakpoint-sp {
  @media all and (max-width: calc(#{$sp} - 1px)) {
    @content;
  }
}

@mixin breakpoint-max {
  @media all and (min-width: ($max)) {
    @content;
  }
}
@mixin breakpoint-maxinner {
  @media all and (min-width: ($maxinner)) {
    @content;
  }
}
@mixin breakpoint-mainbox {
  @media all and (min-width: ($mainbox)) {
    @content;
  }
}
@mixin breakpoint-content {
  @media all and (min-width: ($content)) {
    @content;
  }
}
@mixin breakpoint-pc {
  @media all and (min-width: ($pc)) {
    @content;
  }
}
@mixin breakpoint-company {
  @media all and (min-width: ($company)) {
    @content;
  }
}
@mixin breakpoint-business {
  @media all and (min-width: ($business)) {
    @content;
  }
}
@mixin breakpoint-mainboxhalf {
  @media all and (min-width: ($mainboxhalf)) {
    @content;
  }
}
@mixin breakpoint-contenthalf {
  @media all and (min-width: ($contenthalf)) {
    @content;
  }
}
@mixin breakpoint-tb {
  @media all and (min-width: ($tb)) {
    @content;
  }
}
@mixin breakpoint-sp {
  @media all and (min-width: ($sp)) {
    @content;
  }
}


////// display switch //////
.disp {
  &-block {
    &-pc {
      display: none;
      
      @include breakpoint-pc {
        display: block;
      }
    }
  
    &-sptb {
      display: block;
      
      @include breakpoint-pc {
        display: none;
      }
    }
  
    &-pctb {
      display: none;
      
      @include breakpoint-tb {
        display: block;
      }
    }
  
    &-sp {
      display: block;
      
      @include breakpoint-tb {
        display: none;
      }
    }
  
    &-pcsp {
      display: none;
      
      @include breakpoint-sp {
        display: block;
      }
    }
  
    &-sponly {
      display: block;
      
      @include breakpoint-sp {
        display: none;
      }
    }
  }

  &-inline {
    &-pc {
      display: none;
      
      @include breakpoint-pc {
        display: inline;
      }
    }
  
    &-sptb {
      display: inline;
      
      @include breakpoint-pc {
        display: none;
      }
    }
  
    &-pctb {
      display: none;
      
      @include breakpoint-tb {
        display: inline;
      }
    }
  
    &-sp {
      display: inline;
      
      @include breakpoint-tb {
        display: none;
      }
    }
  
    &-pcsp {
      display: none;
      
      @include breakpoint-sp {
        display: inline;
      }
    }
  
    &-sponly {
      display: inline;
      
      @include breakpoint-sp {
        display: none;
      }
    }
  }
}